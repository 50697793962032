import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Alert, Input } from 'antd';
import { useChangeHandler } from 'hooks';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Link } from 'react-router-dom';
import { auth } from 'state/actions/auth';
import { regexEmail } from 'utils/regex';
import { ReactComponent as ErrorLogo } from '../../assets/Iconly/Bulk/Info-Circle.svg';
import { ReactComponent as EmailLogo } from '../../assets/Iconly/Light-Outline/Email-logo.svg';
import { ReactComponent as LockLogo } from '../../assets/Iconly/Light-Outline/Lock-logo.svg';
import paths from '../Router/paths';
import './Login.css';

const Login = ({ history }) => {
  const { error, isAuth, loading, locale } = useSelector(
    (state) => ({
      error: state.auth.error,
      isAuth: !!state.auth.userData.id,
      loading: state.auth.loading,
      locale: state.preferences.locale,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const [authData, setAuthData] = useState({
    email: '',
    password: '',
  });
  const [visible, setVisible] = useState(false);
  const [canAccessAfter, setCanAccessAfter] = useState(null);
  const [seconds, setSeconds] = useState(null);
  const [errors, setErrors] = useState([]);

  const onChangeHandler = useChangeHandler(setAuthData);

  useEffect(() => {
    let secTimer = setInterval(() => {
      setSeconds(new Date());
    }, 1000);

    return () => clearInterval(secTimer);
  }, []);

  useEffect(() => {
    if (moment(seconds).isSameOrAfter(moment(canAccessAfter))) {
      setCanAccessAfter(null);
    }
  }, [seconds]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (error) {
      toastr.error(error.msg);
      if (error.canAccessAfter) {
        setCanAccessAfter(error.canAccessAfter);
      }
      setAuthData({
        email: '',
        password: '',
      });
    }
    if (isAuth) {
      sessionStorage.setItem('activeMenu', 'dashboard');
      history.push(paths.ROOT); // eslint-disable-line react-hooks/exhaustive-deps
    }
  }, [error, isAuth, loading, locale]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmitHandler = (event) => {
    event.preventDefault();
    setErrors([]);

    // const lowercase = authData.password.match(/[a-z]/g) ? true : false;
    // const uppercase = authData.password.match(/[A-Z]/g) ? true : false;
    // const number = authData.password.match(/[0-9]/g) ? true : false;
    const length = authData.password.length < 1;

    if ((!regexEmail(authData.email) && authData.email) || length) {
      const errorData = [];
      if (!regexEmail(authData.email) && authData.email) {
        errorData.push('Email Field cannot contain a special characters');
      }

      if (length) {
        errorData.push('Password must be filled');
      }

      // if (!lowercase) {
      //   errorData.push('Password Must be contain lowercase');
      // }

      // if (!uppercase) {
      //   errorData.push('Password Must be contain uppercase');
      // }

      // if (!number) {
      //   errorData.push('Password Must be contain number');
      // }
      setErrors(errorData);
    } else {
      dispatch(auth(authData.email, authData.password));
    }
  };

  const passwordToggle = () => {
    const inputPasswordEl = document.getElementById('password');
    if (inputPasswordEl.type === 'password') {
      setVisible(true);
      inputPasswordEl.type = 'text';
    } else {
      setVisible(false);
      inputPasswordEl.type = 'password';
    }
  };

  const timer = () => {
    const ms = moment(canAccessAfter).diff(moment(seconds));
    const d = moment.duration(ms);
    const m = Math.floor(d.minutes());
    const s = Math.floor(d.seconds());
    return `${m} minutes ${s} seconds`;
  };

  return (
    <div className="login">
      <span>Adactive Asia</span>
      <h1>Adsign Client Login</h1>
      <p>This is the login portal for Adactive clients who are using adsign.</p>
      {error && (
        <Alert
          message={error.msg}
          type="error"
          showIcon
          closable
          icon={<ErrorLogo width="16.67" height="16.67" />}
        />
      )}
      {errors.length > 0 &&
        errors.map((x, i) => {
          return (
            <Alert
              key={i}
              message={x}
              type="error"
              showIcon
              closable
              icon={<ErrorLogo width="16.67" height="16.67" />}
            />
          );
        })}
      <form className="form-wrapper" onSubmit={onSubmitHandler}>
        <div
          className={`form ${error || errors.length > 0 ? 'error-login' : ''}`}
        >
          <Input
            id="email"
            name="email"
            type="email"
            value={authData.email}
            onChange={onChangeHandler}
            prefix={<EmailLogo width="22" height="20" />}
            suffix={<label className="form-label">Email</label>}
            required
          />
        </div>

        {/* {error && <span className="error-message">Invalid Credentials</span>} */}
        <div
          className={`form ${error || errors.length > 0 ? 'error-login' : ''}`}
        >
          <Input
            id="password"
            type="password"
            name="password"
            value={authData.password}
            onChange={onChangeHandler}
            prefix={<LockLogo width="22" height="20" />}
            suffix={
              <>
                <label className="form-label">Password</label>
              </>
            }
            addonAfter={
              visible ? (
                <EyeTwoTone onClick={() => passwordToggle()} />
              ) : (
                <EyeInvisibleOutlined onClick={() => passwordToggle()} />
              )
            }
            required
          />
        </div>

        <div className="more-actions">
          <div className="flex">
            <Link to="/recover-password">
              <span>Forget Password</span>
            </Link>
          </div>
        </div>

        {/* {error && <span className="error-message">Invalid Credentials</span>} */}
        <div className="form">
          {loading ? (
            <button type="button" disabled className="button">
              Loading...
            </button>
          ) : canAccessAfter ? (
            <button type="button" disabled className="button">
              You can accesss after &nbsp;{timer()}
            </button>
          ) : (
            <button type="submit" className="button">
              Login
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default Login;
