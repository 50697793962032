import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { errorMsg } from 'utils';
import { url } from 'utils/url';
import moment from 'moment';
import fileDownload from 'js-file-download';

export const LOGS_FETCH_DATA_INIT = createAction('LOGS_FETCH_DATA_INIT');
export const LOGS_FETCH_DATA_SUCCESS = createAction('LOGS_FETCH_DATA_SUCCESS');
export const LOGS_FETCH_DATA_FAIL = createAction('LOGS_FETCH_DATA_FAIL');

export const LOGS_EXPORT_LOG_INIT = createAction('LOGS_EXPORT_LOG_INIT');
export const LOGS_EXPORT_LOG_SUCCESS = createAction('LOGS_EXPORT_LOG_SUCCESS');
export const LOGS_EXPORT_LOG_FAIL = createAction('LOGS_EXPORT_POI_FAIL');

export const LOGS_CLEAN_UP = createAction('LOGS_CLEAN_UP');

export const fetchLogs = ({ page, size, startDate, endDate, email }) => {
  return async (dispatch, getState) => {
    dispatch(LOGS_FETCH_DATA_INIT());
    let logs;
    try {
      logs = await axios.post(
        `${url}/log/get`,
        {
          page,
          page_size: size,
          startDate,
          endDate,
          email,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
    } catch (error) {
      toastr.error('', `${errorMsg(dispatch, error)}`);
      return dispatch(LOGS_FETCH_DATA_FAIL({ error }));
    }
    return dispatch(
      LOGS_FETCH_DATA_SUCCESS({
        list: logs.data.data,
      })
    );
  };
};
export const fetchAuthLogs = ({ page, size, startDate, endDate, email }) => {
  return async (dispatch, getState) => {
    dispatch(LOGS_FETCH_DATA_INIT());
    let logs;
    try {
      logs = await axios.post(
        `${url}/authlog/get`,
        {
          page,
          page_size: size,
          startDate,
          endDate,
          email,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
    } catch (error) {
      toastr.error('', `${errorMsg(dispatch, error)}`);
      return dispatch(LOGS_FETCH_DATA_FAIL({ error }));
    }
    return dispatch(
      LOGS_FETCH_DATA_SUCCESS({
        list: logs.data.data,
      })
    );
  };
};

export const downloadLogs = () => {
  return async (dispatch, getState) => {
    dispatch(LOGS_EXPORT_LOG_INIT());
    axios
      .get(`${url}/log/export`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(
          res.data,
          `logs_export_${moment(new Date()).format('YYYY-MM-DD')}.csv`
        );
        return dispatch(LOGS_EXPORT_LOG_SUCCESS());
      })
      .catch((error) => {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(LOGS_EXPORT_LOG_FAIL({ error }));
      });
  };
};

export const downloadAuthLogs = () => {
  return async (dispatch, getState) => {
    dispatch(LOGS_EXPORT_LOG_INIT());
    axios
      .get(`${url}/authlog/export`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(
          res.data,
          `logs_export_${moment(new Date()).format('YYYY-MM-DD')}.csv`
        );
        return dispatch(LOGS_EXPORT_LOG_SUCCESS());
      })
      .catch((error) => {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(LOGS_EXPORT_LOG_FAIL({ error }));
      });
  };
};

export const logsCleanUp = () => (dispatch) => dispatch(LOGS_CLEAN_UP());
