import React from 'react';
import './FullLayout.css';

function FullLayout({ children }) {
  return (
    <div className="full-layout">
      <div className="left-content" />
      <div className="right-content">{children}</div>
    </div>
  );
}

export default FullLayout;
