import { Breadcrumb, Modal, Table, Tooltip } from 'antd';
import { useFormatMessage } from 'hooks';
import moment from 'moment';
import paths from 'pages/Router/paths';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { deleteUser, fetchUsers } from 'state/actions/users';
import { regexInput } from 'utils/regex';
// import './User.css';
import './Users.scss';
import _ from 'underscore';

function User() {
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState(null);

  const {
    usersList,
    // isAdmin,
    error,
    loading,
    deleted,
    userData,
    role,
  } = useSelector(
    (state) => ({
      usersList: state.users.list,
      // isAdmin: state.auth.userData.isAdmin,
      error: state.users.error,
      loading: state.users.loading,
      deleted: state.users.deleted,
      userData: state.auth.userData,
      role: state.auth.userData.role,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (role === 'admin' || role === 'superadmin') {
      dispatch(fetchUsers());
    }
  }, [dispatch, role]);

  useEffect(() => {
    if (deleted && !loading) {
      dispatch(fetchUsers());
    }
  }, [deleted, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  const [searchError, setSearchError] = useState(null);

  const onRemoveButtonClickHandler = (userId) => {
    Modal.confirm({
      title: 'Are you sure want to delete this data?',
      content: 'Data cannot be restored after delete',
      onOk: () => dispatch(deleteUser(userId)),
      okButtonProps: {
        loading,
      },
    });
  };

  const handleSearch = (e) => {
    setSearchError(null);
    if (!regexInput(e.target.value) && e.target.value) {
      setSearchError('Search cannot using special character');
    }
    setSearch(e.target.value);
  };

  const redirect =
    role === 'admin' || role === 'superadmin' ? (
      false
    ) : (
      <Redirect to={paths.ROOT} />
    );

  const columns = [
    {
      title: useFormatMessage('Users.name'),
      dataIndex: 'name',
      sorter: true,
    },
    {
      title: useFormatMessage('Users.email'),
      dataIndex: 'email',
      sorter: true,
    },
    {
      title: useFormatMessage('Users.role'),
      dataIndex: 'role',
      sorter: true,
    },
    {
      title: useFormatMessage('Users.lastLogin'),
      dateIndex: 'lastLoginDate',
      sorter: true,
      render: (text, record) => {
        if (record.lastLoginDate) {
          return (
            <div>{`${moment(record.lastLoginDate)
              .utcOffset('+08:00')
              .format('h:mm A, MMM Do YYYY')}`}</div>
          );
        }
        return <span>-</span>;
      },
    },
    {
      title: 'Options',
      dataIndex: 'option',
      render: (text, record) => {
        return (
          <div className="users__actions">
            {(role === 'admin' && record.role === 'user') ||
            role === 'superadmin' ? (
              <Tooltip title="Edit User">
                <Link to={`/users/${record._id}`} className="btn btn--edit">
                  <i className="feather-edit" />
                  Edit
                </Link>
              </Tooltip>
            ) : (
              <></>
            )}

            {role === 'superadmin' && (
              <Tooltip title="Delete User">
                <button
                  className="btn btn--delete"
                  onClick={() => onRemoveButtonClickHandler(record._id)}
                >
                  <i className="feather-trash-2" />
                  Delete
                </button>
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  const handleChangeTable = (pagination, filters, sorter, extra) => {
    setSort(sorter);
  };

  const data = search
    ? usersList.filter((el) => {
        const clonedElem = { ...el };
        delete clonedElem.id;
        const name =
          clonedElem.name &&
          clonedElem.name.toLowerCase().includes(search.toLowerCase());
        const org =
          clonedElem.organizationName &&
          clonedElem.organizationName
            .toLowerCase()
            .includes(search.toLowerCase());
        const email =
          clonedElem.email &&
          clonedElem.email.toLowerCase().includes(search.toLowerCase());
        const role =
          clonedElem.role &&
          clonedElem.role.toLowerCase().includes(search.toLowerCase());
        if (name || org || email || role) {
          return clonedElem;
        }
        return false;
      })
    : // eslint-disable-next-line
      usersList.filter((el) => {
        if (role === 'superadmin') {
          return el.role !== 'superadmin';
        } else if (role === 'admin') {
          return (
            el.role !== 'superadmin' &&
            el.organizationName === userData.organizationName
          );
        }
      });

  const sortedData = _.sortBy(data, sort ? sort.field : 'name');
  const sortedDataDirection =
    sort && sort.order === 'descend' ? sortedData.reverse() : sortedData;

  return (
    <section className="users">
      {redirect}
      <div className="header-content">
        <div className="header-left-content">
          <div className="breadcrumb-wrapper">
            <Breadcrumb>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item className="breadcrumb-active">
                User Management
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <h1 className="page-title">User Management</h1>
        </div>
        <div className="header-right-content">
          <div className="searchbar-wrapper">
            <div className="searchbar">
              <i className="feather-search"></i>
              <input
                type="text"
                placeholder="Search..."
                value={search}
                onChange={handleSearch}
              />
            </div>
            {searchError && <div className="search-error">{searchError}</div>}
          </div>
          {(role === 'superadmin' || role === 'admin') && (
            <Link className="button-primary" to="/users/new">
              <i className="feather-plus" />
              New User
            </Link>
          )}
        </div>
      </div>

      <div className="users__table">
        {loading ? (
          <ClipLoader />
        ) : (
          <Table
            columns={columns}
            dataSource={sortedDataDirection}
            rowKey="id"
            onChange={handleChangeTable}
          />
        )}
        {error && 'Show error'}
      </div>
    </section>
  );
}

export default User;
