import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CategoryDropdown from 'components/CategoryDropdown';
import { DatePicker } from 'antd';
import { EmptyAnalyticIcon, FilterIcon, ExportIcon } from 'assets/icon';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ProfileAnalyzerReport from 'components/ProfileAnalyzerReport';
import {
  downloadAnalytics,
  downloadAnalyticsLeaderboard,
  fetchAnalytics,
} from 'state/actions/analytic';
import FilterDropdown from 'components/FilterDropdown';
// import { ExportIcon } from 'assets/icon/dynamic';
import { fetchUsers } from 'state/actions/users';
import './Analytic.css';

const Analytics = () => {
  const { siteId } = useParams();

  const { analytics, loading, users } = useSelector(
    (state) => ({
      users: state.users.list,
      analytics: state.analytic.data,
      loading: state.analytic.loading,
      locations: state.analytic.locations,
    }),
    shallowEqual
  );

  // select report type 'poi' or 'place'
  const [typeOpen, setTypeOpen] = useState(false);
  const [selectedType, setSelectedType] = useState();
  const [tempSelectedType, setTempSelectedType] = useState(selectedType);
  // select report type 'poi' or 'place'
  const [deviceOpen, setDeviceOpen] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState();
  // screens.map((screen) => screen.adsumScreenId)
  const [tempSelectedDevice, setTempSelectedDevice] = useState([]);
  // select report type 'poi' or 'place'
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState();
  const [longDateRange, setLongDateRange] = useState(false);
  // screens.map((screen) => screen.adsumScreenId)
  const [tempSelectedMenu, setTempSelectedMenu] = useState([]);
  const [menuType, setMenuType] = useState([
    { _id: 'gallery', name: 'Gallery Highlight' },
    { _id: 'curated-playlist', name: 'Curated Art Journeys' },
    { _id: 'social-wall', name: 'Social Wall' },
    { _id: 'wayfinding', name: 'Wayfinding' },
  ]);
  const [screens, setScreens] = useState([
    { _id: 'Screen1@adactive.asia' },
    { _id: 'Screen2@adactive.asia' },
    { _id: 'Screen3@adactive.asia' },
    { _id: 'Screen4@adactive.asia' },
  ]);

  const [dateRange, setDateRange] = useState({
    startDate: '',
    endDate: '',
  });

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10, // Or whatever your default page size is
  });
  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
    // Handle other pagination-related operations if needed
  };
  const resetPagination = () => {
    setPagination({
      current: 1,
      pageSize: 10, // Or whatever your default page size is
    });
  };

  const chartType = [
    { _id: 'select', name: 'Number of Clicks' },
    { _id: 'qrcode', name: 'Number of QR Scan' },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUsers());
  }, []); // eslint-disable-line
  useEffect(() => {
    if (users.length > 0) {
      const screensData = screens;
      console.log(screens, 'screens ');
      // eslint-disable-next-line
      screens.map((screen, id) => {
        console.log(users, 'users');
        screensData[id].name = users.find(
          (user) => user.email === screen._id
        )?.name;
      });
      console.log(screensData, 'screen data');
      setScreens(screensData);
    }
  }, [users]); // eslint-disable-line
  useEffect(() => {
    console.log(selectedType, 'selectedtype');
    if (selectedType) {
      console.log('fetch analytics');
      if (selectedType === 'select') {
        setMenuType([
          { _id: 'gallery', name: 'Gallery Highlight' },
          { _id: 'curated-playlist', name: 'Curated Art Journeys' },
          { _id: 'social-wall', name: 'Social Wall' },
          { _id: 'wayfinding', name: 'Wayfinding' },
          { _id: 'session', name: 'Session' },
        ]);
      }
      if (selectedType === 'qrcode') {
        setMenuType([
          { _id: 'gallery', name: 'Gallery Highlight' },
          { _id: 'curated-playlist', name: 'Curated Art Journeys' },
          { _id: 'social-wall', name: 'Social Wall' },
          { _id: 'wayfinding', name: 'Wayfinding' },
        ]);
      }

      dispatch(
        fetchAnalytics({
          start: dateRange.startDate,
          end: dateRange.endDate,
          type: selectedMenu,
          action: selectedType,
          email: selectedDevice,

          siteId,
        })
      );
      resetPagination();
    }
  }, [selectedType, dateRange, selectedDevice, selectedMenu]); // eslint-disable-line

  const handleSetType = () => {
    setSelectedType(tempSelectedType);
    setTypeOpen(false);
  };
  const handleSetDevice = () => {
    setSelectedDevice(tempSelectedDevice);
    setDeviceOpen(false);
  };
  const handleSetMenu = () => {
    setSelectedMenu(tempSelectedMenu);
    setMenuOpen(false);
  };

  const getType = (type) => {
    const findCategory = chartType.find((cat) => cat._id === type);
    return findCategory?.name;
  };

  const handleDateChange = (range) => {
    const startDate = range && range[0].valueOf();
    const endDate = range && range[1].valueOf();

    const timeleft = endDate - startDate;
    const days = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    console.log(startDate, endDate, days, 'range date picker');
    if (days > 31) {
      setLongDateRange(true);
      setDateRange((prevState) => ({
        ...prevState,
        startDate,
        endDate,
      }));
      // toastr.error('Date range must be less than 31 days');
    } else {
      setLongDateRange(false);
      setDateRange((prevState) => ({
        ...prevState,
        startDate,
        endDate,
      }));
    }
  };

  console.log(screens, 'screens ');

  return (
    <>
      <div className="analytic">
        <div className="analytic-header">
          <div className="analytic-header-left">
            <div
              className="playlist-wrapper"
              onClick={() => !loading && setTypeOpen(!typeOpen)}
            >
              <div className="playlist-selection">
                <FilterIcon />
                <span>{!selectedType ? 'Type' : getType(selectedType)}</span>
              </div>
              <i
                className={`feather-chevron-down ${
                  typeOpen ? 'playlist-open' : 'playlist-closed'
                }`}
              />

              <CategoryDropdown
                categories={chartType}
                modalOpen={typeOpen}
                handleSetPlaylist={handleSetType}
                setTempSelectedCategory={(id) => setTempSelectedType(id)}
                tempSelectedCategory={tempSelectedType}
              />
            </div>
            <div
              className="playlist-wrapper"
              onClick={() => !loading && setDeviceOpen(!deviceOpen)}
            >
              <div className="playlist-selection">
                <FilterIcon />
                <span>Screen Filter</span>
              </div>
              <i
                className={`feather-chevron-down ${
                  deviceOpen ? 'playlist-open' : 'playlist-closed'
                }`}
              />

              <FilterDropdown
                categories={screens}
                modalOpen={deviceOpen}
                title="Screen"
                filter
                handleSetPlaylist={handleSetDevice}
                setTempSelectedCategory={(id) => {
                  if (id === 'all') {
                    const categories = screens;
                    if (tempSelectedDevice.length === categories.length) {
                      setTempSelectedDevice([]);
                    } else {
                      const all = categories.map((screen) => screen._id);
                      console.log(all);
                      setTempSelectedDevice(all);
                    }
                  } else {
                    console.log(id);
                    const index = tempSelectedDevice.indexOf(id);
                    if (index === -1) {
                      setTempSelectedDevice([...tempSelectedDevice, id]);
                    } else {
                      tempSelectedDevice.splice(index, 1);
                      setTempSelectedDevice([...tempSelectedDevice]);
                    }
                  }
                }}
                tempSelectedCategory={tempSelectedDevice}
              />
            </div>
            <div
              className="playlist-wrapper"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              <div className="playlist-selection">
                <FilterIcon />
                <span>Page Filter</span>
              </div>
              <i
                className={`feather-chevron-down ${
                  menuOpen ? 'playlist-open' : 'playlist-closed'
                }`}
              />

              <FilterDropdown
                categories={menuType}
                modalOpen={menuOpen}
                title="Type"
                filter
                handleSetPlaylist={handleSetMenu}
                setTempSelectedCategory={(id) => {
                  if (id === 'all') {
                    const categories = menuType;
                    if (tempSelectedMenu.length === categories.length) {
                      setTempSelectedMenu([]);
                    } else {
                      const all = categories.map((screen) => screen._id);
                      console.log(all);
                      setTempSelectedMenu(all);
                    }
                  } else {
                    console.log(id);
                    const index = tempSelectedMenu.indexOf(id);
                    if (index === -1) {
                      setTempSelectedMenu([...tempSelectedMenu, id]);
                    } else {
                      tempSelectedMenu.splice(index, 1);
                      setTempSelectedMenu([...tempSelectedMenu]);
                    }
                  }
                }}
                tempSelectedCategory={tempSelectedMenu}
              />
            </div>

            <div>
              <DatePicker.RangePicker
                className="analytic-date-picker"
                onChange={handleDateChange}
              />
            </div>
          </div>

          {selectedType && (
            <div className="analytic-header-right">
              <button
                className="btn-outline-primary"
                onClick={() =>
                  dispatch(
                    downloadAnalytics({
                      start: dateRange.startDate,
                      end: dateRange.endDate,
                      email: selectedDevice,
                      action: selectedType,
                      type: selectedMenu,
                      siteId,
                    })
                  )
                }
              >
                <ExportIcon color="#64D5D2" size={18} />
                {selectedType === 'leaderboard'
                  ? 'Export Summary'
                  : 'Export Raw Data'}
              </button>
            </div>
          )}
        </div>
        <div className="analytic-content-wrapper">
          <div className="analytic-content">
            {selectedType ? (
              <ProfileAnalyzerReport
                siteId={siteId}
                loading={loading}
                analytics={analytics}
                selectedType={selectedType}
                pagination={pagination}
                longDateRange={longDateRange}
                handleTableChange={handleTableChange}
                onExport={() =>
                  dispatch(
                    downloadAnalyticsLeaderboard({
                      start: dateRange.startDate,
                      end: dateRange.endDate,
                      email: selectedDevice,
                      action: selectedType,
                      type: selectedMenu,
                      siteId,
                    })
                  )
                }
              />
            ) : (
              <div className="empty-content">
                <EmptyAnalyticIcon />
                <h1 className="empty-title">
                  Select the type of data analytics
                </h1>
                <p>
                  Please select the data type first to display the analytics
                  data to be shown
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Analytics;
