import { CircularProgress } from '@material-ui/core';
import { Table } from 'antd';
// import { ExportIcon } from 'assets/icon/dynamic';
import {
  ChartTitle,
  ChartWrapper,
  ReportWrapper,
} from 'components/Report/styles';
import React, { useEffect, useState } from 'react';

import { Bar, Line } from 'react-chartjs-2';
import { shallowEqual, useSelector } from 'react-redux';
import { ExportIcon } from 'assets/icon';
// import ScreensaverReport from './ScreensaverReport';

const ProfileAnalyzerReport = (props) => {
  const {
    analytics,
    loading,
    selectedType,
    handleTableChange,
    pagination,
    longDateRange,
    onExport,
  } = props;
  const INITIAL_STATE = {
    labels: [],
    datasets: [],
  };

  const [reportData, setReportData] = useState(INITIAL_STATE);

  const { dataTable } = useSelector(
    (state) => ({
      dataTable: state.analytic.rawData,
      loadingTable: state.analytic.loadingCalculate,
    }),
    shallowEqual
  );

  const optionsline = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: true,
        text: 'Chart.js Line Chart',
      },
    },
    maintainAspectRatio: false,
  };
  const optionsbar = {
    tooltips: {
      displayColors: true,
      callbacks: {
        mode: 'x',
      },
    },
    // events: [],
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    animation: {
      duration: 1,
      onComplete: (chart) => {
        const chartInstance = chart.chart;
        const { ctx } = chartInstance;

        // ctx.font = Chart.helpers.fontString(
        //   Chart.defaults.global.defaultFontSize,
        //   Chart.defaults.global.defaultFontStyle,
        //   Chart.defaults.global.defaultFontFamily
        // );
        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom';

        chartInstance.data.datasets.forEach((dataset, i) => {
          const meta = chartInstance.controller.getDatasetMeta(i);

          if (!meta.hidden) {
            meta.data.forEach((bar, index) => {
              if (dataset.data[index] > 0) {
                const data = dataset.data[index];
                ctx.font = ' 8pt roboto';
                ctx.fillText(data, bar._model.x, bar._model.y);
              }
            });
          }
        });
      },
    },
    maintainAspectRatio: false,
    legend: {
      position: 'bottom',
    },
    layout: {
      padding: 20,
    },
    plugins: {
      datalabels: {
        font: {
          size: 0,
        },
      },
    },
  };

  const columns = [
    {
      title: 'Total Action Counts',
      dataIndex: 'count',
      width: '10%',
      backgroundColor: '#F2F7FE',
      sorter: (a, b) => a.count - b.count,

      render: (text, record) => {
        return {
          props: {
            style: {
              background: record.name === 'Session' ? '#F2F7FE' : '#FFF',
            },
          },
          children: (
            <div
              className={`table-name ${
                record.name === 'Session' ? 'session' : ''
              }`}
            >
              {text}
            </div>
          ),
        };
      },
    },
    {
      title: '#select',
      dataIndex: 'select',
      width: '10%',
      sorter: (a, b) => a.select - b.select,
      render: (text, record) => {
        return {
          props: {
            style: {
              background: record.name === 'Session' ? '#F2F7FE' : '#FFF',
            },
          },
          children: (
            <div
              className={`table-name ${
                record.name === 'Session' ? 'session' : ''
              }`}
            >
              {text}
            </div>
          ),
        };
      },
    },
    {
      title: 'Type',
      dataIndex: 'source',
      width: '10%',
      sorter: (a, b) => a.goto - b.goto,
      render: (text, record) => {
        return {
          props: {
            style: {
              background: record.name === 'Session' ? '#F2F7FE' : '#FFF',
            },
          },
          children: (
            <div
              className={`table-name ${
                record.name === 'Session' ? 'session' : ''
              }`}
            >
              {text}
            </div>
          ),
        };
      },
    },

    {
      title: 'Label',
      dataIndex: 'name',
      width: '40%',
      render: (text, record) => {
        return {
          props: {
            style: {
              background: record.name === 'Session' ? '#F2F7FE' : '#FFF',
            },
          },
          children: (
            <div
              className={`table-name ${
                record.name === 'Session' ? 'session' : ''
              }`}
            >
              {text}
            </div>
          ),
        };
      },
    },
  ];
  const columnsQR = [
    {
      title: 'Total Action Counts',
      dataIndex: 'count',
      width: '10%',
      sorter: (a, b) => a.count - b.count,
      render: (text) => <div className="table-name">{text}</div>,
    },
    {
      title: 'Label',
      dataIndex: 'name',
      width: '40%',
      render: (text) => <div className="table-name">{text}</div>,
    },
  ];

  const colorPallete = ['#2C82F6', '#64D5D2', '#FFBC00', '#27AE60'];
  useEffect(() => {
    console.log(analytics, 'analytics in report');
    if (analytics && !loading) {
      const actions = [];
      Object.values(analytics).forEach((val) => {
        Object.keys(val).forEach((loc) => {
          if (actions.indexOf(loc) === -1) {
            actions.push(loc);
          }
        });
      });

      console.log(actions, 'labels');
      setReportData({
        labels: Object.keys(analytics),
        datasets: actions.map((set, index) => {
          const color = colorPallete[index];
          // monthData.push({
          //   location: set,
          //   data: Object.values(analytics)
          //     .map((val) => val[set])
          //     ?.reduce((a, b) => (b ? a + b : a), 0),
          // });
          return {
            label: set,
            fill: false,
            data: Object.values(analytics).map((val) => val[set]),
            borderWidth: 2,
            borderColor: color,
            backgroundColor: color,
            strokeColor: color,
          };
        }),
      });
      // let dataMonth = [];
      // dataMonth = locations.map((loc) => {
      //   return {
      //     location: loc.location,
      //     ratio: _.groupBy(loc.data, 'gender'),
      //   };
      // });
      // console.log(dataMonth, 'data month');
      // setDataTable(_.sortBy(dataMonth, ['location']));
    }
  }, [analytics]); // eslint-disable-line

  return (
    <div className="analytic-chart">
      {/* <div className="field-body"> */}

      {/* </div> */}
      <ReportWrapper>
        <ChartTitle>Analytics</ChartTitle>

        {/* <div className="selector">
          {locations && (
            <div className="m-2">
              <Select
                // defaultValue={loc}
                placeholder="Location"
                style={{ width: 120 }}
                onChange={(val) => {
                  setReportData();
                  setLoc(val);
                }}
                options={[
                  {
                    value: null,
                    label: 'All location',
                  },
                  ...locations.map((location) => {
                    return {
                      value: location,
                      label: location,
                    };
                  }),
                ]}
              />
            </div>
          )}
        </div> */}

        {/* <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">{"Start Date"}</label>
          </div>
          <div className="field-label is-normal">
            <label className="label">{"End Date"}</label>
          </div>
        </div> */}
        {reportData && !loading ? (
          <ChartWrapper>
            {longDateRange ? (
              <Line
                data={reportData}
                width={100}
                height={250}
                options={optionsline}
              />
            ) : (
              <Bar
                data={reportData}
                width={100}
                height={250}
                options={optionsbar}
              />
            )}
          </ChartWrapper>
        ) : (
          <CircularProgress />
        )}
      </ReportWrapper>
      {onExport && (
        <div className="leaderboard-export">
          <button className="btn-outline-primary" onClick={onExport}>
            <ExportIcon color="#64D5D2" size={18} />
            Export Summary
          </button>
        </div>
      )}
      <Table
        columns={
          ['qrcode', 'event', 'promotion'].includes(selectedType)
            ? columnsQR
            : columns
        }
        dataSource={dataTable}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default ProfileAnalyzerReport;
