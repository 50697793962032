import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import { logout } from 'state/actions/auth';
import './BaseLayout.css';

const transitionStyles = {
  entering: { marginLeft: -300 },
  entered: { marginLeft: 270 },
  exiting: { marginLeft: -300 },
  exited: { marginLeft: -300 },
};

const childrenTransitionStyle = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

function BaseLayout({
  children,
  history,
  collapsed,
  handleToggle,
  setCollapsed,
}) {
  const dispatch = useDispatch();

  const {
    userName,
    userEmail,
    userData,
    organizations,
    sites,
    role,
  } = useSelector(
    (state) => ({
      userName: state.auth.userData.name,
      userEmail: state.auth.userData.email,
      logoUrl: state.auth.userData.logoUrl,
      role: state.auth.userData.role,
      userData: state.auth.userData,
      organizations: state.organizations.list,
      sites: state.sites.list,
    }),
    shallowEqual
  );

  const [openChildren, setOpenChildren] = useState([]);

  const activeMenu = sessionStorage.getItem('activeMenu');

  const clickMenu = (menu) => {
    setCollapsed(false);
    if (menu) {
      sessionStorage.setItem('activeMenu', menu);
    }
  };

  const onClickLogoutHandler = () => {
    dispatch(logout());
  };

  const handleOpenChildren = (id) => {
    const newChildren = [...openChildren];
    const findChildren = newChildren.find((child) => child._id === id);
    if (findChildren) {
      findChildren.open = !findChildren.open;
      setOpenChildren(newChildren);
    } else {
      setOpenChildren([
        ...openChildren,
        {
          _id: id,
          open: true,
        },
      ]);
    }
  };

  return (
    <div className="base-layout">
      {/* Sidebar */}
      <div className="sidebar">
        <div className="sidebar-top">
          <img src={require('../../assets/sidebar-logo.png')} alt="Adsign" />
          <ul className="menu-sidebar">
            {/* <Tooltip placement="right" title="Dashboard"> */}
            <li
              onClick={() => clickMenu('dashboard')}
              className={activeMenu === 'dashboard' ? 'active' : ''}
            >
              <Link to="/">
                <i className="feather-home" />
                <span>Dashboard</span>
              </Link>
            </li>
            {/* </Tooltip> */}
            {/* <Tooltip placement="right" title="User">
              <li
                onClick={() => clickMenu('user')}
                className={activeMenu === 'user' ? 'active' : ''}
              >
                <Link to="/users">
                  <i className="feather-users" />
                </Link>
              </li>
            </Tooltip> */}
            {role === 'superadmin' && (
              // <Tooltip placement="right" title="Organization">
              <li
                onClick={() => clickMenu('organization')}
                className={activeMenu === 'organization' ? 'active' : ''}
              >
                <Link to="/organizations">
                  <i className="feather-grid" />
                  <span>Organization</span>
                </Link>
              </li>
              // </Tooltip>
            )}

            {role === 'superadmin' && (
              // <Tooltip placement="right" title="Site">
              <li
                className={activeMenu === 'site' ? 'active' : ''}
                onClick={(e) => {
                  handleToggle(e, 'site');
                }}
              >
                <Link to="/sites">
                  <div>
                    <div>
                      <i className="feather-server" />
                      <span>Site</span>
                    </div>
                    <i className="feather-chevron-right" />
                  </div>
                </Link>
              </li>
              // </Tooltip>
            )}

            {role === 'superadmin' ? (
              <li
                onClick={() => clickMenu('roles')}
                className={activeMenu === 'roles' ? 'active' : ''}
              >
                <Link to="/roles">
                  <i className="feather-users" />
                  <span>Roles</span>
                </Link>
              </li>
            ) : null}
            {role === 'superadmin' ? (
              <li
                onClick={(e) => handleToggle(e, 'panel')}
                className={activeMenu === 'panel' ? 'active' : ''}
              >
                <Link to="/panel">
                  <div>
                    <div>
                      <i className="feather-book" />
                      <span>Admin Panel</span>
                    </div>
                    <i className="feather-chevron-right" />
                  </div>
                </Link>
              </li>
            ) : null}

            {role === 'superadmin' || role === 'admin' ? (
              <li
                onClick={() => clickMenu('users')}
                className={activeMenu === 'users' ? 'active' : ''}
              >
                <Link to="/users">
                  <i className="feather-users" />
                  <span>User Management</span>
                </Link>
              </li>
            ) : null}

            <li
              onClick={() => clickMenu('profile')}
              className={activeMenu === 'profile' ? 'active' : ''}
            >
              <Link to="/profile">
                <i className="feather-user" />
                <span>Profile</span>
              </Link>
            </li>

            {role === 'superadmin' || role === 'admin' ? (
              <li
                onClick={(e) => handleToggle(e, 'logs')}
                className={activeMenu === 'logs' ? 'active' : ''}
              >
                <Link to="/logs">
                  <div>
                    <div>
                      <i className="feather-activity" />
                      <span>System Log</span>
                    </div>
                    <i className="feather-chevron-right" />
                  </div>
                </Link>
              </li>
            ) : null}

            {/* <li
              onClick={() => clickMenu('bugs')}
              className={activeMenu === 'bugs' ? 'active' : ''}
            >
              <Link to="/bugs">
                <i className="feather-code" />
                <span>Bug Report</span>
              </Link>
            </li> */}

            {/* {role === 'superadmin' && (
              <Tooltip placement="right" title="Screen">
                <li
                  className={activeMenu === 'screen' ? 'active' : ''}
                  onClick={(e) => {
                    handleToggle(e, 'screen');
                  }}
                >
                  <Link to="/screens">
                    <span>
                      <i className="feather-monitor" />
                    </span>
                  </Link>
                </li>
              </Tooltip>
            )} */}
          </ul>
        </div>
        <div className="sidebar-bottom">
          <ul className="sidebar-bottom-list">
            <li className="sidebar-bottom-title">{userName}</li>
            <li className="sidebar-bottom-subtitle">{userEmail}</li>
            <li
              className="sidebar-bottom-button"
              onClick={onClickLogoutHandler}
            >
              <i className="feather-log-out" />
              Logout
            </li>
          </ul>
        </div>
      </div>

      {/* Sidebar Collapsed */}
      <Transition in={collapsed} timeout={100} appear unmountOnExit>
        {(state) =>
          activeMenu === 'site' ? (
            <div className="sidebar-collapsed" style={transitionStyles[state]}>
              <div className="close" onClick={(e) => handleToggle(e, null)}>
                <i className="feather-chevron-left" />
              </div>
              <ul className="toggle-dropdown">
                <li>
                  <span className="organization-list-title">
                    Organization List
                  </span>
                </li>
                <div className="divider" />

                {organizations &&
                  role === 'superadmin' &&
                  // eslint-disable-next-line
                  organizations.map((organization) => {
                    const openChildrenFound = openChildren.find(
                      (children) => children._id === organization._id
                    );

                    if (role === 'superadmin') {
                      return (
                        <li
                          key={organization._id}
                          className={`has-dropdown ${
                            openChildrenFound && openChildrenFound.open
                              ? 'active'
                              : ''
                          }`}
                        >
                          <span
                            onClick={() => handleOpenChildren(organization._id)}
                          >
                            {organization.name}
                          </span>
                          {
                            <Transition
                              key={organization.id}
                              in={openChildrenFound && openChildrenFound.open}
                              timeout={100}
                              appear
                              unmountOnExit
                            >
                              {(state) => (
                                <ul
                                  className="collapsable"
                                  style={childrenTransitionStyle[state]}
                                >
                                  {sites
                                    .filter(
                                      (site) =>
                                        site.organizationId === organization._id
                                    )
                                    .map((site) => {
                                      return (
                                        <li key={site._id}>
                                          <Link
                                            to={`/${site._id}/information/${site.name}`}
                                          >
                                            {site.name}
                                          </Link>
                                        </li>
                                      );
                                    })}
                                </ul>
                              )}
                            </Transition>
                          }
                        </li>
                      );
                    } else if (organization._id === userData.organizationId) {
                      return (
                        <li
                          key={organization._id}
                          className={`has-dropdown ${
                            openChildrenFound && openChildrenFound.open
                              ? 'active'
                              : ''
                          }`}
                        >
                          <span
                            onClick={() => handleOpenChildren(organization._id)}
                          >
                            {organization.name}
                          </span>
                          {
                            <Transition
                              key={organization.id}
                              in={openChildrenFound && openChildrenFound.open}
                              timeout={100}
                              appear
                              unmountOnExit
                            >
                              {(state) => (
                                <ul
                                  className="collapsable"
                                  style={childrenTransitionStyle[state]}
                                >
                                  {sites
                                    .filter(
                                      (site) =>
                                        site.organizationId === organization._id
                                    )
                                    .map((site) => {
                                      return (
                                        <li key={site._id}>
                                          <Link
                                            to={`/${site._id}/information/${site.name}`}
                                          >
                                            {site.name}
                                          </Link>
                                        </li>
                                      );
                                    })}
                                </ul>
                              )}
                            </Transition>
                          }
                        </li>
                      );
                    }
                  })}
              </ul>
            </div>
          ) : activeMenu === 'logs' ? (
            <div className="sidebar-collapsed" style={transitionStyles[state]}>
              <div className="close" onClick={(e) => handleToggle(e, null)}>
                <i className="feather-chevron-left" />
              </div>
              <ul className="toggle-dropdown">
                <li>
                  <span className="organization-list-title">Adsign Logs</span>
                </li>
                <div className="divider" />
                <li key={0} className={`has-dropdown`}>
                  <Link to={`/authlogs`}>
                    <span>Authentication Log</span>
                    {/* Authentication Log */}
                  </Link>
                </li>
                <li key={1}>
                  <Link to={`/logs`}>
                    <span>System Log</span>
                  </Link>
                </li>
              </ul>
            </div>
          ) : (
            <div className="sidebar-collapsed" style={transitionStyles[state]}>
              <div className="close" onClick={(e) => handleToggle(e, null)}>
                <i className="feather-chevron-left" />
              </div>
              <ul className="toggle-dropdown">
                <li>
                  <span className="organization-list-title">Admin Panel</span>
                </li>
                <div className="divider" />
                <li key={0} className={`has-dropdown`}>
                  <Link to={`/panel`}>
                    <span>Role Authentication Policy</span>
                    {/* Authentication Log */}
                  </Link>
                </li>
                <li key={1}>
                  <Link to={`/paneluser`}>
                    <span>User Authentication Information</span>
                  </Link>
                </li>
              </ul>
            </div>
          )
        }
      </Transition>

      <div className={`container ${collapsed ? 'container-collapsed' : ''}`}>
        {/* navabr */}
        {/* <div className="navbar">
          <div className="profile-container">
            <div className="profile-name">
              <h3>{userName}</h3>
              <p>{userEmail}</p>
            </div> */}

        {/* <div className="profile-setting">
              <i
                className="feather-settings"
                onMouseEnter={() => setProfileToggle(!profileToggle)}
              ></i>
            </div> */}
        {/* <div className="profile-picture">
              <img
                src={`${
                  logoUrl ? `${imgUrl}/user/${logoUrl}` : defaultLogo
                }`}
                alt={userName}
                onMouseEnter={() => setProfileToggle(!profileToggle)}
              />
            </div>
          </div>
          <Transition in={profileToggle} timeout={100} appear unmountOnExit>
            {(state) => (
              <div
                className={
                  role === 'superadmin' || role === 'admin'
                    ? 'profile-dropdown admin'
                    : 'profile-dropdown non-admin'
                }
                style={childrenTransitionStyle[state]}
                onMouseLeave={() => setProfileToggle(!profileToggle)}
              >
                <svg
                  style={childrenTransitionStyle[state]}
                  className="triangle"
                  width="18"
                  height="15"
                  viewBox="0 0 18 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 0L17.6603 15H0.339746L9 0Z"
                    fill="#fff"
                    stroke="#C3CAD9"
                  />
                </svg>

                <ul>
                  <li onClick={goToProfile}>
                    <i className="feather-user" />
                    Profile
                  </li>
                  {role === 'superadmin' || role === 'admin' ? (
                    <li>
                      <Link to="/users">
                        <i className="feather-users" />
                        Users Management
                      </Link>
                    </li>
                  ) : null}
                  <li onClick={onClickLogoutHandler}>
                    <i className="feather-log-out" />
                    Logout
                  </li>
                </ul>
              </div>
            )}
          </Transition>
        </div> */}
        <div className="content">{children}</div>
        <div className="footer">
          <span>Copyright &copy; 2021 Adactive Asia. All Rights Reserved.</span>
        </div>
      </div>
    </div>
  );
}

export default BaseLayout;
