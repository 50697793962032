import {
  Breadcrumb,
  Button,
  Select,
  Table,
  Pagination,
  InputNumber,
  DatePicker,
} from 'antd';
import { useFormatMessage } from 'hooks';
import moment from 'moment';
import paths from 'pages/Router/paths';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { fetchLogs, downloadLogs } from 'state/actions/log';
import { fetchUsers } from 'state/actions/users';
import './Logs.css';
import _ from 'underscore';

const { Option } = Select;
const { RangePicker } = DatePicker;

export default function Logs({ match }) {
  const [dropdown, setDropdown] = useState();
  const [category, setCategory] = useState();
  const [dropdownType, setFilterType] = useState('All');
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(10);
  const [current, setCurrent] = useState(1);
  const page = 1;
  const [dateRange, setDateRange] = useState({
    startDate: '',
    endDate: '',
  });
  const [sort, setSort] = useState(null);

  const {
    userData,
    logsList,
    role,
    loading,
    deleted,
    error,
    userList,
    downloading,
  } = useSelector(
    (state) => ({
      userData: state.auth.userData,
      logsList: state.logs.list,
      userList: state.users.list,
      loading: state.logs.loading,
      deleted: state.logs.deleted,
      role: state.auth.userData.role,
      error: state.logs.error,
      downloading: state.logs.downloading,
    }),
    shallowEqual
  );
  const filterCategory = ['User'];

  const dispatch = useDispatch();

  useEffect(() => {
    if (role === 'admin' || role === 'superadmin') {
      dispatch(fetchLogs({ page, size: limit }));
    }
  }, [dispatch, role]); // eslint-disable-line
  useEffect(() => {
    dispatch(fetchUsers());
  }, []); // eslint-disable-line

  const handleLimit = (value) => {
    setLimit(value);
  };

  const onSearch = () => {
    const { startDate, endDate } = dateRange;
    setCurrent(1);
    dispatch(
      fetchLogs({ page, size: limit, startDate, endDate, email: category })
    );
  };

  const handlePagination = (pageNum) => {
    const { startDate, endDate } = dateRange;
    pageNum !== current && setCurrent(pageNum);
    dispatch(
      fetchLogs({
        page: pageNum,
        size: limit,
        startDate,
        endDate,
        email: category,
      })
    );
  };

  const handleDateChange = (range) => {
    const startDate = range && range[0].valueOf();
    const endDate = range && range[1].valueOf();

    setDateRange((prevState) => ({
      ...prevState,
      startDate,
      endDate,
    }));
  };

  useEffect(() => {
    if (logsList) {
      const arrayOfDropdown = [];
      switch (dropdownType) {
        case 'User':
          // setCategory('All');
          userList
            .filter((org) =>
              userData.role === 'superadmin'
                ? true
                : userData.roleAccess && userData.roleAccess._id === org.roleId
                ? true
                : false
            )
            .map((user) =>
              arrayOfDropdown.push({
                categoryId: user.email,
                name: user.name ?? user.email,
              })
            );
          // const filteredLogs =
          //   logsList.docs &&
          //   logsList.docs
          //     .filter((data) => data.email)
          //     .filter(
          //       (v, i, a) => a.findIndex((t) => t.email === v.email) === i
          //     );

          // if (filteredLogs) {
          //   for (let i = 0; i < filteredLogs.length; i++) {
          //     for (let j = 0; j < userList.length; j++) {
          //       if (filteredLogs[i].email === userList[j].email) {
          //         console.log(userList[j].name);
          //         arrayOfDropdown.push({
          //           categoryId: userList[j].email,
          //           name: userList[j].name ?? userList[j].email,
          //         });
          //       }
          //     }
          //   }
          // }
          break;

        default:
          // setCategory('All');
          break;
      }
      setDropdown(arrayOfDropdown);
    }
  }, [logsList, dropdownType]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (deleted && !loading) {
      dispatch(fetchLogs());
    }
  }, [loading, deleted]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDownload = () => {
    dispatch(downloadLogs());
  };

  // useEffect(() => {
  //   // console.log(match.params.id, 'params');
  //   // console.log('fetch user');
  //   // dispatch(fetchUsers());
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const redirect =
    role === 'admin' || role === 'superadmin' ? (
      false
    ) : (
      <Redirect to={paths.ROOT} />
    );

  // const dataByCategory =
  //   logsList.docs && dropdownType && dropdownType === 'User' && category && category !== 'All' ?
  //     logsList.docs.filter((el) => el.email === category)
  //     : logsList.docs;

  const handleChangeTable = (pagination, filters, sorter, extra) => {
    setSort(sorter);
  };

  const filteredData = search
    ? logsList.docs &&
      logsList.docs.filter((el) => {
        const clonedElem = { ...el };
        return clonedElem.message.toLowerCase().includes(search.toLowerCase());
      })
    : logsList.docs;

  const sortedData = _.sortBy(filteredData, sort ? sort.field : 'name');
  const sortedDataDirection =
    sort && sort.order === 'descend' ? sortedData.reverse() : sortedData;

  const columns = [
    {
      title: useFormatMessage('Logs.email'),
      dataIndex: 'email',
      sorter: true,
    },
    {
      title: useFormatMessage('Logs.browser'),
      dataIndex: 'browserAgent',
      sorter: true,
    },
    {
      title: useFormatMessage('Logs.ip'),
      dataIndex: 'ipAddress',
      sorter: true,
    },
    {
      title: useFormatMessage('Logs.date'),
      dataIndex: 'date',
      // sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      sorter: true,
      render: (text, record) => {
        if (record.date) {
          return (
            <div>{`${moment(record.date)
              .utcOffset('+08:00')
              .format('h:mm:ss A, MMM Do YYYY')}`}</div>
          );
        }
        return <span>-</span>;
      },
    },
    {
      title: useFormatMessage('Logs.status'),
      dataIndex: 'status',
      sorter: true,
      render: (text, record) => {
        if (record.status === 500 || record.status === 422) {
          return <div>Error</div>;
        } else if (record.status === 201 || record.status === 200) {
          return <div>Success</div>;
        }
      },
    },
    {
      title: useFormatMessage('Logs.message'),
      dataIndex: 'message',
      sorter: true,
      render: (text, record) => {
        return <span>{record.message}</span>;
      },
    },
  ];

  return (
    <section className="logs">
      {redirect}
      <div className="logs-header">
        <div className="header-left-content">
          <div className="breadcrumb-wrapper">
            <Breadcrumb>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item className="breadcrumb-active">
                System Logs
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <h1 className="page-title">System Logs</h1>
        </div>
      </div>
      <div className="logs-menu">
        <div className="logs-dropdown">
          <span>Filter by: </span>
          <Select defaultValue="All" onChange={(id) => setFilterType(id)}>
            {filterCategory &&
              filterCategory.map((data) => (
                <Option key={data} value={data}>
                  {data}
                </Option>
              ))}
          </Select>
          {/* <span className="logs-dropdown span"></span> */}
          {dropdownType !== 'All' && dropdown && (
            <Select
              value={category}
              defaultValue="All"
              onChange={(id) => {
                setCategory(id);
              }}
            >
              <Option value="All">All</Option>
              {dropdown &&
                dropdown.map((data) => (
                  <Option key={data.categoryId} value={data.categoryId}>
                    {data.name}
                  </Option>
                ))}
            </Select>
          )}
        </div>
        <div className="search-bar">
          <i className="feather-search" />
          <input
            type="text"
            placeholder="Search..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <div className="logs-download">
          {downloading ? (
            <button className="button-secondary button-loading mr-1">
              <i className="mdi mdi-spin mdi-loading" />
              Loading...
            </button>
          ) : (
            <button className="button-secondary mr-1" onClick={handleDownload}>
              <i className="feather-download" />
              Download CSV
            </button>
          )}
        </div>
      </div>

      <div className="logs-search">
        <div className="logs-limit">
          <label className="logs-limit-label">Total Data: </label>
          <InputNumber
            value={limit}
            onChange={handleLimit}
            className="logs-limit-input"
          />
        </div>

        <RangePicker className="logs-date-picker" onChange={handleDateChange} />

        <div className="logs-btn">
          <Button type="primary" className="btn-primary" onClick={onSearch}>
            Search
          </Button>
        </div>
      </div>

      <div className="logs__table">
        {loading && filteredData ? (
          <ClipLoader />
        ) : (
          <Table
            columns={columns}
            dataSource={sortedDataDirection}
            rowKey="_id"
            pagination={false}
            onChange={handleChangeTable}
          />
        )}
        {error && 'Show error'}
        <div className="logs-pagination">
          {logsList && (
            <Pagination
              current={current}
              defaultCurrent={1}
              total={logsList.totalDocs}
              pageSize={limit}
              onChange={handlePagination}
              showSizeChanger={false}
            />
          )}
        </div>
      </div>
    </section>
  );
}
