import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { authReducer } from './auth';
import { usersReducer } from './users';
import { organizationsReducer } from './organizations';
import { sitesReducer } from './sites';
import { preferencesReducer } from './preferences';
import { respondentReducer } from './respondent';
import { logsReducer } from './log';
import { tabReducer } from './tab';
import { configReducer } from './config';
import { settingsReducer } from './settings';
import { playlistReducer } from './playlist';
import { apiReducer } from './api';
import { roleReducer } from './roles';
import { panelReducer } from './panel';
import { analyticReducer } from './analytic';

export default combineReducers({
  auth: persistReducer(
    {
      key: 'auth',
      storage,
      blacklist: ['error', 'loading'],
    },
    authReducer
  ),
  api: persistReducer(
    {
      key: 'api',
      storage,
      blacklist: ['error', 'loading'],
    },
    apiReducer
  ),
  preferences: persistReducer(
    { key: 'preferences', storage },
    preferencesReducer
  ),
  users: persistReducer(
    {
      key: 'users',
      storage,
      blacklist: ['error', 'loading'],
    },
    usersReducer
  ),
  organizations: persistReducer(
    {
      key: 'organizations',
      storage,
      blacklist: ['error', 'loading'],
    },
    organizationsReducer
  ),
  sites: persistReducer(
    {
      key: 'sites',
      storage,
      blacklist: ['error', 'loading'],
    },
    sitesReducer
  ),
  respondent: persistReducer(
    {
      key: 'respondent',
      storage,
      blacklist: ['error', 'loading'],
    },
    respondentReducer
  ),
  config: persistReducer(
    {
      key: 'config',
      storage,
      blacklist: ['error', 'loading'],
    },
    configReducer
  ),
  settings: persistReducer(
    {
      key: 'settings',
      storage,
      blacklist: ['error', 'loading'],
    },
    settingsReducer
  ),
  playlist: persistReducer(
    {
      key: 'playlist',
      storage,
      blacklist: ['error', 'loading'],
    },
    playlistReducer
  ),
  logs: persistReducer(
    {
      key: 'logs',
      storage,
      blacklist: ['error', 'loading'],
    },
    logsReducer
  ),
  roles: persistReducer(
    {
      key: 'roles',
      storage,
      blacklist: ['error', 'loading'],
    },
    roleReducer
  ),
  panel: persistReducer(
    {
      key: 'panel',
      storage,
      blacklist: ['error', 'loading'],
    },
    panelReducer
  ),
  analytic: persistReducer(
    {
      key: 'analytic',
      storage,
      blacklist: ['error', 'loading'],
    },
    analyticReducer
  ),
  toastr: toastrReducer,
  tab: tabReducer,
});
