/* eslint-disable jsx-a11y/label-has-associated-control */
import { Form, Input, Modal, Select } from 'antd';
import { useChangeHandler } from 'hooks';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { sitesCleanUp } from 'state/actions/sites';
import { regexDescription, regexInput } from 'utils/regex';

import { log } from 'utils';
const { Option } = Select;

const ModalSiteForm = ({
  site,
  setSite,
  visible,
  onCancel,
  onCreate,
  isEditing,
}) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [errors, setErrors] = useState({
    name: '',
    description: '',
  });

  const { organizations, loading } = useSelector(
    (state) => ({
      organizations: state.organizations.list,
      loading: state.sites.loading,
    }),
    shallowEqual
  );

  useEffect(() => {
    return () => dispatch(sitesCleanUp());
  }, [dispatch]);

  const onChangeHandler = useChangeHandler(setSite);

  const onSelectOrganizationHandler = (values, option) => {
    const organizationId = option.value;
    const organizationName = option.children;
    setSite((prevState) => ({
      ...prevState,
      organizationId,
      organizationName,
    }));
  };

  const handleSave = (values) => {
    if (regexInput(values.name) && regexDescription(values.description)) {
      onCreate({
        ...site,
        id: site._id,
        name: values.name,
        organizationId: values.organization,
        description: values.description,
      });
      form.resetFields();
    } else {
      setErrors({
        ...errors,
        name:
          !regexInput(values.name) &&
          'This field cannot contain a special characters',
        description:
          !regexDescription(values.description) &&
          'This field cannot contain a special characters',
      });
    }
  };

  return (
    <Modal
      visible={visible}
      centered
      title={isEditing ? 'Edit Site' : 'Create New Site'}
      okText="Submit"
      confirmLoading={loading}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            handleSave(values);
          })
          .catch((info) => {
            log('Validate Failed:', info);
          });
      }}
    >
      <Form
        layout="vertical"
        form={form}
        onChange={onChangeHandler}
        initialValues={{
          name: site.name,
          organization: site.organizationId,
          description: site.description,
        }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true }]}
          validateStatus={errors.name ? 'error' : 'success'}
          help={errors.name ? errors.name : null}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="organization"
          label="Organization"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder="Select Organization"
            onChange={onSelectOrganizationHandler}
            allowClear
          >
            {organizations &&
              organizations.map((organization) => (
                <Option value={organization._id} key={organization.id}>
                  {organization.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: true }]}
          validateStatus={errors.description ? 'error' : 'success'}
          help={errors.description ? errors.description : null}
        >
          <Input.TextArea rows={4} placeholder="Description" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

ModalSiteForm.propTypes = {
  userData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
  }),
};

export default ModalSiteForm;
