import axios from 'axios';
import fileDownload from 'js-file-download';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import _ from 'underscore';
import { errorMsg, log } from 'utils';
import { url } from 'utils/url';

export const ANALYTICS_FETCH_DATA_INIT = createAction(
  'ANALYTICS_FETCH_DATA_INIT'
);
export const ANALYTICS_FETCH_DATA_SUCCESS = createAction(
  'ANALYTICS_FETCH_DATA_SUCCESS'
);
export const ANALYTICS_FETCH_LEADERBOARD_INIT = createAction(
  'ANALYTICS_FETCH_LEADERBOARD_INIT'
);
export const ANALYTICS_FETCH_LEADERBOARD_SUCCESS = createAction(
  'ANALYTICS_FETCH_LEADERBOARD_SUCCESS'
);
export const ANALYTICS_FETCH_PROFILE_SUCCESS = createAction(
  'ANALYTICS_FETCH_PROFILE_SUCCESS'
);
export const ANALYTICS_FETCH_DATA_FAIL = createAction(
  'ANALYTICS_FETCH_DATA_FAIL'
);
export const ANALYTICS_FETCH_LEADERBOARD_FAIL = createAction(
  'ANALYTICS_FETCH_LEADERBOARD_FAIL'
);
export const ANALYTICS_CALCULATE_DATA_INIT = createAction(
  'ANALYTICS_CALCULATE_DATA_INIT'
);
export const ANALYTICS_CALCULATE_DATA_SUCCESS = createAction(
  'ANALYTICS_CALCULATE_DATA_SUCCESS'
);
export const ANALYTICS_EXPORT_ANALYTIC_INIT = createAction(
  'ANALYTICS_EXPORT_ANALYTIC_INIT'
);
export const ANALYTICS_EXPORT_ANALYTIC_SUCCESS = createAction(
  'ANALYTICS_EXPORT_ANALYTIC_SUCCESS'
);
export const ANALYTICS_EXPORT_ANALYTIC_FAIL = createAction(
  'ANALYTICS_EXPORT_ANALYTIC_FAIL'
);

export const fetchAnalytics = ({ start, end, type, action, siteId, email }) => {
  return async (dispatch) => {
    dispatch(ANALYTICS_FETCH_DATA_INIT());
    let analytic;
    try {
      analytic = await axios.post(
        `${url}/analytics/get`,

        {
          start,
          end,
          siteId,
          type,
          action,
          email,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
    } catch (error) {
      console.log(error);
      toastr.error('', `${errorMsg(dispatch, error)}`);
      return dispatch(ANALYTICS_FETCH_DATA_FAIL({ error }));
    }

    let dataTable = analytic.data.rawData;
    log(analytic.data.rawData, 'rawdata');
    // const groupedData = await _.groupBy(analytic.data.rawData, 'adsumName');
    // const keys = _.keys(groupedData).map((k) =>
    //   dataTable.push({
    //     name: k,
    //     count: groupedData[k].length,
    //     select: groupedData[k].filter((data) => data.action === 'select')
    //       .length,
    //     goto: groupedData[k].filter((data) => data.action === 'goTo').length,
    //     scan: groupedData[k].filter((data) => data.action === 'scan').length,
    //   })
    // );

    const session = dataTable.find((data) => data.name === 'Session');

    dataTable = dataTable.filter((data) => data.name !== 'Session');
    dataTable = _.sortBy(dataTable, 'count');

    if (session) dataTable.unshift(session);
    log(analytic.data.data, analytic.data.rawData, 'analytic result');
    // dispatch(calculateData({ data: analytic.data.rawData }));
    return dispatch(
      ANALYTICS_FETCH_DATA_SUCCESS({
        data: analytic.data.data,
        rawData: dataTable,
      })
    );
  };
};
export const fetchLeaderboard = ({ start, end, siteId, adsumScreenIds }) => {
  return async (dispatch) => {
    dispatch(ANALYTICS_FETCH_LEADERBOARD_INIT());
    let analytic;
    try {
      analytic = await axios.post(
        `${url}/analytic/getTop`,

        {
          start,
          end,
          siteId,
          adsumScreenIds,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
    } catch (error) {
      console.log(error);
      toastr.error('', `${errorMsg(dispatch, error)}`);
      return dispatch(ANALYTICS_FETCH_LEADERBOARD_FAIL({ error }));
    }

    // dispatch(calculateData({ data: analytic.data.rawData }));
    return dispatch(
      ANALYTICS_FETCH_LEADERBOARD_SUCCESS({
        data: analytic.data.data,
      })
    );
  };
};

export const calculateData = ({ data }) => {
  return async (dispatch) => {
    dispatch(ANALYTICS_CALCULATE_DATA_INIT());

    console.log(data, 'data calculate');

    let result = [];

    const groupedDuration = _.groupBy(data, 'adsumScreenId');
    const keys = Object.keys(groupedDuration);

    for (const k of keys) {
      const content = groupedDuration[k];
      result.push({ x: k, y: content.length });
    }

    result = _.sortBy(result, 'y').reverse();
    console.log(result, 'sorted');

    return dispatch(
      ANALYTICS_CALCULATE_DATA_SUCCESS({
        rawData: result,
      })
    );
  };
};

export const downloadAnalytics = (body) => {
  return async (dispatch) => {
    dispatch(ANALYTICS_EXPORT_ANALYTIC_INIT());

    axios
      .post(`${url}/analytics/exportAll`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(
          res.data,
          `logs_export_rawdata_${moment(new Date()).format('YYYY-MM-DD')}.csv`
        );
        return dispatch(ANALYTICS_EXPORT_ANALYTIC_SUCCESS());
      })
      .catch((error) => {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(ANALYTICS_EXPORT_ANALYTIC_FAIL({ error }));
      });
  };
};
export const downloadAnalyticsLeaderboard = (body) => {
  return async (dispatch) => {
    dispatch(ANALYTICS_EXPORT_ANALYTIC_INIT());
    console.log(body, 'body');

    axios
      .post(`${url}/analytics/export`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(
          res.data,
          `logs_export_${body.type}_${moment(new Date()).format(
            'YYYY-MM-DD'
          )}.csv`
        );
        return dispatch(ANALYTICS_EXPORT_ANALYTIC_SUCCESS());
      })
      .catch((error) => {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(ANALYTICS_EXPORT_ANALYTIC_FAIL({ error }));
      });
  };
};

export const loadingAnalytic = () => {
  return async (dispatch) => {
    return dispatch(ANALYTICS_FETCH_DATA_INIT());
  };
};
