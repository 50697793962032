import { Tabs, Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
// import Maps from 'pages/Maps';
import { log } from 'utils';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setActiveTab } from 'state/actions/tab';
import './SiteInfo.css';
import SocialWall from 'pages/SocialWall';
import ArrivalLandmark from 'pages/ArrivalLandmark';
import ActivityPillar from 'pages/ActivityPillar';
import Wayfinding from 'pages/Wayfinding';
import CuratedPlaylist from 'pages/CuratedPlaylist';
import { fetchPlaylist } from 'state/actions/playlist';
import Analytics from 'pages/Analytics';

const { TabPane } = Tabs;

function SiteInfo({ history, setCollapsed }) {
  const { siteId, siteName } = useParams();
  const [selected, setSelected] = useState();
  const [grantAccess, setGrantAccess] = useState();
  const [selectedMenu, setSelectedMenu] = useState('search');
  const dispatch = useDispatch();
  const { tab: activeTab, userData, sitesList } = useSelector((state) => ({
    tab: state.tab.tab,
    userData: state.auth.userData,
    sitesList: state.sites.list,
  }));

  useEffect(() => {
    const acc =
      userData.role !== 'superadmin' && userData.roleAccess
        ? userData.roleAccess.access.find(
            (userAccess) => userAccess.siteId === siteId
          )
        : userData.access.find((userAccess) => userAccess.siteId === siteId) ??
          userData.access[0];
    setGrantAccess(acc ?? userData.access[0]);
  }, []); // eslint-disable-line
  useEffect(() => {
    if (selected) {
      dispatch(setActiveTab({ tab: selected }));
    }
  }, [selected]); // eslint-disable-line

  useEffect(() => {
    setSelectedMenu('search');
  }, [activeTab]); // eslint-disable-line

  const handleChangeTab = (activeKey) => {
    log(activeKey);
    if (activeKey === 'arrival-landmark') {
      dispatch(fetchPlaylist({ siteId, type: 'artwork' }));
    }
    setActiveTab(activeKey);
    dispatch(setActiveTab({ tab: activeKey }));
  };

  // const handleBack = () => {
  //   sessionStorage.setItem('activeMenu', 'dashboard');
  //   setCollapsed(false);
  //   history.push('/'); // eslint-disable-line
  // };

  const siteDescription =
    sitesList &&
    // eslint-disable-next-line
    sitesList.map((site) => {
      if (site.name === siteName) {
        return site.description;
      }
    });

  const api = (
    <Menu onClick={(e) => setSelected(e.key)}>
      <Menu.Item key="activity-pillar">
        <div>
          <i className="feather-book" /> Gallery Highlights
        </div>
      </Menu.Item>
      <Menu.Item key="curated-playlist">
        <div>
          <i className="feather-book" /> Curated Playlists
        </div>
      </Menu.Item>
      <Menu.Item key="social-wall">
        <div>
          <i className="feather-image" /> Visitor Gallery
        </div>
      </Menu.Item>

      <Menu.Item key="wayfinding">
        <div>
          <i className="feather-book" /> Wayfinding
        </div>
      </Menu.Item>
    </Menu>
  );

  const validateMenu = (crud) => {
    if (typeof crud !== 'object') return crud;
    return Object.values(crud).includes(true);
  };

  return (
    <div className="siteinfo">
      <div className="siteinfo-nav">
        <div>
          <h3 className="title">{siteName}</h3>
          <span>{siteDescription && siteDescription}</span>
        </div>
        {/* <button onClick={handleBack} className="button-primary">
          Back
        </button> */}
      </div>

      {userData && userData.role === 'superadmin' ? (
        <Tabs activeKey={activeTab} overlay={api} onTabClick={handleChangeTab}>
          <TabPane
            tab={
              <Dropdown overlay={api} placement="bottomCenter">
                <div onClick={(e) => e.preventDefault()}>
                  <i className="feather-image" /> Activity Pillar{' '}
                  <DownOutlined />
                </div>
              </Dropdown>
            }
            key={
              activeTab === 'social-wall'
                ? 'social-wall'
                : activeTab === 'curated-playlist'
                ? 'curated-playlist'
                : activeTab === 'activity-pillar'
                ? 'activity-pillar'
                : activeTab === 'wayfinding'
                ? 'wayfinding'
                : 'activity-pillar'
            }
          >
            <div className="tab-content">
              <div className="playlist-menu">
                <div className="menu-tab-side">
                  <ul>
                    <li
                      className={`${selectedMenu === 'search' ? 'active' : ''}`}
                      onClick={() => setSelectedMenu('search')}
                    >
                      <i className="feather-search" />
                      Search
                    </li>
                    {activeTab !== 'curated-playlist' && (
                      <li
                        className={`${
                          selectedMenu === 'playlist' ? 'active' : ''
                        }`}
                        onClick={() => {
                          setSelectedMenu('playlist');
                        }}
                      >
                        <i className="feather-list" />
                        Playlist
                      </li>
                    )}
                  </ul>
                </div>
                <div style={{ width: '100%' }}>
                  {activeTab === 'social-wall' && (
                    <SocialWall
                      current={selectedMenu}
                      playlistName={activeTab}
                    />
                  )}
                  {activeTab === 'activity-pillar' && (
                    <ActivityPillar
                      current={selectedMenu}
                      playlistName={activeTab}
                    />
                  )}
                  {activeTab === 'wayfinding' && (
                    <Wayfinding
                      current={selectedMenu}
                      playlistName={activeTab}
                    />
                  )}
                  {activeTab === 'curated-playlist' && (
                    <CuratedPlaylist
                      current={selectedMenu}
                      playlistName={activeTab}
                    />
                  )}
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane
            tab={
              <>
                <i className="feather-map" /> Arrival Landmark
              </>
            }
            key="arrival-landmark"
          >
            <div className="tab-content">
              <div className="tab-content">
                <div className="playlist-menu">
                  <div className="menu-tab-side">
                    <ul>
                      <li
                        className={`${
                          selectedMenu === 'search' ? 'active' : ''
                        }`}
                        onClick={() => setSelectedMenu('search')}
                      >
                        <i className="feather-search" />
                        Search
                      </li>
                      <li
                        className={`${
                          selectedMenu === 'playlist' ? 'active' : ''
                        }`}
                        onClick={() => {
                          setSelectedMenu('playlist');
                        }}
                      >
                        <i className="feather-list" />
                        Playlist
                      </li>
                      <li
                        className={`${
                          selectedMenu === 'playlist-a' ? 'active' : ''
                        }`}
                        onClick={() => {
                          setSelectedMenu('playlist-a');
                        }}
                      >
                        <i className="feather-list" />
                        Playlist Staging A
                      </li>
                      <li
                        className={`${
                          selectedMenu === 'playlist-b' ? 'active' : ''
                        }`}
                        onClick={() => {
                          setSelectedMenu('playlist-b');
                        }}
                      >
                        <i className="feather-list" />
                        Playlist Staging B
                      </li>
                    </ul>
                  </div>
                  <div style={{ width: '100%' }}>
                    {activeTab === 'arrival-landmark' && (
                      <ArrivalLandmark
                        current={selectedMenu}
                        playlistName={activeTab}
                      />
                    )}
                  </div>
                </div>
                {/* )} */}
              </div>
            </div>
          </TabPane>
          <TabPane
            tab={
              <div onClick={(e) => e.preventDefault()} className="siteinfo-tab">
                <i className="feather-bar-chart" /> <span>Analytic</span>
              </div>
            }
            key="analytic"
          >
            <div className="tab-content">
              {activeTab === 'analytic' && <Analytics />}
            </div>
          </TabPane>
        </Tabs>
      ) : userData.role === 'user' || userData.role === 'admin' ? (
        <Tabs defaultActiveKey={activeTab} onTabClick={handleChangeTab}>
          {grantAccess && validateMenu(grantAccess.activity_pillar) && (
            <TabPane
              tab={
                <Dropdown overlay={api} placement="bottomCenter">
                  <div onClick={(e) => e.preventDefault()}>
                    <i className="feather-image" /> Activity Pillar{' '}
                    <DownOutlined />
                  </div>
                </Dropdown>
              }
              key={
                activeTab === 'social-wall'
                  ? 'social-wall'
                  : activeTab === 'curated-playlist'
                  ? 'curated-playlist'
                  : activeTab === 'activity-pillar'
                  ? 'activity-pillar'
                  : 'social-wall'
              }
            >
              <div className="tab-content">
                <div className="playlist-menu">
                  <div className="menu-tab-side">
                    <ul>
                      <li
                        className={`${
                          selectedMenu === 'search' ? 'active' : ''
                        }`}
                        onClick={() => setSelectedMenu('search')}
                      >
                        <i className="feather-search" />
                        Search
                      </li>
                      <li
                        className={`${
                          selectedMenu === 'playlist' ? 'active' : ''
                        }`}
                        onClick={() => {
                          setSelectedMenu('playlist');
                        }}
                      >
                        <i className="feather-list" />
                        Playlist
                      </li>
                    </ul>
                  </div>
                  <div style={{ width: '100%' }}>
                    {activeTab === 'social-wall' && (
                      <SocialWall
                        current={selectedMenu}
                        playlistName={activeTab}
                      />
                    )}
                    {activeTab === 'activity-pillar' && (
                      <ActivityPillar
                        current={selectedMenu}
                        playlistName={activeTab}
                      />
                    )}
                    {activeTab === 'wayfinding' && (
                      <Wayfinding
                        current={selectedMenu}
                        playlistName={activeTab}
                      />
                    )}
                    {activeTab === 'curated-playlist' && (
                      <CuratedPlaylist
                        current={selectedMenu}
                        playlistName={activeTab}
                      />
                    )}
                  </div>
                </div>
                {/* )} */}
              </div>
            </TabPane>
          )}

          {grantAccess && validateMenu(grantAccess.arrival_landmark) && (
            <TabPane
              tab={
                <>
                  <i className="feather-map" /> Arrival Landmark
                </>
              }
              key="arrival-landmark"
            >
              <div className="tab-content">
                {activeTab === 'arrival-landmark' && (
                  <div className="tab-content">
                    <div className="playlist-menu">
                      <div className="menu-tab-side">
                        <ul>
                          <li
                            className={`${
                              selectedMenu === 'search' ? 'active' : ''
                            }`}
                            onClick={() => setSelectedMenu('search')}
                          >
                            <i className="feather-search" />
                            Search
                          </li>
                          <li
                            className={`${
                              selectedMenu === 'playlist' ? 'active' : ''
                            }`}
                            onClick={() => {
                              setSelectedMenu('playlist');
                            }}
                          >
                            <i className="feather-list" />
                            Playlist
                          </li>
                          <li
                            className={`${
                              selectedMenu === 'playlist-a' ? 'active' : ''
                            }`}
                            onClick={() => {
                              setSelectedMenu('playlist-a');
                            }}
                          >
                            <i className="feather-list" />
                            Playlist Staging A
                          </li>
                          <li
                            className={`${
                              selectedMenu === 'playlist-b' ? 'active' : ''
                            }`}
                            onClick={() => {
                              setSelectedMenu('playlist-b');
                            }}
                          >
                            <i className="feather-list" />
                            Playlist Staging B
                          </li>
                        </ul>
                      </div>
                      <div style={{ width: '100%' }}>
                        {activeTab === 'arrival-landmark' && (
                          <ArrivalLandmark
                            current={selectedMenu}
                            playlistName={activeTab}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </TabPane>
          )}

          <TabPane
            tab={
              <div onClick={(e) => e.preventDefault()} className="siteinfo-tab">
                <i className="feather-bar-chart" /> <span>Analytic</span>
              </div>
            }
            key="analytic"
          >
            <div className="tab-content">
              {activeTab === 'analytic' && <Analytics />}
            </div>
          </TabPane>
        </Tabs>
      ) : null}
    </div>
  );
}

export default SiteInfo;
