import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Pagination,
  Select,
  Tag,
  message,
  Tooltip,
  Upload,
} from 'antd';
import PlaylistArrival from 'components/PlaylistArrival';
// import PlaylistPreview from 'components/PlaylistPreview';
import { TweenOneGroup } from 'rc-tween-one';
import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import {
  fetchInstagram,
  fetchInstagramUsername,
  fetchYoutube,
  submitAddImage,
  deletePlaylist,
} from 'state/actions/api';
import { addPostPlaylist, fetchPlaylist } from 'state/actions/playlist';
import { regexInput } from 'utils/regex';

import { imgUrl } from 'utils/url';
import uuid from 'uuid';
import './SocialWall.css';

const { Option } = Select;
const { CheckableTag } = Tag;

function SocialWall({ current }) {
  const saveInputRef = useRef(null);
  const { siteId } = useParams();
  const [playlist, setPlaylist] = useState();
  const [tags, setTags] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState(null);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(8);
  const [selected, setSelected] = useState([]);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [sizePage, setSizePage] = useState(8);
  const [modalUploadURL, setModalUploadURL] = useState(false);
  const [isDragged, setIsDragged] = useState(false);
  const [searchType, setSearchType] = useState('hashtag');
  // const [apiList, setApiList] = useState([]);
  // const [isYoutube, setIsYoutube] = useState(false);
  const [mediaPreviewModal, setMediaPreviewModal] = useState({
    isOpen: false,
    name: '',
    description: '',
    video_url: '',
    image_url: '',
  });

  const [media, setMedia] = useState({
    file: null,
    logo: null,
    name: '',
    description: '',
    video_url: '',
    image_url: '',
    type: '',
  });

  const [deleteModal, setDeleteModal] = useState({
    list: [],
    isOpen: false,
  });

  const {
    artwork,
    list,
    loading,
    playlistList,
    updated,
    uploading,
    deleted,
    // artwork,
  } = useSelector(
    (state) => ({
      list: state.api.list,
      artwork: state.api.artwork,
      loading: state.api.loading,
      playlistList: state.playlist.list,
      updated: state.playlist.added,
      uploading: state.api.uploading,
      deleted: state.api.deleted,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPlaylist({ siteId, type: 'instagram' }));
  }, [siteId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(fetchYoutube({ siteId, type: 'youtube' }));
  }, [siteId]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   setApiList(list.concat(artwork));
  // }, [list.length, artwork.length]); // eslint-disable-line

  // useEffect(() => {
  //   dispatch(fetchYoutube({ siteId, type: 'youtube' }));
  // }, [list.length]); // eslint-disable-line

  useEffect(() => {
    setSelected([]);
  }, [updated]);

  // if (apiList) {
  //   console.log(apiList, '<<< API LISTTT');
  // }

  useEffect(() => {
    if ((uploading && !loading) || (deleted && !loading)) {
      dispatch(fetchYoutube({ siteId, type: 'youtube' }));
      setDeleteModal((prevState) => ({
        ...prevState,
        list: [],
        isOpen: false,
      }));

      handleReset();
    }
    // setMedias(mediaList);
  }, [uploading, loading, deleted]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   if (playlist && playlist.name === 'Gallery Gigs') {
  //     setIsYoutube(true);
  //   } else {
  //     setIsYoutube(false);
  //   }
  // }, [playlist]);

  const handleChangePage = (value) => {
    setCurrentPage(value);
    if (value <= 1) {
      setMinValue(0);
      setMaxValue(sizePage);
    } else {
      setMinValue(value * sizePage - sizePage);
      setMaxValue(value * sizePage);
    }
  };

  const onSearch = () => {
    if (tags.length > 0) {
      if (searchType === 'hashtag') {
        dispatch(fetchInstagram({ tags, siteId, limit }));
      } else {
        dispatch(fetchInstagramUsername({ tags, siteId, limit }));
      }
    } else {
      toastr.error('', 'Input tags or username you want to search');
    }
  };

  const onShowSizeChange = (current, pageSize) => {
    setSizePage(pageSize);
    setCurrentPage(current);
  };

  const onAddImages = (img, checked) => {
    if (checked) {
      setSelected([...selected, img]);
    } else {
      const index = selected.indexOf(img);
      selected.splice(index, 1);
      setSelected([...selected]);
    }
  };

  const trimString = (string, length) => {
    return string.length > length
      ? `${string.substring(0, length)}...`
      : string;
  };

  const onPreviewMediaClickHandler = (post) => {
    setMediaPreviewModal((prevState) => ({
      ...prevState,
      isOpen: true,
      image_url:
        post.type === 'Image' || post.type === 'Sidecar'
          ? post.displayUrl
          : post.type === 'youtube'
          ? post.url
          : '',
      video_url: post.type === 'Video' ? post.displayUrl : '',
      title: post.type === 'youtube' ? post.name : post.ownerUsername,
      description: post.caption,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal((prevState) => ({
      ...prevState,
      isOpen: !prevState.isOpen,
    }));
  };

  // const onDeleteButtonHandler = () => {
  //   setDeleteModal((prevState) => ({
  //     ...prevState,
  //     list: selected.map((item) => item),
  //     isOpen: true,
  //   }));
  // };

  const onDeleteItemHandler = () => {
    dispatch(deletePlaylist({ list: deleteModal.list }));
  };

  // if (playlist) {
  //   console.log(playlist, '<< playlist');
  // }

  // const data = apiList;

  // eslint-disable-next-line
  const onAddToPlaylist = () => {
    if (!playlist) {
      toastr.error('', 'Choose the playlist');
    }
    if (selected.length < 1) {
      toastr.error('', 'Choose images');
    }

    // gallery gig
    if (
      // playlist.id === '61e7884ff5c29545d322386e' // id dev
      playlist.id === '61ee3899d0489814a4cf64f1' && // id prod
      playlist.posts.length > 4
    ) {
      toastr.error('', 'Max 4 items can be added to Gallery Gigs');
    }
    // gallery bottom left
    // if (
    //   // playlist.id === '621d9e790cf9ef3e9d154721' // id dev
    //   playlist.id === '621dccea64e8206ef82cd2ed' && // id prod
    //   playlist.posts.length > 6
    // ) {
    //   toastr.error('', 'Max 6 items can be added to Gallery Bottom Left');
    // }
    // // gallery bottom right
    // if (
    //   // playlist.id === '621d9e7e0cf9ef3e9d154728' // id dev
    //   playlist.id === '621dcce464e8206ef82cd2e6' && // id prod
    //   playlist.posts.length > 6
    // ) {
    //   toastr.error('', 'Max 6 items can be added to Gallery Bottom Right');
    // }
    // // gallery top left
    // if (
    //   // playlist.id === '621d9e8b0cf9ef3e9d154736' // id dev
    //   playlist.id === '621dccae64e8206ef82cd2c9' && // id prod
    //   playlist.posts.length > 6
    // ) {
    //   toastr.error('', 'Max 6 items can be added to Gallery Top Left');
    // }
    // // gallery top right
    // if (
    //   // playlist.id === '621d9e850cf9ef3e9d15472f' // id dev
    //   playlist.id === '621dccd864e8206ef82cd2d6' && // id prod
    //   playlist.posts.length > 6
    // ) {
    //   toastr.error('', 'Max 6 items can be added to Gallery Top Right');
    // }

    if (playlist && selected.length > 0) {
      const post = [...playlist.posts];
      selected.map((art) =>
        post.push(
          art.displayUrl
            ? {
                id: uuid(),
                name:
                  art.hashtags.length > 0 ? art.hashtags[0] : art.ownerUsername,
                description: art.caption ?? '',
                image_url: art.displayUrl,
              }
            : art.url
            ? {
                id: uuid(),
                name: art.name,
                description: art.description,
                image_url: art.url,
                youtube_url: art.video_url,
              }
            : art.video_url
            ? {
                id: uuid(),
                name: art.name,
                description: art.description,
                video_url: art.video_url,
              }
            : ''
        )
      );
      if (playlist.name === 'Main Visitor Gallery' && post.length > 8) {
        return toastr.error('Max items 8');
      }
      playlist.posts = post;
      dispatch(
        addPostPlaylist({
          playlist,
          access: 'activity_pillar',
          action: 'update',
          message: 'add_post',
        })
      );
    }
  };
  const handleLimit = (value) => {
    setError(null);
    if (!regexInput(value) && value) {
      setError('Search cannot using special character');
    }

    setLimit(value);
  };

  const hidePreviewModal = () => {
    setMediaPreviewModal((prevState) => ({
      ...prevState,
      isOpen: !prevState.isOpen,
    }));
  };

  const handleCloseTag = (removedTag) => {
    const updatedTags = tags.filter((tag) => tag !== removedTag);
    setTags(updatedTags);
  };

  useEffect(() => {
    if (inputVisible) {
      saveInputRef.current.focus();
    }
  }, [inputVisible]);

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    setInputVisible(false);
    setInputValue('');

    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue]);
    }
  };

  const imagePreviewUrl = media.file
    ? media.file && URL.createObjectURL(media.file)
    : media.logo && `${imgUrl}/media/${media.logo}`;

  const onFileChangedHandler = (file) => {
    if (
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'video/mp4'
    ) {
      if (file.size / 1000000 <= 50) {
        setMedia((prevState) => ({ ...prevState, file, logo: null }));
      } else {
        message.error('Maximum file size is 50MB');
      }
    } else {
      message.error('Unsupported filetype!');
    }
    setIsDragged(false);
  };

  const handleDeletePreviewImage = () => {
    setMedia((prevState) => ({ ...prevState, file: null, logo: null }));
  };

  const handleChange = (name, value) => {
    setMedia({
      ...media,
      [name]: value,
    });
  };

  const handleReset = () => {
    setModalUploadURL(false);

    setMedia({
      file: null,
      logo: null,
      name: '',
      description: '',
      video_url: '',
      type: '',
    });
  };

  const handleSubmit = () => {
    if (media.name && media.description && media.file) {
      dispatch(submitAddImage({ media, siteId, type: 'youtube' }));
    }
  };

  const forMap = (tag) => {
    const tagElem = (
      <Tag
        closable
        onClose={(e) => {
          e.preventDefault();
          handleCloseTag(tag);
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <span key={tag} style={{ display: 'inline-block' }}>
        {tagElem}
      </span>
    );
  };

  const tagChild = tags.map(forMap);

  return (
    <div className="media__library">
      <Modal
        title="Are you sure to delete this item?"
        visible={deleteModal.isOpen}
        onOk={onDeleteItemHandler}
        onCancel={onCloseModalHandler}
      >
        Are you sure to delete this item?
      </Modal>

      <Modal
        title="Upload URL Link"
        visible={modalUploadURL}
        className="media-form"
        onCancel={handleReset}
        okText="Submit"
        onOk={handleSubmit}
        okButtonProps={{
          loading,
        }}
        cancelButtonProps={{
          hidden: loading,
        }}
      >
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          layout="horizontal"
        >
          <Form.Item
            label="Name"
            rules={[{ required: true, message: 'Name is required!' }]}
          >
            <Input
              placeholder="Name"
              onChange={(e) => handleChange('name', e.target.value)}
              value={media.name}
            />
          </Form.Item>
          <Form.Item
            label="Description"
            rules={[{ required: true, message: 'Description is required' }]}
          >
            <Input.TextArea
              placeholder="Description"
              rows={6}
              onChange={(e) => handleChange('description', e.target.value)}
              value={media.description}
            />
          </Form.Item>
          <Form.Item label="Upload" required>
            {imagePreviewUrl ? (
              <div className="upload-image-preview">
                <Tooltip placement="top" title="Delete">
                  <button
                    type="button"
                    onClick={handleDeletePreviewImage}
                    className="btn-remove"
                  >
                    <i className="feather-x" />
                  </button>
                </Tooltip>
                {(media.file && media.file.type === 'video/mp4') ||
                (media.fileType === 'video/mp4' && !media.file) ? (
                  <video
                    controls="controls"
                    preload="metadata"
                    key={imagePreviewUrl}
                  >
                    <source src={imagePreviewUrl} type="video/mp4" />
                  </video>
                ) : (
                  <img
                    className="media-avatar"
                    src={imagePreviewUrl}
                    alt="User profile logo preview"
                  />
                )}
              </div>
            ) : (
              <Upload
                showUploadList={false}
                beforeUpload={onFileChangedHandler}
              >
                <div
                  onDragLeave={() => setIsDragged(false)}
                  onDragOver={() => setIsDragged(true)}
                  className={`upload-drag-and-drop ${
                    isDragged ? 'active' : ''
                  }`}
                >
                  <i className="feather-upload" />
                  <p>Drag or click to upload</p>
                </div>
              </Upload>
            )}
          </Form.Item>
          <Form.Item
            label="Youtube URL"
            rules={[{ required: true, message: 'Youtube URL is required!' }]}
          >
            <Input
              placeholder="Youtube URL"
              onChange={(e) => handleChange('video_url', e.target.value)}
              value={media.video_url}
            />
          </Form.Item>
        </Form>
      </Modal>

      {mediaPreviewModal.isOpen && (
        <Modal
          title={null}
          visible={mediaPreviewModal.isOpen}
          footer={null}
          onCancel={hidePreviewModal}
          className="custom-modal"
        >
          {mediaPreviewModal.video_url ? (
            <video id="my-video" className="video-js" data-setup="{}" controls>
              <source src={mediaPreviewModal.video_url} />
            </video>
          ) : mediaPreviewModal.image_url ? (
            <img src={mediaPreviewModal.image_url} alt="post" />
          ) : null}

          <div className="custom-modal-content">
            <div className="custom-modal-content-title">
              <div className="custom-modal-title">Title</div>
              <div className="custom-modal-description">
                {mediaPreviewModal.title ? mediaPreviewModal.title : 'No Title'}
              </div>
            </div>
            <div className="custom-modal-content-title">
              <div className="custom-modal-title">Description</div>
              <div className="custom-modal-description">
                {mediaPreviewModal.description}
              </div>
            </div>
          </div>
        </Modal>
      )}
      {current === 'search' && (
        <div className="media__header">
          <div className="media__header-left">
            <div className="media__select-playlist">
              <Select value={searchType} onChange={(e) => setSearchType(e)}>
                {' '}
                <Option value="hashtag">Hashtag</Option>
                <Option value="username">Username</Option>
              </Select>
            </div>
            <div className="media__search-btn">
              <Button type="primary" className="btn-primary" onClick={onSearch}>
                Search
              </Button>
            </div>
            <div className="media__search-limit">
              <label className="media__search-limit-label">Total Data: </label>
              <InputNumber
                value={limit}
                onChange={handleLimit}
                className="media__search-limit-input"
              />
            </div>
            <div className="media__search-tags">
              <div className="media__search-tags-list">
                <TweenOneGroup
                  enter={{
                    scale: 0.8,
                    opacity: 0,
                    type: 'from',
                    duration: 100,
                  }}
                  onEnd={(e) => {
                    if (e.type === 'appear' || e.type === 'enter') {
                      e.target.style = 'display: inline-block';
                    }
                  }}
                  leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
                  appear={false}
                >
                  {tagChild}
                </TweenOneGroup>
              </div>
              <div className="media__search-tags-source">
                {inputVisible && (
                  <Input
                    ref={saveInputRef}
                    type="text"
                    size="small"
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleInputConfirm}
                    onPressEnter={handleInputConfirm}
                    className="media__search-tags-input"
                  />
                )}
                {!inputVisible && (
                  <Tag onClick={showInput} className="media__search-tags-tag">
                    <PlusOutlined />{' '}
                    {searchType === 'hashtag' ? 'New Tag' : 'New Username'}
                  </Tag>
                )}
              </div>
            </div>
          </div>

          {/* <div className="media__header-middle">
            <div className="media__select-add">
              <Button
                type="primary"
                className="btn-primary"
                onClick={() => setModalUploadURL(true)}
              >
                Add Youtube URL to Gallery Gig
              </Button>
            </div>
          </div> */}

          <div className="media__header-right">
            <div className="media__select-playlist">
              <Select
                defaultValue="Select Playlist"
                onChange={(e) => setPlaylist(playlistList[e])}
              >
                {' '}
                {playlistList &&
                  playlistList.map((playlist, index) => (
                    <Option key={playlist._id} value={index}>
                      {playlist.name}
                    </Option>
                  ))}
              </Select>
            </div>
            <div className="media__select-add">
              {list.length > 0 && (
                <div>
                  <Button
                    type="primary"
                    className="btn-primary btn-add-playlist"
                    onClick={onAddToPlaylist}
                  >
                    Add to playlist
                  </Button>
                </div>
              )}

              {/* {isYoutube && (
                <Button
                  type="primary"
                  className="btn-primary btn-add-youtube"
                  onClick={() => setModalUploadURL(true)}
                >
                  <svg
                    width="17"
                    height="12"
                    viewBox="0 0 17 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.6813 2.1235C15.5909 1.7831 15.4126 1.4725 15.1643 1.2226C14.9161 0.972803 14.6066 0.792503 14.2668 0.699903C13.0159 0.363503 8.0159 0.363503 8.0159 0.363503C8.0159 0.363503 3.0159 0.363503 1.76496 0.699903C1.42516 0.792503 1.11564 0.972803 0.867389 1.2226C0.619149 1.4725 0.44087 1.7831 0.35041 2.1235C0.0158695 3.3799 0.0158691 5.9999 0.0158691 5.9999C0.0158691 5.9999 0.0158695 8.6199 0.35041 9.8763C0.44087 10.2166 0.619149 10.5273 0.867389 10.7771C1.11564 11.027 1.42516 11.2073 1.76496 11.2999C3.0159 11.6363 8.0159 11.6363 8.0159 11.6363C8.0159 11.6363 13.0159 11.6363 14.2668 11.2999C14.6066 11.2073 14.9161 11.027 15.1643 10.7771C15.4126 10.5273 15.5909 10.2166 15.6813 9.8763C16.0159 8.6199 16.0159 5.9999 16.0159 5.9999C16.0159 5.9999 16.0159 3.3799 15.6813 2.1235Z"
                      fill="#FF0302"
                    />
                    <path
                      d="M6.37949 8.379V3.6208L10.5613 5.9999L6.37949 8.379Z"
                      fill="#FEFEFE"
                    />
                  </svg>
                  Add Youtube URL
                </Button>
              )} */}
              {/* <Button
                type="primary"
                className="btn-primary btn-add-playlist"
                onClick={onAddToPlaylist}
              >
                Add to playlist
              </Button> */}
            </div>
          </div>

          {error && <div className="search-error">{error}</div>}
        </div>
      )}
      <div className="media__content">
        {current === 'search' ? (
          <>
            {loading ? (
              <ClipLoader />
            ) : (
              <>
                <div className="media__list">
                  <>
                    {list.length > 0 &&
                      (list[0]['#error'] ? (
                        <div>There's no data retrieved</div>
                      ) : (
                        <>
                          {list.slice(minValue, maxValue).map((api, index) => {
                            return (
                              <div key={index} className="media__item">
                                <div className="media__item-actions">
                                  <Tooltip
                                    title="Media Preview"
                                    className="media__preview"
                                  >
                                    <button
                                      onClick={() => {
                                        onPreviewMediaClickHandler(api);
                                      }}
                                    >
                                      <i className="feather-maximize-2" />
                                    </button>
                                  </Tooltip>
                                  {/* <CheckableTag
                                  className="media__item-check"
                                  checked={selected.indexOf(api) !== -1}
                                  onChange={(checked) =>
                                    onAddImages(api, checked)
                                  }
                                >
                                  {selected.indexOf(api) !== -1 && (
                                    <svg
                                      viewBox="0 0 18 13"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="media__check-logo"
                                    >
                                      <path
                                        d="M17 1L6 12L1 7"
                                        stroke="white"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  )}
                                </CheckableTag> */}
                                </div>

                                <CheckableTag
                                  checked={selected.indexOf(api) !== -1}
                                  onChange={(checked) =>
                                    onAddImages(api, checked)
                                  }
                                >
                                  {selected.indexOf(api) !== -1 && (
                                    <div className="checked-overlay">
                                      <svg
                                        width="24"
                                        height="18"
                                        viewBox="0 0 24 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="icon-checked"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M23.6095 1.05719C24.1302 1.57789 24.1302 2.42211 23.6095 2.94281L8.94281 17.6095C8.42211 18.1302 7.57789 18.1302 7.05719 17.6095L0.390524 10.9428C-0.130175 10.4221 -0.130175 9.57789 0.390524 9.05719C0.911223 8.53649 1.75544 8.53649 2.27614 9.05719L8 14.7811L21.7239 1.05719C22.2446 0.536493 23.0888 0.536493 23.6095 1.05719Z"
                                          fill="white"
                                        />
                                      </svg>
                                    </div>
                                  )}

                                  <div className="img-overlay" />
                                  <div className="img-label-wrapper">
                                    <div className="img-label-2">
                                      {api.type}
                                    </div>
                                  </div>

                                  {api.image_url ||
                                  (api.url && api.ext !== 'video/mp4') ? (
                                    <img
                                      className="media__item-img"
                                      src={
                                        api.displayUrl
                                          ? api.displayUrl
                                          : api.url
                                      }
                                      alt=""
                                    />
                                  ) : (
                                    <video
                                      id="my-video"
                                      className="video-js"
                                      data-setup="{}"
                                      preload="metadata"
                                    >
                                      <source
                                        src={
                                          api.video_url
                                            ? api.video_url
                                            : api.url
                                        }
                                      />
                                    </video>
                                  )}
                                  <div className="media__item-description">
                                    <span>
                                      {api.type === 'youtube'
                                        ? api.name
                                        : api.ownerUsername}
                                    </span>
                                    <p>
                                      {api.type === 'youtube'
                                        ? trimString(api.description, 75)
                                        : api.caption
                                        ? trimString(api.caption, 75)
                                        : ''}
                                    </p>
                                  </div>
                                </CheckableTag>
                              </div>
                            );
                          })}
                          {/* <div>
                            <Button
                              type="primary"
                              className="btn-primary btn-add-playlist"
                              onClick={onAddToPlaylist}
                            >
                              Add to playlist
                            </Button>
                          </div> */}
                        </>
                      ))}
                  </>
                </div>
                {/* <Button
                  type="danger"
                  className="btn btn-danger"
                  onClick={onDeleteButtonHandler}
                >
                  Delete Media
                </Button> */}
              </>
            )}
          </>
        ) : (
          current === 'playlist' && (
            // <PlaylistPreview
            //   playlists={playlistList}
            //   apiList={artwork}
            //   updated={updated}
            //   access="activity_pillar"
            // ></PlaylistPreview>
            <PlaylistArrival
              playlists={playlistList}
              apiList={artwork}
              updated={updated}
              access="activity_pillar"
            />
          )
        )}
      </div>

      {current === 'search' && list.length > 0 && (
        <div className="media-pagination">
          <Pagination
            defaultCurrent={1}
            total={list.length}
            current={currentPage}
            pageSize={sizePage}
            onChange={handleChangePage}
            onShowSizeChange={onShowSizeChange}
            pageSizeOptions={[10, 20, 30, 50, 100]}
            showSizeChanger={list.length > 8}
          />
        </div>
      )}
    </div>
  );
}

export default SocialWall;
