import { createReducer } from 'redux-act';

import {
  ANALYTICS_FETCH_DATA_INIT,
  ANALYTICS_FETCH_DATA_SUCCESS,
  ANALYTICS_FETCH_PROFILE_SUCCESS,
  ANALYTICS_FETCH_DATA_FAIL,
  ANALYTICS_CALCULATE_DATA_INIT,
  ANALYTICS_CALCULATE_DATA_SUCCESS,
  ANALYTICS_EXPORT_ANALYTIC_FAIL,
  ANALYTICS_EXPORT_ANALYTIC_INIT,
  ANALYTICS_EXPORT_ANALYTIC_SUCCESS,
  ANALYTICS_FETCH_LEADERBOARD_INIT,
  ANALYTICS_FETCH_LEADERBOARD_SUCCESS,
  ANALYTICS_FETCH_LEADERBOARD_FAIL,
} from 'state/actions/analytic';

const initialState = {
  data: {},
  locations: [],
  rawData: [],
  leaderboard: [],
  loading: false,
  loadingCalculate: false,
  loadingExport: false,
  error: null,
  deleted: false,
  downloading: false,
};

export const analyticReducer = createReducer(
  {
    [ANALYTICS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
      data: {},
    }),
    [ANALYTICS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.data || {},
      rawData: payload.rawData || [],
      loading: false,
      error: null,
    }),
    [ANALYTICS_FETCH_PROFILE_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.data || {},
      locations: payload.location || [],
      loading: false,
      error: null,
    }),
    [ANALYTICS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [ANALYTICS_FETCH_LEADERBOARD_INIT]: () => ({
      ...initialState,
      loading: true,
      leaderboard: [],
    }),
    [ANALYTICS_FETCH_LEADERBOARD_SUCCESS]: (state, payload) => ({
      ...state,
      leaderboard: payload.data || [],
      loading: false,
      error: null,
    }),
    [ANALYTICS_FETCH_LEADERBOARD_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [ANALYTICS_EXPORT_ANALYTIC_INIT]: (state, payload) => ({
      ...state,
      loadingExport: true,
    }),
    [ANALYTICS_EXPORT_ANALYTIC_SUCCESS]: (state, payload) => ({
      ...state,
      loadingExport: false,
    }),
    [ANALYTICS_EXPORT_ANALYTIC_FAIL]: (state, payload) => ({
      ...state,
      loadingExport: false,
      error: payload.error,
    }),
    [ANALYTICS_CALCULATE_DATA_INIT]: () => ({
      ...initialState,
      loadingCalculate: true,
      data: {},
    }),
    [ANALYTICS_CALCULATE_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      rawData: payload.rawData || [],
      loadingCalculate: false,
      error: null,
    }),
  },
  initialState
);
