import { Breadcrumb, Modal, Table, Tooltip, Input } from 'antd';
import ModalOrganizationForm from 'components/ModalOrganizationForm';
import { useFormatMessage } from 'hooks';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import { log } from 'utils';
// import Table from 'components/Table';
import {
  createOrganization,
  deleteOrganization,
  fetchOrganizations,
  modifyOrganization,
} from 'state/actions/organizations';
import { regexInput } from 'utils/regex';
import './Organizations.scss';
import _ from 'underscore';

const Organizations = () => {
  const {
    loading,
    deleted,
    organizationsList,
    userData,
    updated,
    role,
  } = useSelector(
    (state) => ({
      loading: state.organizations.loading,
      deleted: state.organizations.deleted,
      organizationsList: state.organizations.list,
      userData: state.auth.userData,
      updated: state.organizations.updated,
      role: state.auth.userData.role,
    }),
    shallowEqual
  );

  const defaultOrganization = {
    name: '',
    description: '',
    createdAt: moment(Date.now()).format('DD-MM-YY hh:mm:ss'),
    updatedBy: userData.email ? userData.email : '',
  };

  const [visible, setVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [organization, setOrganization] = useState(defaultOrganization);
  const [confirmationTitle, setConfirmationTitle] = useState('');
  const [disableBtn, setDisableBtn] = useState(true);
  const [sort, setSort] = useState(null);

  const [deleteModal, setDeleteModal] = useState({
    organizationId: '',
    organizationName: '',
    userId: null,
    isOpen: false,
  });

  const dispatch = useDispatch();

  const [search, setSearch] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    if (role === 'superadmin') {
      dispatch(fetchOrganizations());
    }
  }, [dispatch, role]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        userId: null,
        isOpen: !prevState.isOpen,
      }));
      dispatch(fetchOrganizations());
    }
  }, [deleted, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (updated && !loading) {
      dispatch(fetchOrganizations());
      setVisible(false);
    }
  }, [updated, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  // const redirect = error && dispatch(logout()) && <Redirect to={paths.LOGIN} />;

  const onRemoveButtonClickHandler = (organizationId, organizationName) => {
    setDeleteModal((prevState) => ({
      organizationId,
      organizationName,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ organizationId: null, isOpen: false });
  };

  const onDeleteOrganizationHandler = () => {
    if (confirmationTitle === deleteModal.organizationName) {
      dispatch(deleteOrganization(deleteModal.organizationId));
    }
  };

  const handleSearch = (e) => {
    setError(null);
    if (!regexInput(e.target.value) && e.target.value) {
      setError('Search cannot using special character');
    }

    setSearch(e.target.value);
  };

  const columns = [
    {
      title: useFormatMessage('Organizations.name'),
      dataIndex: 'name',
      sorter: true,
    },
    {
      title: useFormatMessage('Organizations.description'),
      dataIndex: 'description',
      sorter: true,
    },
    {
      title: useFormatMessage('Organizations.modifiedBy'),
      dataIndex: 'modifiedBy',
      sorter: true,
    },
    {
      title: useFormatMessage('Organizations.modifiedDate'),
      dataIndex: 'modifiedDate',
      sorter: true,
      render: (text, record) => {
        if (record.modifiedAt) {
          return (
            <div>{`${moment(record.modifiedAt)
              .utcOffset('+08:00')
              .format('h:mm A, MMM Do YYYY')}`}</div>
          );
        }
        return <span>-</span>;
      },
    },
    {
      title: 'Options',
      dataIndex: 'option',

      render: (text, record) => {
        return (
          <div className="sites__actions">
            <Tooltip title="Edit Organization">
              <button
                onClick={() => {
                  setOrganization({ ...record, id: record._id });
                  setVisible(true);
                  setIsEditing(true);
                }}
                data-tooltip="Edit"
                className="btn btn--edit"
              >
                <i className="feather-edit"></i>
              </button>
            </Tooltip>

            <Tooltip title="Delete Organization">
              <button
                type="button"
                onClick={() =>
                  onRemoveButtonClickHandler(record._id, record.name)
                }
                data-tooltip="Delete"
                className="btn btn--delete"
              >
                <i className="feather-trash-2"></i>
              </button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const onCreate = (values) => {
    const action = isEditing ? modifyOrganization : createOrganization;
    dispatch(action(values));
  };

  const onNewOrganization = () => {
    log('handle new site');
    setOrganization(defaultOrganization);
    setIsEditing(false);
    setVisible(true);
  };

  const handleChangeTable = (pagination, filters, sorter, extra) => {
    setSort(sorter);
  };

  const data = search
    ? organizationsList.filter((el) => {
        const clonedElem = { ...el };
        delete clonedElem.id;
        return clonedElem.name.toLowerCase().includes(search.toLowerCase());
      })
    : organizationsList.filter((el) => {
        if (role === 'superadmin') {
          return el;
        } else {
          return el.name === userData.organizationName;
        }
      });

  const sortedData = _.sortBy(data, sort ? sort.field : 'name');
  const sortedDataDirection =
    sort && sort.order === 'descend' ? sortedData.reverse() : sortedData;
  // const deleteMessage = useFormatMessage('Organizations.delete');

  const confirmMessage = useFormatMessage('Organizations.confirm');

  const permDeleteMessage = useFormatMessage('Organizations.permDelete');

  // const cancelMessage = useFormatMessage('Organizations.cancel');

  return (
    <section className="organizations">
      <div className="header-content">
        <div className="header-left-content">
          <div className="breadcrumb-wrapper">
            <Breadcrumb>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item className="breadcrumb-active">
                Organizations
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <h1 className="page-title">Organizations</h1>
        </div>
        <div className="header-right-content">
          <div className="searchbar-wrapper">
            <div className="searchbar">
              <i className="feather-search"></i>
              <input
                type="text"
                placeholder="Search..."
                value={search}
                onChange={handleSearch}
              />
            </div>
            {error && <div className="search-error">{error}</div>}
          </div>
          <button className="button-primary" onClick={onNewOrganization}>
            <i className="feather-plus" />
            {useFormatMessage('Organization.newOrganization')}
          </button>
        </div>
      </div>
      {deleteModal.isOpen && (
        <Modal
          title={confirmMessage}
          visible={deleteModal.isOpen}
          onOk={onDeleteOrganizationHandler}
          onCancel={onCloseModalHandler}
          okButtonProps={{
            disabled: disableBtn,
          }}
        >
          <p>{permDeleteMessage}</p>
          <span>
            To delete <strong>{deleteModal.organizationName}</strong> type the
            organization name <strong>{deleteModal.organizationName}</strong>.
          </span>
          <Input
            onChange={(e) => {
              setConfirmationTitle(e.target.value);

              if (e.target.value === deleteModal.organizationName) {
                setDisableBtn(false);
              } else {
                setDisableBtn(true);
              }
            }}
          />
        </Modal>
      )}

      {visible && (
        <ModalOrganizationForm
          visible={true}
          onCancel={() => {
            setVisible(false);
          }}
          onCreate={onCreate}
          organization={organization}
          setOrganization={setOrganization}
          isEditing={isEditing}
        />
      )}

      <div className="organizations__table">
        {loading ? (
          <ClipLoader />
        ) : (
          <Table
            columns={columns}
            dataSource={sortedDataDirection}
            rowKey="_id"
            onChange={handleChangeTable}
          />
        )}
      </div>
    </section>
  );
};

export default Organizations;
