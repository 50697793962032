import { Breadcrumb, Input, Tooltip } from 'antd';
import ChangePasswordCard from 'components/ChangePassword';
import { useChangeHandler, useFormatMessage } from 'hooks';
import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { modifyUser } from 'state/actions/users';
import { regexInput } from 'utils/regex';
import './Profile.scss';

const Profile = () => {
  const { userData, loading } = useSelector(
    (state) => ({
      userData: state.auth.userData,
      loading: state.users.loading,
    }),
    shallowEqual
  );

  const [user, setUser] = useState(userData || {});
  const [errors, setErrors] = useState({
    name: '',
  });

  const dispatch = useDispatch();
  const onChangeHandler = useChangeHandler(setUser);

  const handleSubmit = () => {
    if (!regexInput(user.name) && user.name) {
      setErrors({
        name: 'This field cannot contain special character',
      });
    } else {
      setErrors({
        name: '',
      });
      // console.log(user);
      dispatch(modifyUser({ ...user, isProfile: true }));
    }
  };

  return (
    <section className="profile">
      <div className="breadcrumb-wrapper">
        <Breadcrumb>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumb-active">
            Profile
          </Breadcrumb.Item>
        </Breadcrumb>
        <h1 className="page-title">Profile</h1>
      </div>

      <div className="profile__content">
        <div className="profile__left">
          <h1 className="change-password__title">Change Profile</h1>
          <div className="profile__form">
            <div className="profile__form-control">
              <label className="profile__label">
                {useFormatMessage('UserForm.email')}
                <Tooltip title="You can't change your email">
                  <i className="feather-info info-message" />
                </Tooltip>
              </label>
              <Input
                name="email"
                value={user.email}
                placeholder="Email"
                disabled
              />
              {errors && errors.name && (
                <div className="error-message">{errors.name}</div>
              )}
            </div>
            <div className="profile__form-control">
              <label className="profile__label">
                {useFormatMessage('UserForm.name')}
              </label>
              <Input
                name="name"
                value={user.name}
                placeholder="Name"
                onChange={onChangeHandler}
              />
              {errors && errors.name && (
                <div className="error-message">{errors.name}</div>
              )}
            </div>
            <div className="profile__info">
              {loading ? (
                <button className="button-primary button-loading">
                  Loading...
                </button>
              ) : (
                <button onClick={handleSubmit} className="button-primary">
                  Save Changes
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="profile__right">
          <ChangePasswordCard />
        </div>
      </div>
    </section>
  );
};

export default Profile;
