import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

import Login from 'pages/Login';
import Home from 'pages/Home';
import SiteInfo from 'pages/SiteInfo';
import Users from 'pages/Users';
import User from 'pages/User';
import UserManagementSys from 'pages/UserManagementSys';
import Organizations from 'pages/Organizations';
import Organization from 'pages/Organization';
import Sites from 'pages/Sites';
import Site from 'pages/Site';
import Profile from 'pages/Profile';
import NotFound from 'pages/NotFound';
import Section from 'pages/Section';
import Submenu from 'pages/Submenu';
import paths from './paths';
import PrivateRoute from './PrivateRoute';
import Paths from 'pages/Paths';
import FullLayout from 'components/Layout/FullLayout';
import ResetPassword from 'pages/ResetPassword';
import RecoverPassword from 'pages/RecoverPassword';
import Logs from 'pages/Logs';
import AuthLogs from 'pages/AuthLogs';
import Roles from 'pages/Roles';
import Role from 'pages/Role';
import Panel from 'pages/Panel';
import PanelRole from 'pages/PanelRole';
import PanelUser from 'pages/PanelUser';

const RouterComponent = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path={paths.LOGIN}
          component={(props) => {
            return (
              <FullLayout {...props}>
                <Login {...props} />
              </FullLayout>
            );
          }}
        />
        <Route
          exact
          path={paths.RECOVER_PASSWORD}
          component={(props) => {
            return (
              <FullLayout {...props}>
                <RecoverPassword {...props} />
              </FullLayout>
            );
          }}
        />
        <Route
          exact
          path={paths.RESET_PASSWORD}
          component={(props) => {
            return (
              <FullLayout {...props}>
                <ResetPassword />
              </FullLayout>
            );
          }}
        ></Route>
        <PrivateRoute path={paths.ADD_USER} component={User} />
        <PrivateRoute path={paths.MANAGE_USER} component={UserManagementSys} />
        <PrivateRoute path={paths.MODIFY_USER} component={User} />

        <PrivateRoute path={paths.USERS} component={Users} />
        <PrivateRoute
          path={paths.USER_MANAGEMENT_SYS}
          component={UserManagementSys}
        />

        <PrivateRoute path={paths.ADD_SITE} component={Site} />
        <PrivateRoute path={paths.MODIFY_SITE} component={Site} />
        <PrivateRoute path={paths.SITES} component={Sites} />
        <PrivateRoute path={paths.ADD_ORGANIZATION} component={Organization} />
        <PrivateRoute
          path={paths.MODIFY_ORGANIZATION}
          component={Organization}
        />
        <PrivateRoute path={paths.ORGANIZATIONS} component={Organizations} />
        {/* <PrivateRoute path={paths.CALENDAR} component={AllCalendar} />
        <PrivateRoute path={paths.ANALYTICS} component={Analytics} /> */}
        <PrivateRoute path={paths.PATHS} component={Paths} />
        <PrivateRoute path={paths.PROFILE} component={Profile} />
        <PrivateRoute path={paths.SECTION} component={Section} />
        <PrivateRoute path={paths.SUBMENU_1} component={Submenu} />
        <PrivateRoute path={paths.SUBMENU_3} component={Submenu} />
        <PrivateRoute path={paths.SITEINFO} component={SiteInfo} />
        <PrivateRoute path={paths.LOGS} component={Logs} />
        <PrivateRoute path={paths.AUTH_LOGS} component={AuthLogs} />
        <PrivateRoute path={paths.ADD_ROLES} component={Role} />
        <PrivateRoute path={paths.MODIFY_ROLES} component={Role} />
        <PrivateRoute path={paths.ROLES} component={Roles} />
        <PrivateRoute path={paths.PANEL_ROLE} component={PanelRole} />
        <PrivateRoute path={paths.PANEL} component={Panel} />
        <PrivateRoute path={paths.PANEL_USER} component={PanelUser} />
        <PrivateRoute path={paths.ROOT} component={Home} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default RouterComponent;
