import { arrayMoveImmutable } from 'array-move';
import {
  Modal,
  Collapse,
  DatePicker,
  Tooltip,
  Checkbox,
  Tag,
  Pagination,
  Form,
  Input,
  Upload,
  message,
  Button,
  InputNumber,
} from 'antd';
import uuid from 'uuid';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  addPostPlaylist,
  refreshPostPlaylist,
  removePostPlaylist,
} from 'state/actions/playlist';
// import {url} from 'utils/url';

// import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
// import { SortableContainer } from 'react-sortable-hoc';
// import PlayListGrid from './PlayListGrid';
import './PlaylistArrival.css';
import {
  // fetchArrival,
  submitAddImage,
  deletePlaylist,
  editImage,
  fetchArrivalNew,
  // fetchActivity,
} from 'state/actions/api';
// import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import _ from 'underscore';
// import {
//   deletePlaylist,
//   // fetchArrival,
//   // fetchArrivalInit,
// } from 'state/actions/api';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { log } from 'utils';
import PlayListGrid from './PlayListGrid';

const { Panel } = Collapse;
const { CheckableTag } = Tag;

function PlaylistArrival({
  playlists,
  // , apiList
  updated,
  access,
}) {
  // const startRef = useRef();
  // const endRef = useRef();
  const dispatch = useDispatch();

  const {
    loading,
    uploading,
    deleted,
    playlistList,
    updatedPlaylist,
    apiList,
  } = useSelector(
    (state) => ({
      apiList: state.api.artwork,
      playlistList: state.playlist.list,
      loading: state.playlist.loading,
      errorPlaylist: state.playlist.error,
      updatedPlaylist: state.playlist.updated,
      userData: state.auth.userData,
      uploading: state.api.uploading,
      deleted: state.api.deleted,
    }),
    shallowEqual
  );

  const { siteId } = useParams();
  // eslint-disable-next-line
  const [error, setError] = useState({
    name: false,
    upload: false,
  });
  const [playlist, setPlaylist] = useState();
  // eslint-disable-next-line
  const [orientation, setOrientation] = useState();
  // const [selected, setSelected] = useState([]);
  // const [selectedMedia, setSelectedMedia] = useState({});
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  // const [dataSort, setDataSort] = useState(playlists);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedMediaTags, setSelectedMediaTags] = useState([]);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(12);
  const [sizePage, setSizePage] = useState(12);
  // eslint-disable-next-line
  const [modalUpload, setModalUpload] = useState(false);
  const [isDragged, setIsDragged] = useState(false);
  const [isSelectAllChecked, setIsSelectAllSelected] = useState({
    id: '',
    selected: false,
  });
  const [media, setMedia] = useState({
    id: null,
    file: null,
    logo: null,
    ext: '',
    name: '',
    description: '',
    isEdit: false,
    url: null,
  });

  const [deleteModal, setDeleteModal] = useState({
    playlist: null,
    id: null,
    isOpen: false,
  });

  const [deleteItemModal, setDeleteItemModal] = useState({
    list: [],
    isOpen: false,
  });

  const [mediaPreviewModal, setMediaPreviewModal] = useState({
    title: '',
    isOpen: false,
    source: '',
  });

  const [dateModal, setDateModal] = useState({
    title: '',
    isOpen: false,
    start: null,
    end: null,
    duration: 0,
    isValid: false,
    playlist: null,
    posts: [],
  });
  const [locationModal, setLocationModal] = useState({
    title: '',
    isOpen: false,
   location:'',
    isValid: false,
    playlist: null,
    posts: [],
  });

  const [editModal, setEditModal] = useState({
    title: '',
    isOpen: false,
    playlist: null,
    post: null,
    coi_name: '',
  });

  const [addNewFileModal, setAddNewFileModal] = useState({
    isOpen: false,
    modalName: '',
  });

  const [editHashtagModal, setEditHashtagModal] = useState({
    id: null,
    title: '',
    isOpen: false,
    playlist: null,
  });

  const [content, setContent] = useState([]);

  const handleReset = () => {
    setModalUpload(false);
    setMedia({
      id: null,
      file: null,
      logo: null,
      name: '',
      description: '',
      isEdit: false,
      url: null,
    });
    setError({
      name: '',
      upload: '',
    });
  };

  useEffect(() => {
    // dispatch(fetchActivity({ siteId }));
    dispatch(fetchArrivalNew({ siteId, type: 'artwork' }));
  }, []); // eslint-disable-line

  useEffect(() => {
    // setSearch();
    setCurrentPage(1);
    setMinValue(0);
    setMaxValue(sizePage);
    // setMedias(mediaList);
  }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setMaxValue(minValue + sizePage);
  }, [sizePage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // setSearch();
    setError({});
    // setMedias(mediaList);
  }, [playlist]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSelectedMediaTags([]);
  }, [updated]);

  useEffect(() => {
    if (
      (deleted && !loading) ||
      (updated && !loading) ||
      (updatedPlaylist && !loading)
    ) {
      setDeleteModal((prevState) => ({
        ...prevState,
        playlist: null,
        id: null,
        isOpen: false,
      }));

      setDeleteItemModal((prevState) => ({
        ...prevState,
        list: [],
        isOpen: false,
      }));

      setDateModal((prevState) => ({
        ...prevState,
        isOpen: false,
        playlist: null,
        isValid: false,
        posts: [],
      }));
      setLocationModal((prevState) => ({
        ...prevState,
        isOpen: false,
        playlist: null,
        isValid: false,
        posts: [],
      }));
      setEditModal((prevState) => ({
        ...prevState,
        isOpen: false,
        playlist: null,
        post: null,
      }));

      setIsSelectAllSelected({
        id: '',
        selected: false,
      });
      setSelectedTags([]);
      setSelectedMediaTags([]);
    }
  }, [deleted, loading, updated, updatedPlaylist]);

  useEffect(() => {
    if ((uploading && !loading) || (deleted && !loading)) {
      handleReset();
      // dispatch(fetchArrival({ siteId }));
      dispatch(fetchArrivalNew({ siteId, type: 'artwork' }));
    }
    // setMedias(mediaList);
  }, [uploading, loading, deleted]); // eslint-disable-line

  useEffect(() => {
    if (apiList.length <= sizePage) {
      handleChangePage(1);
    }
  }, [apiList.length]); // eslint-disable-line

  const handleChangePage = (value) => {
    setCurrentPage(value);
    if (value <= 1) {
      setMinValue(0);
      setMaxValue(sizePage);
    } else {
      setMinValue(value * sizePage - sizePage);
      setMaxValue(value * sizePage);
    }
  };

  const handleSubmitDescription = () => {
    dispatch(
      addPostPlaylist({
        playlist,
        access,
        action: 'update',
        message: 'edit_desc',
      })
    );

    setEditHashtagModal((prevState) => ({
      ...prevState,
      isOpen: false,
    }));
  };

  const hideEditHashtagModal = () => {
    setEditHashtagModal((prevState) => ({
      ...prevState,
      isOpen: false,
    }));
  };

  const handleChangeForm = (name, value) => {
    setMedia({
      ...media,
      [name]: value,
    });
  };

  const handleRefreshData = (playlist) => {
    dispatch(refreshPostPlaylist({ playlist }));
  };

  const onRemoveButtonClickHandler = (selectedPlaylist) => {
    // const filteredPlaylist = playlists.filter(
    //   (item) => item._id === selectedPlaylist._id
    // )[0];

    // const posts = filteredPlaylist.posts.filter(
    //   (item) => !selectedTags.includes(item)
    // );

    // filteredPlaylist.posts = posts;
    if (selectedTags.length === 0) {
      toastr.error('', 'Choose item to delete');
    } else {
      setDeleteModal((prevState) => ({
        ...prevState,
        playlist: selectedPlaylist,
        id: selectedPlaylist._id,
        isOpen: true,
      }));
    }
  };

  // const handleOnDragEnd = (result) => {
  //   if (!result.destination) return;

  //   const groupedPlaylist = _.groupBy(playlists, (item) => item._id);
  //   const [items] = groupedPlaylist[result.source.droppableId];

  //   const [reorderedItem] = items.posts.splice(result.source.index, 1);
  //   items.posts.splice(result.destination.index, 0, reorderedItem);

  //   dispatch(addPostPlaylist({ playlist: items }));
  // };

  // const onEditCalendarClickHandler = (playlist, post) => {
  //   setDateModal((prevState) => ({
  //     ...prevState,
  //     playlist,
  //     id: post.id,
  //     title: post.name,
  //     start: post.start ?? undefined,
  //     end: post.end ?? undefined,
  //     isOpen: !prevState.isOpen,
  //   }));
  // };

  const onShowSizeChange = (current, pageSize) => {
    setCurrentPage(current);
    setSizePage(pageSize);
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ playlist: null, id: null, isOpen: false });
  };

  const handleSubmitForm = () => {
    if (media.isEdit && !media.url) {
      toastr.error('', 'Please upload Media');
      setError((prevState) => ({
        ...prevState,
        upload: true,
      }));
    }

    if (!media.isEdit && !media.file) {
      toastr.error('', 'Please upload Media');
      setError((prevState) => ({
        ...prevState,
        upload: true,
      }));
    }

    if (!media.name) {
      toastr.error('', 'Please input Name');
      setError((prevState) => ({
        ...prevState,
        name: true,
      }));
    }

    if (media.isEdit) {
      if (media.name && media.url) {
        console.log(media, 'media');
        dispatch(editImage({ media, siteId }));
      }
    } else if (media.name && media.file) {
      console.log(media, 'media');
        dispatch(submitAddImage({ media, siteId, type: 'artwork' }));
      }
  };

  const onDeletePlaylistItemHandler = () => {
    // const posts = deleteModal.playlist.posts.filter(
    //   (item) => item.id !== deleteModal.id
    // );
    // deleteModal.playlist.posts = posts;
    // deleteModal.playlist.id =
    //   deleteModal.playlist._id ?? deleteModal.playlist.id;
    // // console.log(deleteModal.playlist);

    const posts = deleteModal.playlist.posts.filter(
      (item) => !selectedTags.includes(item)
    );

    const newPlaylist = deleteModal.playlist;
    newPlaylist.posts = posts;

    // filteredPlaylist.posts = posts;

    dispatch(
      removePostPlaylist({
        playlist: newPlaylist,
        access,
      })
    );
  };

  // const handleOnDragEnd = (result) => {
  //   if (!result.destination) {
  //     return;
  //   }

  //   const items = Array.from(
  //     playlists.filter(
  //       (playlist) => playlist._id === result.destination.droppableId
  //     )
  //   );

  //   if (items.length) {
  //     const [reorderedItem] = items[0].posts.splice(result.source.index, 1);
  //     items[0].posts.splice(result.destination.index, 0, reorderedItem);

  //     dispatch(addPostPlaylist({ playlist: items[0] }));
  //   }
  // };

  const hidePreviewModal = () => {
    setMediaPreviewModal((prevState) => ({
      ...prevState,
      isOpen: !prevState.isOpen,
    }));
  };

  const onPreviewModalClickHandler = (post, playlist) => {
    log(post, 'post');
    log(playlist._id, 'playlistId');

    const isVideo =
      post.image_url &&
      post.image_url.split('.')[post.image_url.split('.').length - 1];
    log(access, 'access');

    setMediaPreviewModal((prevState) => ({
      ...prevState,
      id: post.id,
      playlistId: playlist._id,
      isOpen: true,
      type: playlist.type,
      image_url: post.image_url ?? post.cover_photo_url ?? '',
      video_url: isVideo === 'mp4' ? post.image_url : '',
      title: post.name ?? post.coi_name,
      relatedPlaylist: post.relatedPlaylist ?? [],
      access,
      description: post.floor_number
        ? `Level ${post.floor_number}`
        : post.description,
      qr_code: post.qr_code,
      qr_code_position: post.qr_code_position,
    }));

    if (post.recommendPlaylist && post.recommendPlaylist.length > 0) {
      setContent(post.recommendPlaylist);
    } else if (post.relatedPlaylist) {
        setContent(post.relatedPlaylist.slice(0, 2));
      }
  };

  const handleChange = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    // console.log(nextSelectedTags, '<<< next selected tags');
    setSelectedTags(nextSelectedTags);
  };



  const handleSelectedMedia = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...selectedMediaTags, tag]
      : selectedMediaTags.filter((t) => t !== tag);
    setSelectedMediaTags(nextSelectedTags);
  };

  const onFileChangedHandler = (file) => {
    if (
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/jpg' ||
      file.type === 'image/gif' ||
      file.type === 'video/x-msvideo' ||
      file.type === 'video/quicktime' ||
      file.type === 'video/mp4'
    ) {
      if (file.size / 1000000 <= 50) {
        setMedia((prevState) => ({ ...prevState, file, logo: null }));
      } else {
        message.error('Maximum file size is 50MB');
      }
    } else {
      message.error('Unsupported filetype!');
    }
    setIsDragged(false);
  };

  const handleDeletePreviewImage = () => {
    setMedia((prevState) => ({
      ...prevState,
      url: null,
      file: null,
      logo: null,
    }));
  };

  const handleAddNewFileModal = (playlistObj) => {
    setAddNewFileModal((prevState) => ({
      ...prevState,
      isOpen: true,
      modalName: playlistObj.name,
    }));
    setPlaylist(playlistObj);
  };

  const onAddNewFileHandler = () => {};

  const onCloseAddNewFileModal = () => {
    setAddNewFileModal((prevState) => ({
      ...prevState,
      isOpen: false,
      modalName: '',
    }));

    setSelectedMediaTags([]);
    handleReset();
  };

  const onDeleteItemHandler = () => {
    dispatch(deletePlaylist({ list: deleteItemModal.list }));
  };

  const onDeleteButtonHandler = () => {
    if (selectedMediaTags.length < 1) {
      toastr.error('', 'Choose the item');
    } else {
      setDeleteItemModal((prevState) => ({
        ...prevState,
        list: selectedMediaTags.map((item) => item._id),
        isOpen: true,
      }));
    }
  };

  let data =
    orientation && orientation !== 'all'
      ? apiList.filter((el) => {
          const clonedElem = { ...el };
          if (clonedElem.orientation)
            return clonedElem.orientation
              .toLowerCase()
              .includes(orientation.toLowerCase());
          return false;
        })
      : apiList;

  data = search
    ? data.filter((el) => {
        const clonedElem = { ...el };
        const name = clonedElem.name
          .toLowerCase()
          .includes(search.toLowerCase());
        const desc = clonedElem.description
          .toLowerCase()
          .includes(search.toLowerCase());
        // const credit = clonedElem.description_credit??clonedElem.description
        //   .toLowerCase()
        //   .includes(search.toLowerCase());
        if (name || desc) {
          return clonedElem;
        }
        return false;
      })
    : data;

  const handleSubmitDate = () => {
    const modifiedPlaylist = dateModal.playlist;
    const newDate = new Date();

    modifiedPlaylist.posts.forEach((item) => {
      if (dateModal.posts.includes(item)) {
        item.start = dateModal.start
          ? dateModal.start
          : dateModal.start === null
          ? moment(newDate).format('YYYY-MM-DD HH:mm')
          : undefined; // eslint-disable-line
        item.end = dateModal.end
          ? dateModal.end
          : dateModal.end === null
          ? moment(newDate).format('YYYY-MM-DD HH:mm')
          : undefined; // eslint-disable-line
        item.qrcode_url = dateModal.qrcode_url; // eslint-disable-line
        item.duration = dateModal.duration; // eslint-disable-line
      } else {
        item; // eslint-disable-line
      }
    });

    if (!dateModal.isValid) {
      toastr.error('', 'Invalid input date');
    } else {
      dispatch(
        addPostPlaylist({
          playlist: modifiedPlaylist,
          access,
          action: 'update',
          message: 'date',
        })
      );
    }
  };
  const handleSubmitLocation = () => {
    const modifiedPlaylist = locationModal.playlist;
 

    modifiedPlaylist.posts.forEach((item) => {
      if (locationModal.posts.includes(item)) {
        item.location = locationModal.location
          ? locationModal.location
          :  undefined; // eslint-disable-line
        
      } else {
        item; // eslint-disable-line
      }
    });
    console.log(modifiedPlaylist, 'modified playlist');


      dispatch(
        addPostPlaylist({
          playlist: modifiedPlaylist,
          access,
          action: 'update',
          message: 'date',
        })
      );
    
  };

  const handleEditPost = () => {
    const modifiedPlaylist = editModal.playlist;

    modifiedPlaylist.posts.forEach((item) => {
      if (editModal.post.id === item.id) {
        item.coi_name = editModal.coi_name;
      } else {
        item; // eslint-disable-line
      }
    });

    dispatch(
      addPostPlaylist({
        playlist: modifiedPlaylist,
        access,
        action: 'update',
        message: 'edit_post',
      })
    );
  };

  const onChangeEditModal = (e) => {
    const name = e.target.value;
    setEditModal((prevState) => ({
      ...prevState,
      coi_name: name,
    }));
  };
  const onChangeLocationModal = (e) => {
    const name = e.target.value;
    setLocationModal((prevState) => ({
      ...prevState,
      location: name,
    }));
  };
  const onChangeDateStart = (e) => {
    const start = moment(e).format('YYYY-MM-DD HH:mm');

    setDateModal((prevState) => ({
      ...prevState,
      start: e === null ? undefined : start,
    }));
  };

  const onChangeDateEnd = (e) => {
    const end = moment(e).format('YYYY-MM-DD HH:mm');

    if (moment(end).isSameOrAfter(dateModal.start)) {
      setDateModal((prevState) => ({
        ...prevState,
        isValid: true,
      }));
    } else if (dateModal.start === undefined || end === null) {
      setDateModal((prevState) => ({
        ...prevState,
        isValid: true,
      }));
    } else {
      setDateModal((prevState) => ({
        ...prevState,
        isValid: false,
      }));
    }

    setDateModal((prevState) => ({
      ...prevState,
      end: e === null ? undefined : end,
    }));
  };

  const onDurationChange = (e) => {
    setDateModal((prevState) => ({
      ...prevState,
      duration: e * 1000,
    }));
  };

  const hideDateModal = () => {
    setDateModal((prevState) => ({
      ...prevState,
      isOpen: false,
    }));
  };
  const hideLocationModal = () => {
    setLocationModal((prevState) => ({
      ...prevState,
      isOpen: false,
    }));
  };
  const hideEditModal = () => {
    setEditModal((prevState) => ({
      ...prevState,
      isOpen: false,
    }));
  };

  const onCloseDeleteItemModal = () => {
    setDeleteItemModal((prevState) => ({
      ...prevState,
      list: [],
      isOpen: false,
    }));
  };

  const trimString = (string, length) => {
    return string.length > length
      ? `${string.substring(0, length)}...`
      : string;
  };

  const imagePreviewUrl = media.file
    ? media.file && URL.createObjectURL(media.file)
    : media.url && media.url;

  const onAddToPlaylist = () => {
    if (!playlist) {
      toastr.error('', 'Choose the playlist');
    }
    if (selectedMediaTags.length < 1) {
      toastr.error('', 'Choose images');
    }
    if (playlist && selectedMediaTags.length > 0) {
      const post = [...playlist.posts];
      selectedMediaTags.map((art) => {
        log(art, 'art');
        return post.push({
          id: uuid(),
          name: art.coi_name ?? art.name,
          image_url: art.image_url ?? art.url,
          duration: 8,
          ...art,
        });
      });

      playlist.posts = post; // eslint-disable-line
      dispatch(
        addPostPlaylist({
          playlist,
          access,
          action: 'update',
          message: 'add_post',
        })
      );
    }
  };

  const onSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleSelectAll = (posts, event, id) => {
    if (event.target.checked) {
      setIsSelectAllSelected({
        id,
        selected: true,
      });
      setSelectedTags(posts);
    } else {
      setIsSelectAllSelected({
        id,
        selected: false,
      });
      setSelectedTags([]);
    }
  };

  const onClickSetDateButton = (selectedPlaylist) => {
    if (selectedTags.length === 0) {
      toastr.error('Media is not selected');
    } else {
      const date = new Date();
      // console.log(date, 'date before added 1');
      date.setDate(date.getDate() + 1);
      // console.log(date, 'date after added 1');
      setDateModal((prevState) => ({
        ...prevState,
        isOpen: true,
        playlist: selectedPlaylist,
        posts: selectedTags,
        start: selectedTags[0].start
          ? selectedTags[0].start
          : moment(new Date()).format('YYYY-MM-DD HH:mm'),
        end: selectedTags[0].end
          ? selectedTags[0].end
          : moment(date).format('YYYY-MM-DD HH:mm'),
        duration: selectedTags[0].duration ? selectedTags[0].duration : 8000,
        isValid: true,
      }));
    }
  };
  const onClickAddLocation = (selectedPlaylist) => {
    if (selectedTags.length === 0) {
      toastr.error('Media is not selected');
    } else {
      const date = new Date();
      // console.log(date, 'date before added 1');
      date.setDate(date.getDate() + 1);
      // console.log(date, 'date after added 1');
      setLocationModal((prevState) => ({
        ...prevState,
        isOpen: true,
        playlist: selectedPlaylist,
        posts: selectedTags,
       location:selectedTags.find(tag => tag.location) ?selectedTags.find(tag => tag.location).location:  '',
        isValid: true,
      }));
    }
  };

  const onClickEditButton = (selectedPlaylist) => {
    if (selectedTags.length === 0) {
      toastr.error('Media is not selected');
    } else if (selectedTags.length > 1) {
      toastr.error('You only can edit one media');
    } else {
      setEditModal((prevState) => ({
        ...prevState,
        isOpen: true,
        playlist: selectedPlaylist,
        post: selectedTags[0],
        coi_name: selectedTags[0].coi_name,
      }));
    }
  };

  const handleEditMedia = (mediaData) => {
    setMedia((prevState) => ({
      ...prevState,
      id: mediaData._id,
      name: mediaData.name,
      description: mediaData.description,
      isEdit: true,
      url: mediaData.url,
      youtube_url: mediaData.youtube_url,
      ext: mediaData.ext,
      // logo: mediaData.image_url
    }));
  };

  const handleCancelEdit = () => {
    handleReset();
  };

  const onChangeDescription = (e) => {
    setPlaylist({
      ...playlist,
      description: e.target.value,
    });
  };

  const onClickEditHashtag = (playlistObj) => {
    setEditHashtagModal((prevState) => ({
      ...prevState,
      isOpen: true,
      modalName: playlistObj.name,
    }));
    setPlaylist(playlistObj);
  };

  const regexDescription = (value) => {
    const regex = /(<([^>]+)>)/gi;
    return value.replace(regex, '');
  };

  const onDragEnd = (result) => {
    if (
      result.destination &&
      result.destination.droppableId === 'droppable-destination'
    ) {
      const findRelated =
        mediaPreviewModal.relatedPlaylist[result.source.index];
      let recommendPlaylist;

      const findPlaylist = playlistList.find(
        (x) => x._id === mediaPreviewModal.playlistId
      );

      const findPost = findPlaylist.posts.find(
        (x) => x.id === mediaPreviewModal.id
      );

      if (content.length > 1) {
        const newContent = [...content];
        newContent[result.destination.index] = findRelated;
        recommendPlaylist = newContent;
        setContent(newContent);
      } else {
        recommendPlaylist = [...content, findRelated];
        setContent([...content, findRelated]);
      }

      findPost.recommendPlaylist = recommendPlaylist;

      dispatch(
        addPostPlaylist({
          playlist: findPlaylist,
          access,
          action: 'update',
          message: 'edit_post',
        })
      );
    }

    // console.log(findPlaylist, 'findPlaylist');
  };



  const handleRemoveContent = (index) => {
    const findPlaylist = playlistList.find(
      (x) => x._id === mediaPreviewModal.playlistId
    );

    const findPost = findPlaylist.posts.find(
      (x) => x.id === mediaPreviewModal.id
    );

    const newContent = [...content];
    newContent.splice(index, 1);
    setContent(newContent);

    findPost.recommendPlaylist = newContent;

    dispatch(
      addPostPlaylist({
        playlist: findPlaylist,
        access,
        action: 'update',
        message: 'edit_post',
      })
    );
  };

  return (
    <div className="playlist">
      {addNewFileModal.isOpen && (
        <Modal
          title="Media Database"
          visible={addNewFileModal.isOpen}
          onOk={onAddNewFileHandler}
          onCancel={onCloseAddNewFileModal}
          footer={null}
          className="media__database-modal"
        >
          <div className="media__database-arrival">
            <div className="media__database-arrival-left">
              <h2 className="media__database-arrival-playlist-name">
                {addNewFileModal.modalName}
              </h2>
              <div className="media__database-arrival-header">
                <div className="media__database-search">
                  <div className="searchbar">
                    <i className="feather-search" />
                    <input
                      placeholder="Search..."
                      className="search-input"
                      value={search}
                      onChange={onSearch}
                    />
                  </div>
                </div>

                <div className="media__database-actions">
                  <Button
                    type="danger"
                    className="btn btn-danger media__database-delete"
                    onClick={onDeleteButtonHandler}
                  >
                    Delete Media
                  </Button>
                  <Button
                    className="media__database-add"
                    onClick={onAddToPlaylist}
                  >
                    Add to Playlist
                  </Button>
                </div>
              </div>
              <div className="media__wrapper">
                {apiList.length > 0 && (
                  <>
                    {data.length > 0 && (
                      <>
                        {data.slice(minValue, maxValue).map((api, index) => {
                          return (
                            <div key={api.id} className="media__item">
                              <div className="media__item-description">
                                <span>{api.name}</span>
                                <p>
                                  {api.floor_number
                                    ? api.floor_number
                                    : trimString(api.description, 75)}
                                </p>
                              </div>
                              <div
                                className="media__item-edit"
                                onClick={() => handleEditMedia(api)}
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M1.25376 2.58579C1.62883 2.21071 2.13754 2 2.66797 2H7.33463C7.70283 2 8.0013 2.29848 8.0013 2.66667C8.0013 3.03486 7.70283 3.33333 7.33463 3.33333H2.66797C2.49116 3.33333 2.32159 3.40357 2.19656 3.5286C2.07154 3.65362 2.0013 3.82319 2.0013 4V13.3333C2.0013 13.5101 2.07154 13.6797 2.19656 13.8047C2.32159 13.9298 2.49116 14 2.66797 14H12.0013C12.1781 14 12.3477 13.9298 12.4727 13.8047C12.5977 13.6797 12.668 13.5101 12.668 13.3333V8.66667C12.668 8.29848 12.9664 8 13.3346 8C13.7028 8 14.0013 8.29848 14.0013 8.66667V13.3333C14.0013 13.8638 13.7906 14.3725 13.4155 14.7475C13.0404 15.1226 12.5317 15.3333 12.0013 15.3333H2.66797C2.13754 15.3333 1.62883 15.1226 1.25376 14.7475C0.878682 14.3725 0.667969 13.8638 0.667969 13.3333V4C0.667969 3.46957 0.878682 2.96086 1.25376 2.58579Z"
                                    fill="white"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M13.3346 1.91912C13.1364 1.91912 12.9462 1.99788 12.8061 2.13807L6.60329 8.34083L6.2509 9.75042L7.66048 9.39802L13.8632 3.19526C14.0034 3.05507 14.0822 2.86493 14.0822 2.66666C14.0822 2.4684 14.0034 2.27826 13.8632 2.13807C13.7231 1.99788 13.5329 1.91912 13.3346 1.91912ZM11.8632 1.19526C12.2535 0.80502 12.7828 0.585785 13.3346 0.585785C13.8865 0.585785 14.4158 0.80502 14.8061 1.19526C15.1963 1.5855 15.4155 2.11478 15.4155 2.66666C15.4155 3.21855 15.1963 3.74783 14.8061 4.13807L8.47272 10.4714C8.38728 10.5568 8.28023 10.6175 8.16301 10.6468L5.49634 11.3134C5.26916 11.3702 5.02883 11.3037 4.86325 11.1381C4.69766 10.9725 4.63109 10.7322 4.68789 10.505L5.35455 7.83831C5.38386 7.72109 5.44447 7.61403 5.52991 7.52859L11.8632 1.19526Z"
                                    fill="white"
                                  />
                                </svg>
                              </div>
                              {/* <div className="img-overlay"></div> */}

                              <CheckableTag
                                checked={selectedMediaTags.indexOf(api) > -1}
                                onChange={(checked) =>
                                  handleSelectedMedia(api, checked)
                                }
                              >
                                {selectedMediaTags.indexOf(api) > -1 && (
                                  <div className="checked-overlay">
                                    <svg
                                      width="24"
                                      height="18"
                                      viewBox="0 0 24 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="icon-checked"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M23.6095 1.05719C24.1302 1.57789 24.1302 2.42211 23.6095 2.94281L8.94281 17.6095C8.42211 18.1302 7.57789 18.1302 7.05719 17.6095L0.390524 10.9428C-0.130175 10.4221 -0.130175 9.57789 0.390524 9.05719C0.911223 8.53649 1.75544 8.53649 2.27614 9.05719L8 14.7811L21.7239 1.05719C22.2446 0.536493 23.0888 0.536493 23.6095 1.05719Z"
                                        fill="white"
                                      />
                                    </svg>
                                  </div>
                                )}
                                {/* <div className="media__item-actions">
                                  <Tooltip
                                  title="Media Preview"
                                  className="media__preview"
                                >
                                  <button
                                    onClick={() => {
                                      onPreviewMediaClickHandler(api);
                                    }}
                                  >
                                    <i className="feather-maximize-2" />
                                  </button>
                                </Tooltip>
                                  <CheckableTag
                                  className="media__item-check"
                                  checked={selectedMedia.indexOf(api) !== -1}
                                  onChange={(checked) =>
                                    handleSelectedMedia(api, checked)
                                  }
                                  // onChange={(checked) =>
                                  //   onAddImages(api, checked)
                                  // }
                                >
                                  {selectedMedia.indexOf(api) !== -1 && (
                                    <svg
                                      viewBox="0 0 18 13"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="media__check-logo"
                                    >
                                      <path
                                        d="M17 1L6 12L1 7"
                                        stroke="white"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  )}
                                </CheckableTag>
                                </div> */}

                                {/* <div className="img-label-wrapper">
                                <div className="img-label">{api.type}</div>
                                <div className="img-label-2">
                                  {api.image_url ||
                                  (api.url && api.ext !== 'video/mp4')
                                    ? 'Image'
                                    : 'Video'}
                                </div>
                              </div> */}
                                {api.image_url ||
                                api.cover_photo_url ||
                                (api.url && api.ext !== 'video/mp4') ? (
                                  <img
                                    className="media__item-img"
                                    src={
                                      api.image_url ??
                                      api.url ??
                                      api.cover_photo_url
                                    }
                                    alt=""
                                  />
                                ) : (
                                  <video
                                    id="my-video"
                                    className="video-js"
                                    data-setup="{}"
                                    preload="metadata"
                                  >
                                    <source
                                      src={
                                        api.video_url ? api.video_url : api.url
                                      }
                                    />
                                  </video>
                                )}
                              </CheckableTag>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </>
                )}
              </div>
              <div className="media-pagination">
                <Pagination
                  defaultCurrent={1}
                  current={currentPage}
                  total={data.length}
                  pageSize={sizePage}
                  onChange={handleChangePage}
                  onShowSizeChange={onShowSizeChange}
                />
              </div>
            </div>
            <div className="media__database-arrival-right">
              <h3 className="media__database-arrival-upload-title">
                {media.isEdit ? 'Edit Media' : 'Manual Upload'}
              </h3>
              
              <Form
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                layout="horizontal"
                onFinish={handleSubmitForm}
              >
                <Form.Item
                  label={
                    <>
                      Name <span className="is-required">&nbsp; *</span>
                    </>
                  }
                  rules={[{ required: true, message: 'Name is required!' }]}
                >
                  <Input
                    placeholder="Name"
                    onChange={(e) => handleChangeForm('name', e.target.value)}
                    value={media.name}
                  />
                  {error.name && (
                    <div className="error-message">Please input Name</div>
                  )}
                </Form.Item>
                <Form.Item label="Description">
                  <Input.TextArea
                    placeholder="Description"
                    rows={6}
                    onChange={(e) =>
                      handleChangeForm('description', e.target.value)
                    }
                    value={media.description}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <>
                      Upload<span className="is-required">&nbsp; *</span>
                    </>
                  }
                >
                  {imagePreviewUrl ? (
                    <div className="upload-image-preview">
                      <Tooltip placement="top" title="Delete">
                        <button
                          type="button"
                          onClick={handleDeletePreviewImage}
                          className="btn-remove"
                        >
                          <i className="feather-x" />
                        </button>
                      </Tooltip>
                      {(media.file &&
                        (media.file.type === 'video/mp4' ||
                          media.file.type === 'video/x-msvideo' ||
                          media.file.type === 'video/quicktime')) ||
                      ((media.ext === 'video/mp4' ||
                        media.ext === 'video/x-msvideo' ||
                        media.ext === 'video/quicktime') &&
                        !media.file) ? (
                        <video
                          controls="controls"
                          preload="metadata"
                          key={imagePreviewUrl}
                        >
                          <source src={imagePreviewUrl} type="video/mp4" />
                        </video>
                      ) : (
                        <img
                          className="media-avatar"
                          src={imagePreviewUrl}
                          alt="User profile logo preview"
                        />
                      )}
                    </div>
                  ) : (
                    <Upload
                      showUploadList={false}
                      beforeUpload={onFileChangedHandler}
                    >
                      <div
                        onDragLeave={() => setIsDragged(false)}
                        onDragOver={() => setIsDragged(true)}
                        className={`upload-drag-and-drop ${
                          isDragged ? 'active' : ''
                        }`}
                      >
                        <i className="feather-upload" />
                        <p>Drag or click to upload</p>
                      </div>
                    </Upload>
                  )}

                  {error.upload && (
                    <div className="error-message">Please upload media</div>
                  )}
                </Form.Item>

                {playlist.type === 'instagram' && (
                  <Form.Item
                    label="Youtube URL"
                    rules={[
                      { required: true, message: 'Youtube URL is required!' },
                    ]}
                  >
                    <Input
                      placeholder="Youtube URL"
                      onChange={(e) =>
                        handleChangeForm('youtube_url', e.target.value)
                      }
                      value={media.youtube_url}
                    />
                  </Form.Item>
                )}
                <Form.Item className="btn-wrapper">
                  {media.isEdit && (
                    <Button
                      type="danger"
                      className="cancel-upload-btn"
                      onClick={() => handleCancelEdit()}
                    >
                      Cancel
                    </Button>
                  )}
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="submit-upload-btn"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Modal>
      )}
     
      {editHashtagModal.isOpen && (
        <Modal
          title={editHashtagModal.title}
          visible={editHashtagModal.isOpen}
          onOk={handleSubmitDescription}
          onCancel={hideEditHashtagModal}
        >
          <div className="playlist__modal-calendar-end">
            <label className="playlist__modal-calendar-end-label">
              Playlist hashtags:
            </label>
            <Input
              value={playlist.description}
              onChange={onChangeDescription}
            />
          </div>
        </Modal>
      )}
      {deleteModal.isOpen && (
        <Modal
          title="Are you sure to delete this item?"
          visible={deleteModal.isOpen}
          onOk={onDeletePlaylistItemHandler}
          onCancel={onCloseModalHandler}
        >
          Are you sure to delete this item?
        </Modal>
      )}

      {deleteItemModal.isOpen && (
        <Modal
          title="Are you sure to delete this item?"
          visible={deleteItemModal.isOpen}
          onOk={onDeleteItemHandler}
          onCancel={onCloseDeleteItemModal}
        >
          Are you sure to delete this item?
        </Modal>
      )}

      {mediaPreviewModal.isOpen && (
        <Modal
          title={null}
          visible={mediaPreviewModal.isOpen}
          footer={null}
          onCancel={hidePreviewModal}
          className="custom-modal custom-modal-lg"
        >
          {mediaPreviewModal.video_url ? (
            <video id="my-video" className="video-js" data-setup="{}" controls>
              <source src={mediaPreviewModal.video_url} />
            </video>
          ) : mediaPreviewModal.image_url ? (
            <img src={mediaPreviewModal.image_url} alt="post" />
          ) : null}

          <div className="custom-modal-content">
            <div className="custom-modal-content-title">
              <div className="custom-modal-title">Title</div>
              <div className="custom-modal-description">
                {mediaPreviewModal.title ? mediaPreviewModal.title : 'No Title'}
              </div>
            </div>
            <div className="custom-modal-content-title">
              <div className="custom-modal-title">Description</div>
              <div className="custom-modal-description">
                {mediaPreviewModal.description
                  ? regexDescription(mediaPreviewModal.description)
                  : 'No description'}
              </div>
            </div>
            <div className="custom-modal-content-title">
              <div className="custom-modal-title">Media type</div>
              <div className="custom-modal-description">
                {mediaPreviewModal.video_url ||
                (mediaPreviewModal.image_url &&
                  mediaPreviewModal.image_url.slice(-3) === 'mp4')
                  ? 'Video'
                  : 'Image'}
              </div>
            </div>

            {mediaPreviewModal.qr_code && (
              <div className="custom-modal-content-title">
                <div className="custom-modal-title">QR code url</div>
                <div className="custom-modal-description">
                  {mediaPreviewModal.qr_code}
                </div>
              </div>
            )}
            {mediaPreviewModal.qr_code_position && (
              <div className="custom-modal-content-title">
                <div className="custom-modal-title">QR code position</div>
                <div className="custom-modal-description">
                  {mediaPreviewModal.qr_code_position}
                </div>
              </div>
            )}

            <DragDropContext onDragEnd={onDragEnd}>
              {mediaPreviewModal.type === 'activity' && (
                <div className="custom-modal-content-title">
                  <div className="custom-modal-title">All Curated Journeys</div>
                  <div
                    className="custom-modal-description"
                    style={{ width: '100%' }}
                  >
                    <div className="image-gallery-recommends">
                      {_.chunk(mediaPreviewModal.relatedPlaylist, 3).map(
                        (chunk, index) => {
                          return (
                            <Droppable
                              droppableId={`droppable-source-${index}`}
                              direction="horizontal"
                              key={`droppable-source-${index}`}
                            >
                              {(provided) => (
                                <div
                                  className="image-gallery-wrapper"
                                  key={`droppable-source-${index}`}
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {chunk.map((related, index2) => {
                                    return (
                                      <Draggable
                                        draggableId={`draggable-source-${index}-${index2}`}
                                        index={index * 3 + index2}
                                        key={`draggable-source-${index}-${index2}`}
                                      >
                                        {(provided) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className="image-wrapper"
                                          >
                                            <img
                                              src={related.cover_photo_url}
                                              alt={related.itinerary_name}
                                              className="image"
                                            />
                                            <h3>{related.itinerary_name}</h3>
                                          </div>
                                        )}
                                      </Draggable>
                                    );
                                  })}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              )}

              {playlists.find((x) => x.type === 'activity') && (
                <div className="custom-modal-content-title">
                  <div className="custom-modal-title">Gallery Recommends</div>
                  <div
                    className="custom-modal-description drop-area"
                    style={{ width: '100%' }}
                  >
                    <div className="drop-title">
                      <p>
                        Drag and drop your selected content here
                        <i className="mdi mdi-arrow-down" />
                      </p>
                      <p>All content dragged will be automatically saved</p>
                    </div>
                    <div className="image-gallery-recommends no-border">
                      <Droppable
                        droppableId="droppable-destination"
                        direction="horizontal"
                      >
                        {(provided) => (
                          <div
                            className="drop-container image-gallery-wrapper"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {_.filter(content).map((related, index2) => {
                              return (
                                <Draggable
                                  draggableId={`draggable-destination-${index2}`}
                                  index={index2}
                                  key={`draggable-destination-${index2}`}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className="image-wrapper"
                                    >
                                      <button
                                        className="btn-remove"
                                        onClick={() =>
                                          handleRemoveContent(index2)
                                        }
                                      >
                                        <i className="mdi mdi-close" />
                                      </button>
                                      <img
                                        src={related.cover_photo_url}
                                        alt={related.itinerary_name}
                                        className="image"
                                      />
                                      <h3>{related.itinerary_name}</h3>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </div>
                  </div>
                </div>
              )}
            </DragDropContext>

            {/* <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="source" direction="horizontal">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="source-wrapper"
                  >
                    {dummyJson.map((x, index) => {
                      return (
                        <Draggable
                          draggableId={`source-${index}`}
                          index={index}
                          key={index}
                        >
                          {(provided) => (
                            <div
                              className="list-draggable"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <p>{x.title}</p>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              <Droppable droppableId="content" direction="horizontal">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="content-wrapper"
                  >
                    {content.map((x, index) => {
                      return (
                        <Draggable
                          draggableId={`content-${index}`}
                          index={index}
                          key={index}
                        >
                          {(provided) => (
                            <div
                              className="list-draggable"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <p>{x.title}</p>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext> */}
          </div>
        </Modal>
      )}

      {dateModal.isOpen && (
        <Modal
          title={dateModal.title}
          visible={dateModal.isOpen}
          onOk={handleSubmitDate}
          onCancel={hideDateModal}
        >
          <div className="playlist__modal-calendar-start">
            <label className="playlist__modal-calendar-start-label">
              Start Date:
            </label>
            <DatePicker
              // defaultValue={moment(
              //   dateModal.start ? dateModal.start : new Date(),
              //   'YYYY-MM-DD HH:mm'
              // )}
              value={
                dateModal.start
                  ? moment(dateModal.start, 'YYYY-MM-DD HH:mm')
                  : dateModal.start
              }
              showTime
              onChange={onChangeDateStart}
            />
          </div>
          <div className="playlist__modal-calendar-end">
            <label className="playlist__modal-calendar-end-label">
              End Date:
            </label>
            <DatePicker
              // defaultValue={moment(
              //   dateModal.end ? dateModal.end : new Date(),
              //   'YYYY-MM-DD HH:mm'
              // )}
              value={
                dateModal.end
                  ? moment(dateModal.end, 'YYYY-MM-DD HH:mm')
                  : dateModal.end
              }
              showTime
              onChange={onChangeDateEnd}
            />
          </div>
          {access === 'arrival_landmark' && (
            <div className="playlist__modal-duration">
              <label className="playlist__modal-duration-label">
                Set Duration:
              </label>
              <InputNumber
                min={1}
                max={999}
                value={dateModal.duration / 1000}
                onChange={onDurationChange}
              />
            </div>
          )}

          {/* <div className="playlist__modal-calendar-end">
            <label className="playlist__modal-calendar-end-label">
              QR Code URL:
            </label>
            <Input value={dateModal.posts[0].qrcode_url} onChange={onChangeQrCodeUrl} />
          </div> */}
        </Modal>
      )}
      {locationModal.isOpen && (
        <Modal
          title={locationModal.title}
          visible={locationModal.isOpen}
          onOk={handleSubmitLocation}
          onCancel={hideLocationModal}
        >
          <div className="playlist__modal-calendar-start">
          <label className="playlist__modal-calendar-end-label">
              Location:
            </label>
            <Input value={locationModal.location} onChange={onChangeLocationModal} placeholder='Location COI'/>
          </div>
         
       

          {/* <div className="playlist__modal-calendar-end">
            <label className="playlist__modal-calendar-end-label">
              QR Code URL:
            </label>
            <Input value={dateModal.posts[0].qrcode_url} onChange={onChangeQrCodeUrl} />
          </div> */}
        </Modal>
      )}
      {editModal.isOpen && (
        <Modal
          title={editModal.title}
          visible={editModal.isOpen}
          onOk={handleEditPost}
          onCancel={hideEditModal}
        >
          <div className="playlist__modal-calendar-end">
            <label className="playlist__modal-calendar-end-label">
              Venue Name:
            </label>
            <Input value={editModal.coi_name} onChange={onChangeEditModal} />
          </div>
        </Modal>
      )}

      <Collapse className="playlist__arrival-wrapper">
        {playlistList.map((
          playlist,
          index // eslint-disable-line
        ) => (
          <Panel
            key={playlist._id}
            header={
              playlist.name.includes('Social Wall')
                ? `${playlist.name} - #${playlist.description}`
                : playlist.name
            }
            className="playlist__header"
          >
            <div className="playlist__panel">
              <div className="playlist__options">
                <div className="playlist__checkbox">
                  <Checkbox
                    checked={
                      isSelectAllChecked.id === playlist._id &&
                      isSelectAllChecked.selected
                    }
                    onChange={(event) =>
                      handleSelectAll(playlist.posts, event, playlist._id)
                    }
                  />
                  <label className="playlist__checkbox-title">Select All</label>
                </div>
                <div className="playlist__action-buttons">
                  {playlist._id !== '61de69b891e46703387899d7' &&
                  playlist._id !== '61ee3899d0489814a4cf64f1' &&
                  !playlist.name.includes('Social Wall') && ( // social wall playlist
                      <Button
                        className="playlist__add-file-btn"
                        onClick={() => handleRefreshData(playlist)}
                      >
                        Update API
                      </Button>
                    )}

                  {playlist._id !== '61de69b891e46703387899d7' &&
                    !playlist.name.includes('Social Wall') &&
                    playlist.type !== 'activity' &&
                    playlist.type !== 'wayfinding' && (
                      <Button
                        className="playlist__add-file-btn"
                        onClick={() => handleAddNewFileModal(playlist)}
                      >
                        Add new file
                      </Button>
                    )}
                  {/* {playlist._id !== '61de69b891e46703387899d7' &&
                    !playlist.name.includes('Social Wall') &&
                    playlist.type !== 'activity' &&
                    playlist.type !== 'wayfinding' && (
                      <div className="media__header-middle">
                      <div className="media__select-add">
                        <Button
                          type="primary"
                          className="btn-primary"
                          onClick={() => setModalUploadURL({isOpen:true, playlist})}
                        >
                          Add Youtube URL to Gallery Gig
                        </Button>
                      </div>
                    </div>
                    )} */}
                  {playlist.type === 'wayfinding' &&
                  !playlist.name.includes('Social Wall') && ( // social wall playlist
                      <Button
                        className="playlist__add-file-btn"
                        onClick={() => onClickEditButton(playlist)}
                      >
                        Edit Venue Name
                      </Button>
                    )}
                  {playlist._id !== '61de69b891e46703387899d7' &&
                    playlist._id !== '61ee3899d0489814a4cf64f1' &&
                    !playlist.name.includes('Social Wall') &&
                    playlist.type !== 'wayfinding' && (
                      <Button
                        className="playlist__set-time-btn"
                        onClick={() => onClickSetDateButton(playlist)}
                      >
                        Set date time
                      </Button>
                    )}
                  {playlist.type ==='activity' && (
                      <Button
                        className="playlist__set-time-btn"
                        onClick={() => onClickAddLocation(playlist)}
                      >
                        Add Location
                      </Button>
                    )}
                  {playlist.name.includes('Social Wall') && (
                    <Button
                      className="playlist__add-file-btn"
                      onClick={() => onClickEditHashtag(playlist)}
                    >
                      Edit Hashtags
                    </Button>
                  )}
                  <Button
                    className="playlist__delete-file-btn btn-danger ant-btn"
                    type="danger"
                    onClick={() => onRemoveButtonClickHandler(playlist)}
                  >
                    Delete
                  </Button>
                </div>
              </div>
              <PlayListGrid
                posts={playlist.posts}
                playlist={playlist}
                distance={1}
                axis="xy"
                onSortEnd={({ oldIndex, newIndex }) => {
                  const groupedPlaylist = _.groupBy(
                    playlists,
                    (item) => item._id
                  );
                  const [items] = groupedPlaylist[playlist._id];
                  // const [reorderedItem] = items.posts.splice(oldIndex, 1);
                  // items.posts.splice(newIndex, 0, reorderedItem);
                  // dispatch(addPostPlaylist({ playlist: items }));
                  const newDataSort = items;
                  newDataSort.posts = arrayMoveImmutable(
                    newDataSort.posts,
                    oldIndex,
                    newIndex
                  );
                  dispatch(
                    addPostPlaylist({
                      playlist: newDataSort,
                      access,
                      action: 'update',
                    })
                  );
                }}
                onPreviewModalClickHandler={(post) =>
                  onPreviewModalClickHandler(post, playlist)
                }
                onRemoveButtonClickHandler={onRemoveButtonClickHandler}
                handleChange={handleChange}
                selectedTags={selectedTags}
                access={access}
              />
            </div>
          </Panel>
        ))}
      </Collapse>
    </div>
  );
}

export default PlaylistArrival;
