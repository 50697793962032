import Axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { url } from 'utils/url';
import { log } from 'utils';
import { errorMsg } from 'utils';

export const PLAYLIST_FETCH_DATA_INIT = createAction(
  'PLAYLIST_FETCH_DATA_INIT'
);
export const PLAYLIST_FETCH_DATA_SUCCESS = createAction(
  'PLAYLIST_FETCH_DATA_SUCCESS'
);
export const PLAYLIST_FETCH_DATA_FAIL = createAction(
  'PLAYLIST_FETCH_DATA_FAIL'
);

export const PLAYLIST_DELETE_PLAYLIST_INIT = createAction(
  'PLAYLIST_DELETE_PLAYLIST_INIT'
);
export const PLAYLIST_DELETE_PLAYLIST_SUCCESS = createAction(
  'PLAYLIST_DELETE_PLAYLIST_SUCCESS'
);
export const PLAYLIST_DELETE_PLAYLIST_FAIL = createAction(
  'PLAYLIST_DELETE_PLAYLIST_FAIL'
);

export const PLAYLIST_CLEAR_DATA = createAction('PLAYLIST_CLEAR_DATA');

export const PLAYLIST_CREATE_PLAYLIST_INIT = createAction(
  'PLAYLIST_CREATE_PLAYLIST_INIT'
);
export const PLAYLIST_CREATE_PLAYLIST_SUCCESS = createAction(
  'PLAYLIST_CREATE_PLAYLIST_SUCCESS'
);
export const PLAYLIST_CREATE_PLAYLIST_FAIL = createAction(
  'PLAYLIST_CREATE_PLAYLIST_FAIL'
);

export const PLAYLIST_MODIFY_PLAYLIST_INIT = createAction(
  'PLAYLIST_MODIFY_PLAYLIST_INIT'
);
export const PLAYLIST_MODIFY_PLAYLIST_SUCCESS = createAction(
  'PLAYLIST_MODIFY_PLAYLIST_SUCCESS'
);
export const PLAYLIST_MODIFY_PLAYLIST_FAIL = createAction(
  'PLAYLIST_MODIFY_PLAYLIST_FAIL'
);
export const PLAYLIST_CLEAR_PLAYLIST_INIT = createAction(
  'PLAYLIST_CLEAR_PLAYLIST_INIT'
);
export const PLAYLIST_CLEAR_PLAYLIST_RESET = createAction(
  'PLAYLIST_CLEAR_PLAYLIST_RESET'
);
export const PLAYLIST_CLEAR_PLAYLIST_SUCCESS = createAction(
  'PLAYLIST_CLEAR_PLAYLIST_SUCCESS'
);
export const PLAYLIST_CLEAR_PLAYLIST_FAIL = createAction(
  'PLAYLIST_CLEAR_PLAYLIST_FAIL'
);

export const PLAYLIST_CLEAN_UP = createAction('PLAYLIST_CLEAN_UP');

export const PLAYLIST_CLEAR_DATA_LOGOUT = createAction(
  'PLAYLIST_CLEAR_DATA_LOGOUT'
);

export const PLAYLIST_BULK = createAction('PLAYLIST_BULK');

export const fetchPlaylist = ({ type, siteId, message }) => {
  return async (dispatch, getState) => {
    dispatch(PLAYLIST_FETCH_DATA_INIT());
    let playlist;
    if (siteId) {
      let playlistData;

      try {
        playlistData = await Axios.get(`${url}/playlist`, {
          params: {
            siteId,
            type,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });
        playlist = playlistData.data.data;
        if (type === 'activity') {
          const url_ngs = 'https://cache.gallery.adsign.adactive.asia';
          // const url_ngs = 'https://apihub.nationalgallery.sg/_presentation';
          const serverToken =
            'PROD_eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkZXZlbG9wZXJfaWQiOjI2LCJkaXNwbGF5X25hbWUiOiJBZGl0eWEiLCJ2ZW5kb3JfbmFtZSI6IkFEQUNUSVZFIiwiZW1haWwiOiJ0ZWNodGVhbUBhZGFjdGl2ZS5hc2lhIiwidG91Y2hwb2ludCI6IkFDVElWSVRZIFBJTExBUiIsIm1vZGUiOiJQUk9EIiwiaWF0IjoxNjM4MTc0MjgwfQ.FURJTiTs462okpstFfHR8ywMR7jUk7C9NUsxS2aNuJQ';
          const headers_cache = {
            Authorization: `${serverToken}`,
            // 'Auth-Token': `${token}`,
          };

          const body_cache = {
            input_body: {
              username: 'dev@adactive.asia',
              password: 'Password 123@',
            },
          };
          const dataPlaylist = await Axios.post(
            `${url_ngs}/curated`,
            body_cache,
            {
              headers: headers_cache,
            }
          );
          const curatedPlaylist = dataPlaylist.data.data;
          log(dataPlaylist, 'data playlist');
          for (const ind in playlist[0].posts) {
            const post = playlist[0].posts[ind];

            var relatedData = curatedPlaylist.filter((playlist) =>
              playlist.itinerary_cois.find(
                (coi) =>
                  coi.coi_id === post.artwork_coi_id ?? post.product_coi_id
              )
            );

            relatedData = relatedData.map((data) => {
              return {
                cover_photo_url: data.image_url,
                description: data.description,
                estimated_duration: data.estimated_duration,
                itinerary_id: data.itinerary_id,
                itinerary_name: data.itinerary_name,
                subtitle: data.subtitle,
              };
            });
            log(relatedData, 'related data');
            playlist[0].posts[ind].relatedPlaylist = relatedData;
          }
        }
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(PLAYLIST_FETCH_DATA_FAIL({ error }));
      }
    }
    return dispatch(
      PLAYLIST_FETCH_DATA_SUCCESS({
        ...getState().playlist,

        list: playlist,
      })
    );
  };
};

export const fetchPlaylistStaging = ({ type, siteId, message }) => {
  return async (dispatch, getState) => {
    dispatch(PLAYLIST_FETCH_DATA_INIT());
    let playlist;
    if (siteId) {
      let playlistData;

      try {
        playlistData = await Axios.get(`${url}/playlist`, {
          params: {
            siteId,
            type,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });
        playlist = playlistData.data.data;
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(PLAYLIST_FETCH_DATA_FAIL({ error }));
      }
    }
    return dispatch(
      PLAYLIST_FETCH_DATA_SUCCESS({
        ...getState().playlist,

        staging: playlist,
      })
    );
  };
};

export const addPostPlaylist = ({ playlist, access, action, message }) => {
  return async (dispatch, getState) => {
    dispatch(PLAYLIST_MODIFY_PLAYLIST_INIT());
    try {
      playlist.id = playlist._id;
      playlist.access = access;
      playlist.action = action;
      playlist.message = message;
      if (playlist.type === 'activity') {
        log('add activity');
        const url_ngs = 'https://cache.gallery.adsign.adactive.asia';
        // const url_ngs = 'https://apihub.nationalgallery.sg/_presentation';
        const serverToken =
          'PROD_eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkZXZlbG9wZXJfaWQiOjI2LCJkaXNwbGF5X25hbWUiOiJBZGl0eWEiLCJ2ZW5kb3JfbmFtZSI6IkFEQUNUSVZFIiwiZW1haWwiOiJ0ZWNodGVhbUBhZGFjdGl2ZS5hc2lhIiwidG91Y2hwb2ludCI6IkFDVElWSVRZIFBJTExBUiIsIm1vZGUiOiJQUk9EIiwiaWF0IjoxNjM4MTc0MjgwfQ.FURJTiTs462okpstFfHR8ywMR7jUk7C9NUsxS2aNuJQ';
        const headers_cache = {
          Authorization: `${serverToken}`,
          // 'Auth-Token': `${token}`,
        };

        const body_cache = {
          input_body: {
            username: 'dev@adactive.asia',
            password: 'Password 123@',
          },
        };
        const dataPlaylist = await Axios.post(
          `${url_ngs}/curated`,
          body_cache,
          {
            headers: headers_cache,
          }
        );
        const curatedPlaylist = dataPlaylist.data.data;
        log(dataPlaylist, playlist, 'data playlist');
        for (const ind in playlist.posts) {
          const post = playlist.posts[ind];
          var relatedData = curatedPlaylist.filter((playlist) =>
            playlist.itinerary_cois.find(
              (coi) =>
                coi.coi_id ===
                (post.artwork_coi_id
                  ? post.artwork_coi_id
                  : post.product_coi_id)
            )
          );

          relatedData = relatedData.map((data) => {
            return {
              cover_photo_url: data.image_url,
              description: data.description,
              estimated_duration: data.estimated_duration,
              itinerary_id: data.itinerary_id,
              itinerary_name: data.itinerary_name,
              subtitle: data.subtitle,
            };
          });
          log(relatedData, 'related data');
          playlist.posts[ind].recommendPlaylist = relatedData;
        }
        // const url_ngs = 'https://apihub.nationalgallery.sg/_presentation';
        // const serverToken =
        //   'PROD_eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkZXZlbG9wZXJfaWQiOjI2LCJkaXNwbGF5X25hbWUiOiJBZGl0eWEiLCJ2ZW5kb3JfbmFtZSI6IkFEQUNUSVZFIiwiZW1haWwiOiJ0ZWNodGVhbUBhZGFjdGl2ZS5hc2lhIiwidG91Y2hwb2ludCI6IkFDVElWSVRZIFBJTExBUiIsIm1vZGUiOiJQUk9EIiwiaWF0IjoxNjM4MTc0MjgwfQ.FURJTiTs462okpstFfHR8ywMR7jUk7C9NUsxS2aNuJQ';
        // var headers = {
        //   Authorization: `${serverToken}`,
        //   // 'Auth-Token': `${token}`,
        // };
        // const login = {
        //   input_body: {
        //     username: 'dev@adactive.asia',
        //     password: 'Password 123@',
        //   },
        // };
        // const dataLogin = await Axios.post(`${url_ngs}/authentication`, login, {
        //   headers,
        // });
        // const token = dataLogin.data.data.auth_token;
        // headers['Auth-Token'] = token;
        // for (const ind in playlist.posts) {
        //   const post = playlist.posts[ind];
        //   log(post);
        //   if (!post.recommendPlaylist) {
        //     log(post, 'no recommend playlist');
        //     const dataPost = {
        //       input_body: {
        //         cois: [
        //           post.artwork_coi_id
        //             ? {
        //                 coi_id: post.artwork_coi_id,
        //                 coi_type: 'Artwork',
        //               }
        //             : {
        //                 coi_id: post.product_coi_id,
        //                 coi_type: 'Product',
        //               },
        //         ],
        //       },
        //     };
        //     const relatedPlaylist = await Axios.post(
        //       `${url_ngs}/gallery_hq/itinerary/related_itinerary`,
        //       dataPost,
        //       { headers }
        //     );
        //     log(relatedPlaylist, 'related playlist');
        //     playlist.posts[ind].recommendPlaylist = relatedPlaylist.data.data;
        //   }
        // }
      }
      log(playlist);

      await Axios.post(`${url}/playlist/edit`, playlist, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        PLAYLIST_MODIFY_PLAYLIST_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }
    // const play = JSON.stringify(playlist);
    toastr.success('', 'Playlist is updated successfully');
    dispatch(PLAYLIST_MODIFY_PLAYLIST_SUCCESS({ playlist }));
    return dispatch(
      fetchPlaylist({ siteId: playlist.siteId, type: playlist.type })
    );
  };
};
export const refreshPostPlaylist = ({ playlist }) => {
  return async (dispatch, getState) => {
    dispatch(PLAYLIST_MODIFY_PLAYLIST_INIT());
    const url_ngs = 'https://apihub.nationalgallery.sg/_presentation';
    const serverToken =
      'PROD_eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkZXZlbG9wZXJfaWQiOjI2LCJkaXNwbGF5X25hbWUiOiJBZGl0eWEiLCJ2ZW5kb3JfbmFtZSI6IkFEQUNUSVZFIiwiZW1haWwiOiJ0ZWNodGVhbUBhZGFjdGl2ZS5hc2lhIiwidG91Y2hwb2ludCI6IkFDVElWSVRZIFBJTExBUiIsIm1vZGUiOiJQUk9EIiwiaWF0IjoxNjM4MTc0MjgwfQ.FURJTiTs462okpstFfHR8ywMR7jUk7C9NUsxS2aNuJQ';
    var headers = {
      Authorization: `${serverToken}`,
      // 'Auth-Token': `${token}`,
    };
    const login = {
      input_body: {
        username: 'dev@adactive.asia',
        password: 'Password 123@',
      },
    };
    try {
      if (playlist.type === 'wayfinding') {
        //conditional for update data just for the wayfinding aka venue coi
        const dataLogin = await Axios.post(`${url_ngs}/authentication`, login, {
          headers,
        });
        const token = dataLogin.data.data.auth_token;
        headers['Auth-Token'] = token;
        playlist.id = playlist._id;
        const body = {
          input_body: {
            search_value: '',
            page: 1,
            page_size: 1000,
            only_pinned: false,
            pinned_first: false,
          },
        };
        const api1 = await Axios.post(
          `${url_ngs}/gallery_hq/coi/search/venue`,
          body,
          {
            headers,
          }
        );
        const venueList = api1.data.data.search_result;

        for (const index in playlist.posts) {
          if (playlist.posts[index].venue_coi_id) {
            const newData = venueList.find(
              (venue) =>
                venue.venue_coi_id === playlist.posts[index].venue_coi_id
            );
            playlist.posts[index] = {
              ...playlist.posts[index],
              floor_number: newData.floor_number,
              cover_photo_url: newData.cover_photo_url,
            };
          }
        }
        await Axios.post(`${url}/playlist/edit`, playlist, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });
      } else if (playlist.type === 'artwork') {
        const dataLogin = await Axios.post(`${url_ngs}/authentication`, login, {
          headers,
        });
        const token = dataLogin.data.data.auth_token;
        headers['Auth-Token'] = token;
        playlist.id = playlist._id;
        var api1 = await Axios.get(
          `${url_ngs}/gallery_hq/arrival_landmark/all`,
          {
            headers,
          }
        );
        var list_api1 = [];

        api1 = api1.data.data.arrival_landmarks.map((x) => {
          list_api1 = [...list_api1, ...x.displayed];
          return x.displayed;
        });
        log(api1, 'api1');
        log(list_api1, 'arrival landmark from ghq');
        for (const i in playlist.posts) {
          if (playlist.posts[i].al_id) {
            const newPost = list_api1.find(
              (api) => api.al_id === playlist.posts[i].al_id
            );
            if (newPost) {
              log(newPost, 'new post');
              playlist.posts[i] = newPost;
            }
          }
        }
      } else {
        // conditional for update data for either artwork and product coi (a lot of these two data in one page)
        const dataLogin = await Axios.post(`${url_ngs}/authentication`, login, {
          headers,
        });
        const token = dataLogin.data.data.auth_token;
        headers['Auth-Token'] = token;
        playlist.id = playlist._id;

        const body = {
          input_body: {
            search_value: '',
            page: 1,
            page_size: 1000,
            only_pinned: false,
            pinned_first: false,
          },
        };
        const body2 = {
          input_body: {
            search_value: '',
            page: 1,
            page_size: 1000,
            category: ['Exhibitions'],
          },
        };
        const api1 = await Axios.post(
          `${url_ngs}/gallery_hq/coi/search/artwork`,
          body,
          {
            headers,
          }
        );
        const api2 = await Axios.post(
          `${url_ngs}/gallery_hq/coi/search/product`,
          body2,
          { headers }
        );
        const artworkList = api1.data.data.search_result;
        const productList = api2.data.data.search_result;

        for (const index in playlist.posts) {
          if (playlist.posts[index].product_coi_id) {
            const newData = productList.find(
              (product) =>
                product.product_coi_id === playlist.posts[index].product_coi_id
            );
            if (newData)
              playlist.posts[index] = {
                ...playlist.posts[index],
                coi_name: newData.coi_name,
                description: newData.description,
                image_url: newData.image_url,
              };
          } else if (playlist.posts[index].artwork_coi_id) {
            const newData = artworkList.find(
              (product) =>
                product.artwork_coi_id === playlist.posts[index].artwork_coi_id
            );
            if (newData)
              playlist.posts[index] = {
                ...playlist.posts[index],
                coi_name: newData.coi_name,
                description: newData.description,
                image_url: newData.image_url,
              };
          }
        }
        await Axios.post(`${url}/playlist/edit`, playlist, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });
      }
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        PLAYLIST_MODIFY_PLAYLIST_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }
    // const play = JSON.stringify(playlist);
    toastr.success('', 'Playlist is updated successfully');
    return dispatch(PLAYLIST_MODIFY_PLAYLIST_SUCCESS({ playlist }));
  };
};
export const removePostPlaylist = ({ playlist, access }) => {
  return async (dispatch, getState) => {
    dispatch(PLAYLIST_CLEAR_PLAYLIST_INIT());
    try {
      playlist.id = playlist._id;
      playlist.access = access;
      playlist.action = 'delete';
      await Axios.post(`${url}/playlist/edit`, playlist, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      toastr.success('', 'Playlist is updated successfully');
      dispatch(PLAYLIST_CLEAR_PLAYLIST_SUCCESS({ playlist }));
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        PLAYLIST_CLEAR_PLAYLIST_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }
    // const play = JSON.stringify(playlist);

    return dispatch(PLAYLIST_CLEAR_PLAYLIST_RESET());
  };
};

// export const deletePlaylist = (id) => {
//   console.log(id, '<<< id delete playlist');
//   return async (dispatch, getState) => {
//     dispatch(PLAYLIST_DELETE_PLAYLIST_INIT());

//     try {
//       await Axios.delete(`${url}/playlistMedia/delete`, {
//         params: {
//           id,
//         },
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
//         },
//       });
//       console.log('try delete media');
//     } catch (error) {
//       let errorMessage;
//       if (error.response) {
//         errorMessage = error && error.response && error.response.data.message;
//       }
//       toastr.error('', errorMessage);
//       return dispatch(
//         PLAYLIST_DELETE_PLAYLIST_FAIL({
//           error: error && error.response && error.response.data,
//         })
//       );
//     }

//     toastr.success('', 'The media was deleted.');
//     return dispatch(PLAYLIST_DELETE_PLAYLIST_SUCCESS({ id }));
//   };
// };

export const modifyMedia = ({
  id,
  name,
  description,
  start,
  end,
  duration,
  categoryId,
  categoryName,
  file,
  fileUrl,
  siteId,
  modifiedAt,
  modifiedBy,
  type,
  days,
  dates,
  color,
}) => {
  return async (dispatch, getState) => {
    dispatch(PLAYLIST_MODIFY_PLAYLIST_INIT());
    log(days, dates);
    let fd = new FormData();

    fd.set('path', 'media');
    fd.set('id', id);
    fd.set('name', name);
    fd.set('description', description);
    if (start && end && start !== 'Invalid date' && end !== 'Invalid date') {
      fd.set('start', start);
      fd.set('end', end);
    }
    if (duration) {
      fd.set('duration', duration);
    }
    fd.set('siteId', siteId);
    if (categoryId && categoryName) {
      fd.set('categoryId', categoryId);
      fd.set('categoryName', categoryName);
    }
    fd.set('dates', JSON.stringify(dates));
    fd.set('days', JSON.stringify(days));
    fd.set('type', type);
    fd.set('color', color);
    fd.append('file', file);
    let updateMedia;

    try {
      updateMedia = await Axios.post(`${url}/media/edit`, fd, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'content-type': 'multipart/form-data',
        },
      });
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        PLAYLIST_MODIFY_PLAYLIST_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'Media updated successfully');

    return dispatch(
      PLAYLIST_MODIFY_PLAYLIST_SUCCESS({ media: updateMedia.data.data, id })
    );
  };
};
