import { Breadcrumb, Modal, Table, Tooltip, Input } from 'antd';
import ModalSiteForm from 'components/ModalSiteForm';
import { useFormatMessage } from 'hooks';
import moment from 'moment';
import paths from 'pages/Router/paths';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { log } from 'utils';
import {
  createSite,
  deleteSite,
  fetchSites,
  modifySite,
} from 'state/actions/sites';
// import Table from 'components/Table';
import { clearUsersData } from 'state/actions/users';
import { regexInput } from 'utils/regex';
import './Sites.scss';
import _ from 'underscore';

const Sites = () => {
  const {
    // isAdmin,

    loading,
    deleted,
    updated,
    sitesList,
    userData,
    role,
  } = useSelector(
    (state) => ({
      // isAdmin: state.auth.userData.isAdmin,

      loading: state.sites.loading,
      deleted: state.sites.deleted,
      updated: state.sites.updated,
      sitesList: state.sites.list,
      userData: state.auth.userData,
      role: state.auth.userData.role,
    }),
    shallowEqual
  );

  const defaultSite = {
    name: '',
    description: '',
    organization: '',
    modifiedAt: moment(Date.now()).format('DD-MM-YY hh:mm:ss'),
    modifiedBy: userData.email ? userData.email : '',
  };

  const [deleteModal, setDeleteModal] = useState({
    siteId: '',
    siteName: '',
    userId: null,
    isOpen: false,
  });
  const [search, setSearch] = useState();
  const [visible, setVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [site, setSite] = useState(defaultSite);
  const [error, setError] = useState(null);
  const [confirmationTitle, setConfirmationTitle] = useState('');
  const [disableBtn, setDisableBtn] = useState(true);
  const [sort, setSort] = useState(null);

  const dispatch = useDispatch();

  const redirect = role === 'superadmin' ? false : <Redirect to={paths.ROOT} />;

  useEffect(() => {
    if (role === 'superadmin') {
      dispatch(fetchSites());
    }

    return () => dispatch(clearUsersData());
  }, [dispatch, role]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        userId: null,
        isOpen: !prevState.isOpen,
      }));
      dispatch(fetchSites());
    }
  }, [deleted, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    log(updated);
    if (updated && !loading) {
      dispatch(fetchSites());
      setVisible(false);
    }
  }, [updated, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  const onCreate = (values) => {
    const action = isEditing ? modifySite : createSite;
    log(values, '<<<< values site oncreate');
    dispatch(action(values));
  };

  const onNewSiteHandler = () => {
    log('handle new site');
    setSite(defaultSite);
    setIsEditing(false);
    setVisible(true);
  };

  const onRemoveButtonClickHandler = (siteId, siteName) => {
    setDeleteModal((prevState) => ({
      siteId,
      siteName,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ siteId: null, isOpen: false });
  };

  const onDeleteSiteHandler = () => {
    if (confirmationTitle === deleteModal.siteName) {
      dispatch(deleteSite(deleteModal.siteId));
    }
  };

  const handleSearch = (e) => {
    setError(null);
    if (!regexInput(e.target.value) && e.target.value) {
      setError('Search cannot using special character');
    }

    setSearch(e.target.value);
  };

  const columns = [
    {
      title: 'Site Name',
      dataIndex: 'name',
      sorter: true,
    },
    {
      title: 'Organization',
      dataIndex: 'organizationName',
      sorter: true,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      sorter: true,
    },
    {
      title: 'Last Modified By',
      dataIndex: 'modifiedBy',
      sorter: true,
      render: (text, record) => {
        return <div>{record.modifiedBy}</div>;
      },
    },
    {
      title: 'Last Modified Date',
      dataIndex: 'modifiedAt',
      sorter: true,
      render: (text, record) => {
        if (record.modifiedAt) {
          return (
            <div>{`${moment(record.modifiedAt)
              .utcOffset('+08:00')
              .format('h:mm A, MMM Do YYYY')}`}</div>
          );
        }
        return <span>-</span>;
      },
    },
    {
      title: 'Options',
      dataIndex: 'option',

      render: (text, record) => {
        return (
          <div className="sites__actions">
            <Tooltip title="Edit Site">
              <button
                onClick={() => {
                  setSite(record);
                  setVisible(true);
                  setIsEditing(true);
                }}
                data-tooltip="Edit"
                className="btn btn--edit"
              >
                <i className="feather-edit"></i>
              </button>
            </Tooltip>

            <Tooltip title="Delete Site">
              <button
                type="button"
                onClick={() =>
                  onRemoveButtonClickHandler(record._id, record.name)
                }
                data-tooltip="Delete"
                className="btn btn--delete"
              >
                <i className="feather-trash-2"></i>
              </button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const handleChangeTable = (pagination, filters, sorter, extra) => {
    setSort(sorter);
  };

  const data = search
    ? sitesList.filter((el) => {
        const clonedElem = { ...el };
        delete clonedElem.id;
        return clonedElem.name.toLowerCase().includes(search.toLowerCase());
      })
    : sitesList;

  const sortedData = _.sortBy(data, sort ? sort.field : 'name');
  const sortedDataDirection =
    sort && sort.order === 'descend' ? sortedData.reverse() : sortedData;

  const confirmMessage = useFormatMessage('Sites.confirm');

  const permDeleteMessage = useFormatMessage('Sites.permDelete');

  return (
    <section className="sites">
      <div className="header-content">
        <div className="header-left-content">
          <div className="breadcrumb-wrapper">
            <Breadcrumb>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item className="breadcrumb-active">
                Sites
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <h1 className="page-title">Sites</h1>
        </div>
        <div className="header-right-content">
          <div className="searchbar-wrapper">
            <div className="searchbar">
              <i className="feather-search"></i>
              <input
                type="text"
                placeholder="Search..."
                value={search}
                onChange={handleSearch}
              />
            </div>
            {error && <div className="search-error">{error}</div>}
          </div>
          <button className="button-primary" onClick={onNewSiteHandler}>
            <i className="feather-plus" />
            New Site
          </button>
        </div>
      </div>

      {redirect}
      {deleteModal.isOpen && (
        <Modal
          title={confirmMessage}
          visible={deleteModal.isOpen}
          onOk={onDeleteSiteHandler}
          onCancel={onCloseModalHandler}
          okButtonProps={{
            disabled: disableBtn,
          }}
        >
          <p>{permDeleteMessage}</p>
          <span>
            To delete <strong>{deleteModal.siteName}</strong> type the site name
            <strong> {deleteModal.siteName}</strong>.
          </span>
          <Input
            onChange={(e) => {
              setConfirmationTitle(e.target.value);

              if (e.target.value === deleteModal.siteName) {
                setDisableBtn(false);
              } else {
                setDisableBtn(true);
              }
            }}
          />
        </Modal>
      )}

      {visible && (
        <ModalSiteForm
          visible={true}
          onCancel={() => {
            setVisible(false);
          }}
          onCreate={onCreate}
          site={site}
          setSite={setSite}
          isEditing={isEditing}
        />
      )}

      <div className="sites__table">
        {loading ? (
          <ClipLoader />
        ) : (
          <Table
            columns={columns}
            dataSource={sortedDataDirection}
            rowKey="_id"
            onChange={handleChangeTable}
          />
        )}
        {error && 'Show error'}
      </div>
    </section>
  );
};

export default Sites;
