import React, { useEffect, useState } from 'react';
import {
  Pagination,
  Tag,
  Button,
  Select,
  Modal,
  Form,
  Input,
  Upload,
  Tooltip,
  message,
} from 'antd';
import uuid from 'uuid';
import './ActivityPillar.css';
import { toastr } from 'react-redux-toastr';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import {
  deletePlaylist,
  fetchActivityDirect,
  fetchArrivalInit,
  // fetchArrivalNew,
} from 'state/actions/api';
import { fetchPlaylist, addPostPlaylist } from 'state/actions/playlist';
// import PlaylistPreview from 'components/PlaylistPreview';
import { imgUrl } from 'utils/url';
import { submitAddImage } from 'state/actions/api';
import PlaylistArrival from 'components/PlaylistArrival';

const { Option } = Select;

function ActivityPillar({ current }) {
  const [playlist, setPlaylist] = useState();
  const { siteId } = useParams();
  const [search, setSearch] = useState('');
  const [source, setSource] = useState();
  const [selected, setSelected] = useState([]);
  const [error, setError] = useState(null);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(8);
  const [sizePage, setSizePage] = useState(8);
  const { CheckableTag } = Tag;
  const [currentPage, setCurrentPage] = useState(0);
  const [modalUpload, setModalUpload] = useState(false);
  const [isDragged, setIsDragged] = useState(false);
  const [media, setMedia] = useState({
    file: null,
    logo: null,
    name: '',
    name2: '',
    description: '',
  });
  const [deleteModal, setDeleteModal] = useState({
    list: [],
    isOpen: false,
  });
  // const [uploading, setUploading] = useState(false);

  const [mediaPreviewModal, setMediaPreviewModal] = useState({
    title: '',
    isOpen: false,
    source: '',
  });

  const {
    apiList,
    loading,
    playlistList,
    updated,
    uploading,
    deleted,
  } = useSelector(
    (state) => ({
      apiList: state.api.artwork,
      loading: state.api.loading,
      playlistList: state.playlist.list,
      updated: state.playlist.added,
      uploading: state.api.uploading,
      deleted: state.api.deleted,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchArrivalInit());
    dispatch(fetchPlaylist({ siteId, type: 'activity' }));
    // setMedias(mediaList);
  }, [siteId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // setMedias(mediaList);
  }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if ((uploading && !loading) || (deleted && !loading)) {
      handleReset();
      // dispatch(fetchActivity({ siteId }));
    }
    // setMedias(mediaList);
  }, [uploading, loading, deleted]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(fetchActivityDirect({ siteId }));
    setPlaylist(playlistList[0]);
    // dispatch(fetchArrivalNew({ siteId, type: 'artwork' }));
    setSource();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        ...prevState,
        list: [],
        isOpen: false,
      }));
    }
  }, [deleted, loading]);

  useEffect(() => {
    if (current === 'search') dispatch(fetchActivityDirect({ siteId }));
    setSource();
    playlistList && setPlaylist(playlistList[0]);
  }, [current]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    // setSearch();
    setError(null);
    // setMedias(mediaList);
  }, [playlist]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    // setSearch();
    setSelected([]);
    // setMedias(mediaList);
  }, [updated]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // setSearch();
    setCurrentPage(1);
    setMinValue(0);
    setMaxValue(sizePage);
    // setMedias(mediaList);
  }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setMaxValue(minValue + sizePage);
  }, [sizePage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {});

  const handleChangePage = (value) => {
    setCurrentPage(value);
    if (value <= 1) {
      setMinValue(0);
      setMaxValue(sizePage);
    } else {
      setMinValue(value * sizePage - sizePage);
      setMaxValue(value * sizePage);
    }
  };
  const onAddImages = (img, checked) => {
    if (checked) {
      setSelected([...selected, img]);
    } else {
      const index = selected.indexOf(img);
      selected.splice(index, 1);
      setSelected([...selected]);
    }
  };

  const onSearch = (e) => {
    setSearch(e.target.value);
  };

  var data =
    source && source !== 'all'
      ? apiList.filter((el) => {
          const clonedElem = { ...el };
          if (clonedElem.type)
            return clonedElem.type.toLowerCase().includes(source.toLowerCase());
          return false;
        })
      : apiList;

  data = search
    ? data.filter((el) => {
        const clonedElem = { ...el };
        const name =
          clonedElem.coi_name &&
          clonedElem.coi_name.toLowerCase().includes(search.toLowerCase());
        const desc =
          clonedElem.description &&
          clonedElem.description.toLowerCase().includes(search.toLowerCase());
        // const credit = clonedElem.description_credit??clonedElem.description
        //   .toLowerCase()
        //   .includes(search.toLowerCase());
        if (name || desc) {
          return clonedElem;
        }
        return false;
      })
    : data;

  const onShowSizeChange = (current, pageSize) => {
    setCurrentPage(current);
    setSizePage(pageSize);
  };
  const trimString = (string, length) => {
    return string.length > length
      ? `${string.substring(0, length)}...`
      : string;
  };

  const onAddToPlaylist = () => {
    if (!playlist) {
      setError('Choose the playlist');
      toastr.error('', 'Choose the playlist');
    }
    if (selected.length < 1) {
      toastr.error('', 'Choose images');
    }
    if (playlist && selected.length > 0) {
      const post = [...playlist.posts];

      selected.map((art) =>
        post.push({
          id: uuid(),
          ...art,
        })
      );
      playlist.posts = post;
      // console.log(selected);
      dispatch(
        addPostPlaylist({
          playlist,
          access: 'activity_pillar',
          action: 'update',
          message: 'add_post',
        })
      );
    }
  };

  const imagePreviewUrl = media.file
    ? media.file && URL.createObjectURL(media.file)
    : media.logo && `${imgUrl}/media/${media.logo}`;

  const onFileChangedHandler = (file) => {
    if (
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'video/mp4'
    ) {
      if (file.size / 1000000 <= 50) {
        setMedia((prevState) => ({ ...prevState, file, logo: null }));
      } else {
        message.error('Maximum file size is 50MB');
      }
    } else {
      message.error('Unsupported filetype!');
    }
    setIsDragged(false);
  };

  const handleDeletePreviewImage = () => {
    setMedia((prevState) => ({ ...prevState, file: null, logo: null }));
  };

  const handleChange = (name, value) => {
    setMedia({
      ...media,
      [name]: value,
    });
  };

  const handleReset = () => {
    setModalUpload(false);
    setMedia({
      file: null,
      logo: null,
      name: '',
      description: '',
    });
  };

  const onCloseModalHandler = () => {
    setDeleteModal((prevState) => ({
      ...prevState,
      isOpen: !prevState.isOpen,
    }));
  };

  const hidePreviewModal = () => {
    setMediaPreviewModal((prevState) => ({
      ...prevState,
      isOpen: !prevState.isOpen,
    }));
  };

  const onPreviewMediaClickHandler = (post) => {
    setMediaPreviewModal((prevState) => ({
      ...prevState,
      isOpen: true,
      image_url: post.image_url ?? '',
      video_url: post.video_url ?? '',
      title: post.coi_name,
      description: post.description,
    }));
  };

  const onDeleteItemHandler = () => {
    dispatch(deletePlaylist({ list: deleteModal.list }));
  };

  const handleSubmit = () => {
    if (media.name && media.description && media.file) {
      dispatch(submitAddImage({ media, siteId }));
    }
  };

  return (
    <div className="media__library">
      <Modal
        title="Upload custom media"
        visible={modalUpload}
        className="media-form"
        onCancel={handleReset}
        okText="Submit"
        onOk={handleSubmit}
        okButtonProps={{
          loading,
        }}
        cancelButtonProps={{
          hidden: loading,
        }}
      >
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          layout="horizontal"
        >
          <Form.Item
            label="Name"
            rules={[{ required: true, message: 'Name is required!' }]}
          >
            <Input
              placeholder="Name"
              onChange={(e) => handleChange('name', e.target.value)}
              value={media.name}
            />
          </Form.Item>
          <Form.Item
            label="Description"
            rules={[{ required: true, message: 'Description is required' }]}
          >
            <Input.TextArea
              placeholder="Description"
              rows={6}
              onChange={(e) => handleChange('description', e.target.value)}
              value={media.description}
            />
          </Form.Item>
          <Form.Item label="Upload" required>
            {imagePreviewUrl ? (
              <div className="upload-image-preview">
                <Tooltip placement="top" title="Delete">
                  <button
                    type="button"
                    onClick={handleDeletePreviewImage}
                    className="btn-remove"
                  >
                    <i className="feather-x" />
                  </button>
                </Tooltip>
                {(media.file && media.file.type === 'video/mp4') ||
                (media.fileType === 'video/mp4' && !media.file) ? (
                  <video
                    controls="controls"
                    preload="metadata"
                    key={imagePreviewUrl}
                  >
                    <source src={imagePreviewUrl} type="video/mp4" />
                  </video>
                ) : (
                  <img
                    className="media-avatar"
                    src={imagePreviewUrl}
                    alt="User profile logo preview"
                  />
                )}
              </div>
            ) : (
              <Upload
                showUploadList={false}
                beforeUpload={onFileChangedHandler}
              >
                <div
                  onDragLeave={() => setIsDragged(false)}
                  onDragOver={() => setIsDragged(true)}
                  className={`upload-drag-and-drop ${
                    isDragged ? 'active' : ''
                  }`}
                >
                  <i className="feather-upload" />
                  <p>Drag or click to upload</p>
                </div>
              </Upload>
            )}
          </Form.Item>
        </Form>
      </Modal>
      {/* </div> */}

      {deleteModal.isOpen && (
        <Modal
          title="Are you sure to delete this item?"
          visible={deleteModal.isOpen}
          onOk={onDeleteItemHandler}
          onCancel={onCloseModalHandler}
        >
          Are you sure to delete this item?
        </Modal>
      )}

      {mediaPreviewModal.isOpen && (
        <Modal
          title={null}
          visible={mediaPreviewModal.isOpen}
          footer={null}
          onCancel={hidePreviewModal}
          className="custom-modal"
        >
          {mediaPreviewModal.video_url ? (
            <video id="my-video" className="video-js" data-setup="{}" controls>
              <source src={mediaPreviewModal.video_url} />
            </video>
          ) : mediaPreviewModal.image_url ? (
            <img src={mediaPreviewModal.image_url} alt="post" />
          ) : null}

          <div className="custom-modal-content">
            <div className="custom-modal-content-title">
              <div className="custom-modal-title">Title</div>
              <div className="custom-modal-description">
                {mediaPreviewModal.title ? mediaPreviewModal.title : 'No Title'}
              </div>
            </div>
            <div className="custom-modal-content-title">
              <div className="custom-modal-title">Description</div>
              <div className="custom-modal-description">
                {mediaPreviewModal.description}
              </div>
            </div>
          </div>
        </Modal>
      )}

      {current === 'search' && (
        <div className="media-header-arrival">
          <div className="media-header-input">
            <div className="media-header-left">
              <div className="media-form-group">
                <div className="searchbar">
                  <i className="feather-search" />
                  <input
                    placeholder="Search..."
                    className="search-input"
                    value={search}
                    onChange={onSearch}
                  />
                </div>
              </div>
            </div>
            <div className="media-header-right">
              <div className="media-form-group">
                <label>Filter by source</label>
                <Select
                  className="select-dropdown-item"
                  defaultValue="all"
                  value={source}
                  onChange={(e) => setSource(e)}
                >
                  <Option value={'all'}>All</Option>
                  <Option value={'artwork'}>Artwork</Option>
                  <Option value={'tours'}>Tours</Option>
                  <Option value={'exhibition'}>Exhibitions</Option>
                  <Option value={'activities'}>Activities</Option>
                  <Option value={'venue'}>Venue</Option>
                </Select>
              </div>
              <div className="media-form-group">
                <label>Select Playlist</label>
                <Select
                  className="select-dropdown-item"
                  defaultValue={playlistList ? 0 : 'Select Playlist'}
                  onChange={(e) => setPlaylist(playlistList[e])}
                >
                  {playlistList &&
                    playlistList.map((playlist, index) => (
                      <Option key={playlist._id} value={index}>
                        {playlist.name}
                      </Option>
                    ))}
                </Select>
              </div>
            </div>
          </div>

          <div className="media-header-input">
            <Button
              type="primary"
              className="btn-primary"
              onClick={onAddToPlaylist}
            >
              Add to playlist
            </Button>
          </div>
        </div>
      )}

      <div className="media__content">
        {current === 'search' ? (
          <>
            {loading ? (
              <ClipLoader />
            ) : (
              <>
                <div className="media__wrapper">
                  {apiList.length > 0 && (
                    <>
                      {data.length > 0 && (
                        <>
                          {/* <div className='media-wrapper'> */}
                          {data.slice(minValue, maxValue).map((api, index) => {
                            return (
                              <div key={index} className="media__item">
                                <div className="media__item-actions">
                                  <Tooltip
                                    title="Media Preview"
                                    className="media__preview"
                                  >
                                    <button
                                      onClick={() => {
                                        onPreviewMediaClickHandler(api);
                                      }}
                                    >
                                      <i className="feather-maximize-2" />
                                    </button>
                                  </Tooltip>
                                </div>

                                <CheckableTag
                                  checked={selected.indexOf(api) !== -1}
                                  onChange={(checked) =>
                                    onAddImages(api, checked)
                                  }
                                >
                                  {selected.indexOf(api) !== -1 && (
                                    <div className="checked-overlay">
                                      <svg
                                        width="24"
                                        height="18"
                                        viewBox="0 0 24 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="icon-checked"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M23.6095 1.05719C24.1302 1.57789 24.1302 2.42211 23.6095 2.94281L8.94281 17.6095C8.42211 18.1302 7.57789 18.1302 7.05719 17.6095L0.390524 10.9428C-0.130175 10.4221 -0.130175 9.57789 0.390524 9.05719C0.911223 8.53649 1.75544 8.53649 2.27614 9.05719L8 14.7811L21.7239 1.05719C22.2446 0.536493 23.0888 0.536493 23.6095 1.05719Z"
                                          fill="white"
                                        />
                                      </svg>
                                    </div>
                                  )}

                                  <div className="img-label-wrapper">
                                    <div className="img-label">{api.type}</div>
                                    <div className="img-label-2">
                                      {api.image_url ||
                                      (api.url && api.ext !== 'video/mp4')
                                        ? 'Image'
                                        : 'Video'}
                                    </div>
                                  </div>

                                  {api.image_url ||
                                  (api.url && api.ext !== 'video/mp4') ? (
                                    <img
                                      className="media__item-img"
                                      src={
                                        api.image_url ? api.image_url : api.url
                                      }
                                      alt=""
                                    />
                                  ) : (
                                    <video
                                      id="my-video"
                                      className="video-js"
                                      data-setup="{}"
                                      preload="metadata"
                                    >
                                      <source
                                        src={
                                          api.video_url
                                            ? api.video_url
                                            : api.url
                                        }
                                      />
                                    </video>
                                  )}
                                  <div className="media__item-description">
                                    <span>
                                      {trimString(api.coi_name ?? '', 20)}
                                    </span>
                                    {api.description && (
                                      <p>{trimString(api.description, 20)}</p>
                                    )}
                                  </div>
                                </CheckableTag>
                              </div>
                            );
                          })}
                        </>
                      )}
                    </>
                  )}
                </div>
              </>
            )}
          </>
        ) : (
          current === 'playlist' && (
            // <PlaylistPreview playlists={playlistList} type="activity-pillar" apiList={apiList}></PlaylistPreview>
            <PlaylistArrival
              playlists={playlistList}
              // apiList={apiList}
              updated={updated}
              access="activity_pillar"
            />
          )
        )}
      </div>

      {current === 'search' && (
        <>
          <div className="media-pagination">
            <Pagination
              defaultCurrent={1}
              current={currentPage}
              total={data.length}
              pageSize={sizePage}
              onChange={handleChangePage}
              onShowSizeChange={onShowSizeChange}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default ActivityPillar;
