import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Input, Alert } from 'antd';
import { useChangeHandler, useFormatMessage } from 'hooks';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { logout, resetUserPassword } from 'state/actions/auth';
import { ReactComponent as LockLogo } from '../../assets/Iconly/Light-Outline/Lock-logo.svg';
// import './ResetPassword.scss';
import './ResetPassword.css';
import { log } from 'utils';
import { ReactComponent as ErrorLogo } from '../../assets/Iconly/Bulk/Info-Circle.svg';

const ResetPassword = () => {
  const {
    error,
    isAuth,
    restoredPassword,
    loading,
    emailFromToken,
    userData,
  } = useSelector(
    (state) => ({
      error: state.auth.error,
      isAuth: !!state.auth.userData.id,
      // changedPassword: state.auth.changedPassword,
      restoredPassword: state.auth.restoredPassword,
      loading: state.auth.loading,
      emailFromToken: state.auth.emailFromToken,
      userData: state.auth.userData,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const [seePassword, setSeePassword] = useState(false);
  const [seeConfirmation, setSeeConfirmation] = useState(false);
  const [currentEmail, setCurrentEmail] = useState('');

  const [passwords, setPasswords] = useState({
    new: '',
    confirmation: '',
    current: '',
  });

  const [errors, setErrors] = useState([]);

  const onChangeHandler = useChangeHandler(setPasswords);

  useEffect(() => {

    if (!emailFromToken && !isAuth) {
      // history.push('/');
    }
  }, [emailFromToken, isAuth]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (restoredPassword && !error) {
      history.push('/');
    }
  }, [restoredPassword, error]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userData.email) {
      setCurrentEmail(userData.email);
    } else if (emailFromToken) {
      setCurrentEmail(emailFromToken);
    }
  }, [userData.email, emailFromToken]);

  if (userData) {
    log(userData, '<<< user data');
  }

  if (passwords) {
    log(passwords, '<<<< passwords');
  }

  if (isAuth) {
    log(isAuth, '<<< is auth');
  }

  if (emailFromToken) {
    log(emailFromToken, '<<< email from tokenm');
  }

  const backToLogin = () => {
    dispatch(logout());
  };

  const passwordToggle = () => {
    const inputPasswordEl = document.getElementById('password');
    if (inputPasswordEl.type === 'password') {
      setSeePassword(true);
      inputPasswordEl.type = 'text';
    } else {
      setSeePassword(false);
      inputPasswordEl.type = 'password';
    }
  };

  const confirmationToggle = () => {
    const inputConfirmationEl = document.getElementById('confirmation');
    if (inputConfirmationEl.type === 'password') {
      setSeeConfirmation(true);
      inputConfirmationEl.type = 'text';
    } else {
      setSeeConfirmation(false);
      inputConfirmationEl.type = 'password';
    }
  };

  let inputs = {
    new: {
      modifier: null,
      message: { modifier: null, content: null },
    },
    confirmation: {
      modifier: null,
      message: { modifier: null, content: null },
    },
  };

  const setInputs = (key, value) => {
    inputs = { ...inputs, [`${key}`]: value };
  };

  const newPasswordsAreEqual =
    passwords.new &&
    passwords.confirmation &&
    passwords.new === passwords.confirmation;

  const passwordsMatchMessagge = useFormatMessage(
    `ChangePassword.matchPassword`
  );

  const notMatchPasswordMessage = useFormatMessage(
    `ChangePassword.notMatchPassword`
  );

  if (newPasswordsAreEqual) {
    setInputs('confirmation', {
      modifier: 'is-success',
      message: {
        modifier: 'is-success',
        content: passwordsMatchMessagge,
      },
    });
  } else if (passwords.confirmation) {
    setInputs('confirmation', {
      modifier: 'is-danger',
      message: {
        modifier: 'is-danger',
        content: notMatchPasswordMessage,
      },
    });
  }

  const onSubmitHandler = (event) => {
    event.preventDefault();
    setErrors([]);

    const lowercase = passwords.new.match(/[a-z]/g) ? true : false;
    const uppercase = passwords.new.match(/[A-Z]/g) ? true : false;
    const number = passwords.new.match(/[0-9]/g) ? true : false;
    const length = passwords.new.length < 12;
    const special = passwords.new.match(/[!@#$%^&*()_+\-=\]{};':"\\|,.<>?-]/g) ? true : false;

    if (
      length ||
      !lowercase ||
      !uppercase ||
      !number ||
      !special ||
      passwords.new !== passwords.confirmation
    ) {
      const errorData = [];

      if (length) {
        errorData.push('Password Minimum 12 Characters');
      }

      if (!lowercase) {
        errorData.push('Password Must be contain lowercase');
      }

      if (!uppercase) {
        errorData.push('Password Must be contain uppercase');
      }

      if (!number) {
        errorData.push('Password Must be contain number');
      }

      if (!special) {
        errorData.push('Password Must be contain special character');
      }

      if (passwords.new !== passwords.confirmation) {
        errorData.push('Password not match with confirmation');
      }

      setErrors(errorData);
    } else {
      dispatch(
        resetUserPassword(passwords.new, passwords.confirmation, currentEmail)
      );
    }
  };

  return (
    <section className="reset-password">
      {/* {isPasswordRestored} */}
      {/* {redirect} */}
      <h1 className="reset-password__title">Reset your password</h1>
      <p className="reset-password__desc">Enter your new password</p>
      {errors.length > 0 &&
        errors.map((x, i) => {
          return (
            <Alert
              key={i}
              message={x}
              type="error"
              showIcon
              closable
              icon={<ErrorLogo width="16.67" height="16.67" />}
            />
          );
        })}
      <form className="reset-password__form" onSubmit={onSubmitHandler}>
        {inputs.new.message.content ? (
          <p className={`help is-${inputs.new.message.modifier}`}>
            {inputs.new.message.content}
          </p>
        ) : null}

        <div
          className={`reset-password__form-controls ${errors.length > 0 ? 'error-form' : ''
            }`}
        >
          <Input
            id="password"
            type="password"
            value={passwords.new}
            name="new"
            onChange={onChangeHandler}
            prefix={<LockLogo width="22" height="20" />}
            suffix={
              <label className="reset-password__label">
                Enter New Password
              </label>
            }
            addonAfter={
              seePassword ? (
                <EyeTwoTone onClick={() => passwordToggle()} />
              ) : (
                <EyeInvisibleOutlined onClick={() => passwordToggle()} />
              )
            }
            required
          />
        </div>

        <div
          className={`reset-password__form-controls ${errors.length > 0 ? 'error-form' : ''
            }`}
        >
          <Input
            id="confirmation"
            type="password"
            value={passwords.confirmation}
            name="confirmation"
            onChange={onChangeHandler}
            prefix={<LockLogo width="22" height="20" />}
            suffix={
              <label className="reset-password__label">
                Re-Type New Password
              </label>
            }
            addonAfter={
              seeConfirmation ? (
                <EyeTwoTone onClick={() => confirmationToggle()} />
              ) : (
                <EyeInvisibleOutlined onClick={() => confirmationToggle()} />
              )
            }
            required
          />
        </div>

        {inputs.new.message.content ? (
          <p className={`help is-${inputs.new.message.modifier}`}>
            {inputs.new.message.content}
          </p>
        ) : null}

        {inputs.confirmation.message.content && (
          <p className={`help is-${inputs.confirmation.message.modifier}`}>
            {inputs.confirmation.message.content}
          </p>
        )}
        <div className="reset-password__form-controls">
          {loading ? (
            <button type="button" disabled className="reset-password__button">
              Loading...
            </button>
          ) : (
            <button type="submit" className="reset-password__button">
              Reset Password
            </button>
          )}

          <Link onClick={() => backToLogin()} to="/">
            <button className="reset-password__back">Back</button>
          </Link>
        </div>
      </form>
      <div className="reset-password__message">
        <h3 className="reset-password__message-title">
          Password must contain the following:
        </h3>
        <p
          className="reset-password__message-invalid"
          style={{
            color: passwords.new.match(/[a-z]/g)
              ? 'green'
              : 'hsla(0, 79%, 63%, 1)',
          }}
        >
          {passwords.new.match(/[a-z]/g) ? (
            <i className="feather-check-circle"></i>
          ) : (
            <i className="feather-x-circle"></i>
          )}
          A lowercase letter
        </p>
        <p
          className="reset-password__message-invalid"
          style={{
            color: passwords.new.match(/[A-Z]/g)
              ? 'green'
              : 'hsla(0, 79%, 63%, 1)',
          }}
        >
          {passwords.new.match(/[A-Z]/g) ? (
            <i className="feather-check-circle"></i>
          ) : (
            <i className="feather-x-circle"></i>
          )}
          A capital (uppercase) letter
        </p>
        <p
          className="reset-password__message-invalid"
          style={{
            color: passwords.new.match(/[0-9]/g)
              ? 'green'
              : 'hsla(0, 79%, 63%, 1)',
          }}
        >
          {passwords.new.match(/[0-9]/g) ? (
            <i className="feather-check-circle"></i>
          ) : (
            <i className="feather-x-circle"></i>
          )}
          A number
        </p>
        <p
          className="change-password__message-invalid"
          style={{
            color:
              passwords.new.match(/[!@#$%^&*()_+\-=\]{};':"\\|,.<>?-]/g) ? 'green' : 'hsla(0, 79%, 63%, 1)',
          }}
        >
          {passwords.new.match(/[!@#$%^&*()_+\-=\]{};':"\\|,.<>?-]/g) ? (
            <i className="feather-check-circle"></i>
          ) : (
            <i className="feather-x-circle"></i>
          )}
          Special Character
        </p>
        <p
          className="reset-password__message-invalid"
          style={{
            color: passwords.new.length >= 12 ? 'green' : 'hsla(0, 79%, 63%, 1)',
          }}
        >
          {passwords.new.length >= 12 ? (
            <i className="feather-check-circle"></i>
          ) : (
            <i className="feather-x-circle"></i>
          )}
          Minimum 12 Characters
        </p>
      </div>
    </section>
  );
};

export default ResetPassword;
