import { Tooltip, Tag } from 'antd';
import moment from 'moment';
import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { log } from 'utils';

const { CheckableTag } = Tag;

const DragElement = SortableElement((props) => {
  const {
    post,
    onPreviewModalClickHandler,
    id,
    playlist,
    // onRemoveButtonClickHandler,
    // onEditCalendarClickHandler,
    handleChange,
    selectedTags,
    access,
  } = props;

  const trimString = (string, length) => {
    return string.length > length
      ? `${string.substring(0, length)}...`
      : string;
  };

  log(playlist);

  const isVideo =
    access === 'arrival_landmark' &&
    post.image_url &&
    post.image_url.split('.')[post.image_url.split('.').length - 1];

  return (
    <div className="playlist__card-item">
      <div className="playlist__card-header">
        <div className="playlist__card-info">
          <span className="playlist__card-order">#{parseInt(id) + 1}</span>
          {playlist.type !== 'wayfinding' && playlist.type !== 'artwork' && (
            <span className="playlist__card-type">
              {post.video_url ||
              (post.image_url && post.image_url.slice(-3) === 'mp4')
                ? 'Video'
                : 'Image'}
            </span>
          )}
          {playlist.type === 'artwork' && (
            <span
              className={
                post.al_id ? 'playlist__card-type-2' : 'playlist__card-type'
              }
            >
              {post.al_id ? 'API' : 'Manual Upload'}
            </span>
          )}
        </div>
        <div className="playlist__actions">
          <Tooltip title="Media Preview" className="playlist__media-preview">
            <button
              onClick={() => {
                onPreviewModalClickHandler(post);
              }}
            >
              <i className="feather-maximize-2" />
            </button>
          </Tooltip>
        </div>
      </div>

      <CheckableTag
        checked={selectedTags.indexOf(post) > -1}
        onChange={(checked) => handleChange(post, checked)}
      >
        {/* <div className='img-overlay'></div> */}
        {selectedTags.indexOf(post) > -1 && (
          <div className="checked-overlay">
            <svg
              width="24"
              height="18"
              viewBox="0 0 24 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="icon-checked"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.6095 1.05719C24.1302 1.57789 24.1302 2.42211 23.6095 2.94281L8.94281 17.6095C8.42211 18.1302 7.57789 18.1302 7.05719 17.6095L0.390524 10.9428C-0.130175 10.4221 -0.130175 9.57789 0.390524 9.05719C0.911223 8.53649 1.75544 8.53649 2.27614 9.05719L8 14.7811L21.7239 1.05719C22.2446 0.536493 23.0888 0.536493 23.6095 1.05719Z"
                fill="white"
              />
            </svg>
          </div>
        )}

        {(post.image_url || post.cover_photo_url) && isVideo !== 'mp4' ? (
          <img
            src={post.image_url ?? post.cover_photo_url}
            alt="default"
            className="playlist__card-img"
          />
        ) : (
          <video
            id="my-video"
            className="video-js playlist__card-video"
            data-setup="{}"
            preload="metadata"
          >
            <source src={post.video_url ? post.video_url : post.image_url} />
          </video>
        )}
        <div className="playlist__card-description">
          <span className="playlist__post-name">
            {'Name : '}
            {post.name ??
              trimString(post.coi_name ?? post.al_name ?? 'No title', 20)}
          </span>

          {access === 'arrival_landmark' && (
            <>
              {post.duration > 0 ? (
                <span className="playlist__post-duration">
                  {'Duration : '}
                  {post.duration / 1000}s
                </span>
              ) : (
                <span className="playlist__post-duration">Not set</span>
              )}
            </>
          )}
          <p>
            {playlist.type === 'wayfinding' && `Level ${post.floor_number}`}
          </p>
          <div className="playlist__card-calendar">
            {playlist.type !== 'wayfinding' && (
              <div className="playlist__card-calendar-wrapper">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="playlist__card-calendar-logo"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.75 3.75C3.33579 3.75 3 4.08579 3 4.5V15C3 15.4142 3.33579 15.75 3.75 15.75H14.25C14.6642 15.75 15 15.4142 15 15V4.5C15 4.08579 14.6642 3.75 14.25 3.75H3.75ZM1.5 4.5C1.5 3.25736 2.50736 2.25 3.75 2.25H14.25C15.4926 2.25 16.5 3.25736 16.5 4.5V15C16.5 16.2426 15.4926 17.25 14.25 17.25H3.75C2.50736 17.25 1.5 16.2426 1.5 15V4.5Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 0.75C12.4142 0.75 12.75 1.08579 12.75 1.5V4.5C12.75 4.91421 12.4142 5.25 12 5.25C11.5858 5.25 11.25 4.91421 11.25 4.5V1.5C11.25 1.08579 11.5858 0.75 12 0.75Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6 0.75C6.41421 0.75 6.75 1.08579 6.75 1.5V4.5C6.75 4.91421 6.41421 5.25 6 5.25C5.58579 5.25 5.25 4.91421 5.25 4.5V1.5C5.25 1.08579 5.58579 0.75 6 0.75Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.5 7.5C1.5 7.08579 1.83579 6.75 2.25 6.75H15.75C16.1642 6.75 16.5 7.08579 16.5 7.5C16.5 7.91421 16.1642 8.25 15.75 8.25H2.25C1.83579 8.25 1.5 7.91421 1.5 7.5Z"
                    fill="white"
                  />
                </svg>
                <span className="playlist__card-calendar-text">
                  {post.start || post.end
                    ? post.start && !post.end
                      ? `Will show after ${post.start}`
                      : !post.start && post.end
                      ? `Will show before ${post.end}`
                      : post.start && post.end
                      ? `${post.start} - ${post.end}`
                      : null
                    : post.start_date && post.end_date
                    ? `${moment(post.start_date).format(
                        'YYYY-MM-DD HH:mm'
                      )} - ${moment(post.end_date).format('YYYY-MM-DD HH:mm')}`
                    : 'All Day'}
                </span>
              </div>
            )}
          </div>
        </div>
      </CheckableTag>
    </div>
  );
});

const PlayListGrid = SortableContainer(
  ({
    posts,
    onPreviewModalClickHandler,
    playlist,
    onEditCalendarClickHandler,
    onRemoveButtonClickHandler,
    handleChange,
    selectedTags,
    access,
  }) => {
    return (
      <div
        className="playlist__card"
        // style={{
        //   display: 'grid',
        //   gridGap: '1rem',
        //   gridTemplateColumns: 'repeat(4, 1fr)',
        //   zIndex: '0',
        // }}
      >
        {posts.map((post, index) => {
          return (
            <>
              <DragElement
                post={post}
                key={index}
                index={index}
                id={index}
                onPreviewModalClickHandler={onPreviewModalClickHandler}
                onEditCalendarClickHandler={onEditCalendarClickHandler}
                onRemoveButtonClickHandler={onRemoveButtonClickHandler}
                playlist={playlist}
                handleChange={handleChange}
                selectedTags={selectedTags}
                access={access}
              />
            </>
          );
        })}
      </div>
    );
  }
);

export default PlayListGrid;
