import { createReducer } from 'redux-act';

import {
  AUTH_SIGN_IN_INIT,
  AUTH_SIGN_IN_FAIL,
  AUTH_LOGOUT_INIT,
  AUTH_LOGOUT_SUCCESS,
  AUTH_FETCH_INFORMATION_SUCCESS,
  AUTH_RESTORE_SESSION_INIT,
  AUTH_RESTORE_SESSION_SUCCESS,
  AUTH_RESTORE_SESSION_FAIL,
  AUTH_SET_PASSWORD_INIT,
  AUTH_SET_PASSWORD_SUCCESS,
  AUTH_SET_PASSWORD_FAIL,
  AUTH_RESET_PASSWORD_INIT,
  AUTH_RESET_PASSWORD_SUCCESS,
  AUTH_RESET_PASSWORD_FAIL,
  AUTH_READ_TOKEN_INIT,
  AUTH_READ_TOKEN_SUCCESS,
  AUTH_READ_TOKEN_FAIL,
  AUTH_CLEAN_UP,
  AUTH_CHANGE_PASSWORD_INIT,
  AUTH_CHANGE_PASSWORD_SUCCESS,
  AUTH_CHANGE_PASSWORD_FAIL,
  AUTH_PROVIDER_FAIL,
  AUTH_PROVIDER_INIT,
  AUTH_PROVIDER_SUCCESS,
} from 'state/actions/auth';

const initialState = {
  userData: {
    id: null,
    isAdmin: null,
    role: '',
  },
  loading: false,
  error: null,
  restoring: false,
  restoringError: null,
  restoredPassword: false,
  changedPassword: false,
  isSuccessReset: false,
  emailFromToken: '',
};

export const authReducer = createReducer(
  {
    [AUTH_SIGN_IN_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [AUTH_SIGN_IN_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [AUTH_FETCH_INFORMATION_SUCCESS]: (state, payload) => ({
      ...state,
      userData: {
        id: payload.id,
        email: payload.email,
        isAdmin: payload.isAdmin,
        role: payload.role,
        roleAccess: payload.roleAccess,
        isPasswordChanged: payload.isPasswordChanged,
        isPasswordExpired: payload.isPasswordExpired ?? false,
        name: payload.name,
        organizationId: payload.organizationId,
        organizationName: payload.organizationName,
        logoUrl: payload.logoUrl || state.userData.logoUrl,
        createdAt: payload.createdAt,
        lastLoginDate: payload.lastLoginDate,
        access: payload.access,
      },
      loading: false,
      error: null,
    }),
    [AUTH_LOGOUT_INIT]: () => ({ ...initialState }),
    [AUTH_LOGOUT_SUCCESS]: (state) => ({ ...state }),
    [AUTH_RESTORE_SESSION_INIT]: (state) => ({ ...state, restoring: true }),
    [AUTH_RESTORE_SESSION_SUCCESS]: (state) => ({
      ...state,
      restoring: false,
      restoringError: null,
    }),
    [AUTH_RESTORE_SESSION_FAIL]: (state) => ({
      ...state,
      restoring: false,
      restoringError: true,
    }),
    [AUTH_SET_PASSWORD_INIT]: (state) => ({ ...state, loading: true }),
    [AUTH_SET_PASSWORD_SUCCESS]: (state) => ({
      ...state,
      loading: false,
      error: null,
    }),
    [AUTH_SET_PASSWORD_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [AUTH_RESET_PASSWORD_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [AUTH_RESET_PASSWORD_SUCCESS]: (state) => ({
      ...state,
      loading: false,
      error: null,
      restoredPassword: true,
    }),
    [AUTH_RESET_PASSWORD_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [AUTH_READ_TOKEN_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [AUTH_READ_TOKEN_SUCCESS]: (state, payload) => ({
      ...state,
      emailFromToken: payload.email,
      loading: false,
      error: null,
      isSuccessReset: true,
    }),
    [AUTH_READ_TOKEN_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [AUTH_CLEAN_UP]: (state) => ({
      ...state,
      error: null,
      changedPassword: false,
    }),
    [AUTH_CHANGE_PASSWORD_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [AUTH_CHANGE_PASSWORD_SUCCESS]: (state) => ({
      ...state,
      userData: {
        isPasswordChanged: true,
      },
      loading: false,
      changedPassword: true,
    }),
    [AUTH_CHANGE_PASSWORD_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [AUTH_PROVIDER_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [AUTH_PROVIDER_SUCCESS]: (state, payload) => ({
      ...state,
      userData: {
        id: payload.id,
        isAdmin: payload.isAdmin,
        role: payload.role,
        isPasswordChanged: payload.isPasswordChanged,
        email: payload.email,
        name: payload.name,
        site: payload.site,
        logoUrl: payload.logoUrl,
        createdAt: payload.createdAt,
        lastLoginDate: payload.lastLoginDate,
        access: payload.access,
      },
      error: null,
      loading: false,
    }),
    [AUTH_PROVIDER_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
  },
  initialState
);
