import React, { useEffect, useState } from 'react';
import { Pagination, Tag, Modal, Tooltip } from 'antd';
import './CuratedPlaylist.css';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import {
  fetchCuratedPlaylist,
  fetchArrivalInit,
  // fetchArrivalNew,
} from 'state/actions/api';
import { fetchPlaylist } from 'state/actions/playlist';
function CuratedPlaylist({ current }) {
  const { siteId } = useParams();
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState([]);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(8);
  const [sizePage, setSizePage] = useState(8);
  const { CheckableTag } = Tag;
  const [currentPage, setCurrentPage] = useState(0);
  // const [uploading, setUploading] = useState(false);

  const [mediaPreviewModal, setMediaPreviewModal] = useState({
    title: '',
    isOpen: false,
    source: '',
  });

  const { apiList, loading, updated } = useSelector(
    (state) => ({
      apiList: state.api.artwork,
      loading: state.api.loading,
      updated: state.playlist.added,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchArrivalInit());
    dispatch(fetchPlaylist({ siteId, type: 'wayfinding' }));
    // setMedias(mediaList);
  }, [siteId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(fetchCuratedPlaylist({ siteId }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (current === 'search') dispatch(fetchCuratedPlaylist({ siteId }));

    // setMedias(mediaList);
  }, [current]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    // setSearch();
    setSelected([]);
    // setMedias(mediaList);
  }, [updated]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // setSearch();
    setCurrentPage(1);
    setMinValue(0);
    setMaxValue(sizePage);
    // setMedias(mediaList);
  }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setMaxValue(minValue + sizePage);
  }, [sizePage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {});

  const handleChangePage = (value) => {
    setCurrentPage(value);
    if (value <= 1) {
      setMinValue(0);
      setMaxValue(sizePage);
    } else {
      setMinValue(value * sizePage - sizePage);
      setMaxValue(value * sizePage);
    }
  };
  const onAddImages = (img, checked) => {
    if (checked) {
      setSelected([...selected, img]);
    } else {
      const index = selected.indexOf(img);
      selected.splice(index, 1);
      setSelected([...selected]);
    }
  };

  const onSearch = (e) => {
    setSearch(e.target.value);
  };

  const data = search
    ? apiList.filter((el) => {
        const clonedElem = { ...el };
        const name =
          clonedElem.itinerary_name &&
          clonedElem.itinerary_name
            .toLowerCase()
            .includes(search.toLowerCase());
        const desc =
          clonedElem.description &&
          clonedElem.description.toLowerCase().includes(search.toLowerCase());
        // const credit = clonedElem.description_credit??clonedElem.description
        //   .toLowerCase()
        //   .includes(search.toLowerCase());
        if (name || desc) {
          return clonedElem;
        }
        return false;
      })
    : apiList;

  const onShowSizeChange = (current, pageSize) => {
    setCurrentPage(current);
    setSizePage(pageSize);
  };
  const trimString = (string, length) => {
    return string.length > length
      ? `${string.substring(0, length)}...`
      : string;
  };

  // const onDeleteButtonHandler = () => {
  //   setDeleteModal((prevState) => ({
  //     ...prevState,
  //     list: selected.map((item) => item._id),
  //     isOpen: true,
  //   }));
  // };

  const hidePreviewModal = () => {
    setMediaPreviewModal((prevState) => ({
      ...prevState,
      isOpen: !prevState.isOpen,
    }));
  };

  const onPreviewMediaClickHandler = (post) => {
    setMediaPreviewModal((prevState) => ({
      ...prevState,
      isOpen: true,
      image_url: post.image_url ?? '',
      video_url: post.video_url ?? '',
      title: post.itinerary_name,
      description: post.description,
    }));
  };

  // console.log(data);

  return (
    <div className="media__library">
      {mediaPreviewModal.isOpen && (
        <Modal
          title={null}
          visible={mediaPreviewModal.isOpen}
          footer={null}
          onCancel={hidePreviewModal}
          className="custom-modal"
        >
          {mediaPreviewModal.video_url ? (
            <video id="my-video" className="video-js" data-setup="{}" controls>
              <source src={mediaPreviewModal.video_url} />
            </video>
          ) : mediaPreviewModal.image_url ? (
            <img src={mediaPreviewModal.image_url} alt="post" />
          ) : null}

          <div className="custom-modal-content">
            <div className="custom-modal-content-title">
              <div className="custom-modal-title">Title</div>
              <div className="custom-modal-description">
                {mediaPreviewModal.title ? mediaPreviewModal.title : 'No Title'}
              </div>
            </div>
            <div className="custom-modal-content-title">
              <div className="custom-modal-title">Description</div>
              <div className="custom-modal-description">
                {mediaPreviewModal.description}
              </div>
            </div>
          </div>
        </Modal>
      )}

      <div className="media-header-arrival">
        <div className="media-header-input">
          <div className="media-form-group">
            <label>Search</label>
            <div className="searchbar">
              <i className="feather-search" />
              <input
                placeholder="Search..."
                className="search-input"
                value={search}
                onChange={onSearch}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="media__content">
        <>
          {loading ? (
            <ClipLoader />
          ) : (
            <>
              <div className="media__wrapper">
                {apiList.length > 0 && (
                  <>
                    {data.length > 0 && (
                      <>
                        {/* <div className='media-wrapper'> */}
                        {data.slice(minValue, maxValue).map((api, index) => {
                          return (
                            <div key={index} className="media__item">
                              <div className="media__item-actions">
                                <Tooltip
                                  title="Media Preview"
                                  className="media__preview"
                                >
                                  <button
                                    onClick={() => {
                                      onPreviewMediaClickHandler(api);
                                    }}
                                  >
                                    <i className="feather-maximize-2" />
                                  </button>
                                </Tooltip>
                                <CheckableTag
                                  // className="media__item-check"
                                  checked={selected.indexOf(api) !== -1}
                                  onChange={(checked) =>
                                    onAddImages(api, checked)
                                  }
                                >
                                  {selected.indexOf(api) !== -1 && (
                                    <div className="checked-overlay">
                                      <svg
                                        width="24"
                                        height="18"
                                        viewBox="0 0 24 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="icon-checked"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M23.6095 1.05719C24.1302 1.57789 24.1302 2.42211 23.6095 2.94281L8.94281 17.6095C8.42211 18.1302 7.57789 18.1302 7.05719 17.6095L0.390524 10.9428C-0.130175 10.4221 -0.130175 9.57789 0.390524 9.05719C0.911223 8.53649 1.75544 8.53649 2.27614 9.05719L8 14.7811L21.7239 1.05719C22.2446 0.536493 23.0888 0.536493 23.6095 1.05719Z"
                                          fill="white"
                                        />
                                      </svg>
                                    </div>
                                  )}
                                </CheckableTag>
                              </div>

                              <div className="img-overlay"></div>
                              <div className="img-label-wrapper">
                                <div className="img-label">{`${api.original_length} Activities`}</div>
                              </div>

                              <img
                                className="media__item-img"
                                src={api.image_url}
                                alt=""
                              />
                              <div className="media__item-description">
                                <span>{api.itinerary_name}</span>
                                {api.description && (
                                  <p>{trimString(api.description, 75)}</p>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </>
      </div>

      <div className="media-pagination">
        <Pagination
          defaultCurrent={1}
          current={currentPage}
          total={data.length}
          pageSize={sizePage}
          onChange={handleChangePage}
          onShowSizeChange={onShowSizeChange}
        />
      </div>
    </div>
  );
}

export default CuratedPlaylist;
